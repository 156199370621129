import React, { useEffect, useState, useRef } from "react";
import "./styles/index.less";
import config from "../../config/config";
import { useNavigate } from "react-router-dom";
import {
  Empty,
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spin,
  Tabs,
  message
} from "antd";
import type { TabsProps } from 'antd';
import { useParams } from "react-router-dom";
import { CaretRightOutlined, ExclamationCircleOutlined, PauseOutlined } from "@ant-design/icons";
import { get_task, get_task_answer, get_transcribe, evaluate_task, get_subtitle, test_masking, update_task_acc, updateDateTask, test_analyze, save_graph_data, update_graph_data, get_graph_record } from "../../services/task";
import { get_project } from "../../services/project";
import Board from "./components/Board";
import RuleBoard from "./components/Rule_Board";
import moment from "moment";


const { confirm } = Modal;
type Props = {};

const ConfigTask = (props: Props) => {
  const params = useParams();
  const [sublist, setSubList]: any = useState([]);
  const [debuglist, setDebugList]: any = useState([]);
  const [sublistTest, setSubListTest]: any = useState([]);
  const [outPage, setOutPage] = useState(true);
  const [tabKey, setTabKey] = useState('1');
  const [rulelist, setRuleList]: any = useState([]);
  const [taskdata, setTaskData]: any = useState([]);
  const [projectData, setProjectData]: any = useState({});
  const [result, setResult] = useState([] as any);
  const [answer, setAnswer] = useState(["", "", "", "", "", "", "", ""] as any);
  const [foundArr, setFound] = useState([]);
  const [foundArr2, setFound2] = useState([]);

  const navigate = useNavigate();

  const getSubtitle = async () => {
    try {
      const res = await get_subtitle({ task_id: params.task_id, track_id: params.track_id });


      // res.result = JSON.parse(res.result);

      console.log("res get subtitle :", res.result);

      const res2 = await test_analyze(res.result);
      console.log("res get result :", res2);

      let sub;
      let i = 0;
      if (res.result.length > 0) {

        setSubList(res.result);
        setDebugList(res2.result.debugging);

        const ans = await get_task_answer({ task_id: params.task_id, track_id: params.track_id });
        console.log("res get answer :", ans.data);

        for (let j = 0; j < res2.result.result.length; j++) {
          res2.result.result[j].id = j;
          // console.log(res2.result.result[j]);


          const t = await get_task({ data: { task_id: params.task_id, track_id: params.track_id } });
          console.log("task name", t.data[0].name);

          if (ans.data == null || ans.data[j] === undefined) {
            const g = await save_graph_data({ task_id: params.task_id, track_id: params.track_id, name: t.data[0].name, rule: res2.result.result[j].label, score: res2.result.result[j].score, answer: "" });
            if (g.message == "success") {
              console.log("saved graph data");
            } else {
              console.log("fail save");
            }
          } else {
            console.log(ans.data[j]);
            
            const g = await save_graph_data({ task_id: params.task_id, track_id: params.track_id, name: t.data[0].name, rule: res2.result.result[j].label, score: res2.result.result[j].score, answer: ans.data[j].toString().toUpperCase() });
            if (g.message == "success") {
              console.log("saved graph data");
            } else {
              console.log("fail save");
            }

          }
        }
        setResult(res2.result.result);
        // setLoading(false);
      } else {
        setSubList([]);
        setResult([]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const jumpTo = (time: any) => {
    (document.getElementById(time) as HTMLElement).scrollIntoView({ behavior: "smooth", inline: "start", block: "nearest" });
  }

  const getData = async () => {
    try {
      const res = await get_task({ data: { task_id: params.task_id, track_id: params.track_id } });
      // console.log("res get task :", res.data[0]);
      setTaskData(res.data[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getProjectData = async () => {
    try {
      const res = await get_project({ data: { project_id: params.project_id } });
      // console.log("res get project :", res.data[0]);
      setProjectData(res.data[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getAnswer = async () => {
    try {
      const res = await get_task_answer({ task_id: params.task_id, track_id: params.track_id });
      console.log("res get answer :", res.data);

      if (res.data == null) {
        setAnswer(["", "", "", "", "", "", "", ""]);
      }
      setAnswer(res.data);
    } catch (e) {
      console.error(e);
    }
  };



  useEffect(() => {
    if (tabKey === '1') {
      getData();
      getProjectData();
      getSubtitle();
      getAnswer();
    }
  }, [tabKey]);




  return (
    <div className="content-container" style={{ paddingRight: 0, marginRight: 0, overflowX: "hidden" }}>
      {/* <Spin spinning={loading} tip="กำลังประมวลผล..." size="large"> */}
      <Row gutter={16} style={{ paddingBottom: '10px' }}>
        <h2 onClick={() => {
          // if (outPage === false) {
          Modal.confirm({
            title: 'คุณต้องการออกจากหน้านี้ใช่หรือไม่ ?',
            icon: <ExclamationCircleOutlined />,
            content: "กรุณาบันทึกข้อมูลก่อนออกจากหน้านี้",
            okText: 'ออกจากหน้านี้',
            cancelText: 'ปิด',
            onOk: async () => {
              navigate("/project");
            }
          });
          // } else {
          //   navigate("/project");
          // }

        }} style={{ fontSize: "20px", cursor: "pointer" }}>Project: {projectData.name}</h2>
        <h2 style={{ fontSize: "20px", marginLeft: "10px" }}>&gt;</h2>

        <h2 onClick={() => {
          // if (outPage === false) {
          Modal.confirm({
            title: 'คุณต้องการออกจากหน้านี้ใช่หรือไม่ ?',
            icon: <ExclamationCircleOutlined />,
            content: "กรุณาบันทึกข้อมูลก่อนออกจากหน้านี้",
            okText: 'ออกจากหน้านี้',
            cancelText: 'ปิด',
            onOk: async () => {
              navigate("/task/" + params.project_id);
            }
          });
          // } else {
          //   navigate("/task/" + params.project_id)
          // }
        }} style={{ fontSize: "20px", marginLeft: "15px", cursor: "pointer" }}>Task</h2>
        <h2 style={{ fontSize: "20px", marginLeft: "10px" }}>&gt;</h2>
        <h2 style={{ fontSize: "20px", marginLeft: "10px", color: "black" }}>{taskdata.name}</h2>
      </Row>
      <Row>
        <Col
          // span={10} 
          lg={12}
          xl={10}
          style={{ marginRight: "10px" }}>
          <RuleBoard key={"result"} title="Result" height="600px" task={taskdata} lists={result} lists2={sublist} setLists={setResult} answer={answer} color="#E0FAE6" text="#53A078" info="#5578B9" search={true} setFound={setFound} setFound2={setFound2} />

        </Col>
        <Col
          lg={10}
          xl={13}
        //  span={13}
        >

          <Board key={"subtitle"} title="Conversation" height="600px" task={taskdata} lists={sublist} setLists={setSubList} color="#E0FAE6" text="#53A078" info="#5578B9" search={true} debugging={debuglist} foundArr={foundArr} foundArr2={foundArr2} />

        </Col>
      </Row>
    </div>
  );
};

export default ConfigTask;
