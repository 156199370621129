import config from "../config/config";
// import { getToken } from "../utils/prefix";

export async function group_create(data) {
  const res = await fetch(config.api_backend + `/group/create`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function group_update(data) {
  const res = await fetch(config.api_backend + `/group/update`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function group_delete(data) {
  const res = await fetch(config.api_backend + `/group/delete`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function terms_delete(data) {
  const res = await fetch(config.api_backend + `/group/deleteTerms`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_group(data) {
  const res = await fetch(config.api_backend + `/group/getGroupById`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_terms(data) {
  const res = await fetch(config.api_backend + `/group/getTerms`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_all_group(data) {
  const res = await fetch(config.api_backend + `/group/all`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}


export async function save_group_status(data) {
  const res = await fetch(config.api_backend + `/group/saveGroupStatus`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

