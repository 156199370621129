import { useState, useEffect } from "react";
import { Button, Card, Col, Menu, Row, Select, message, Switch, Dropdown, Modal, Empty, Tooltip, Image, Checkbox } from "antd";
import Search from "antd/es/input/Search";
import { ExclamationCircleOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { get_original_transcribe, get_task_answer, save_task_answer, save_task_status, get_task, test_analyze } from "../../../services/task";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import type { MenuProps } from 'antd';
import customer from '../../../assets/images/icons/user-service.png';
import agent from '../../../assets/images/icons/customer-service.png';
import '../styles/index.less';
import { useAuth } from "../../../utils/Auth/AuthWrapper";

interface IBoardProps {
    title: string;
    task: any;
    lists: [];
    setLists: any;
    color: any;
    height: string;
    search: boolean;
    text: string;
    info: string;
    debugging: any;
    foundArr: any;
    foundArr2: any;
}

interface ICard {
    id: number;
    text: string;
}



const Board: React.FC<IBoardProps> = ({ title, task, lists, setLists, color, height, search, text, info, debugging, foundArr, foundArr2 }) => {
    const params = useParams();
    const history = useLocation();
    const navigate = useNavigate();
    const onSearch = (value: string) => console.log(value);
    const [checked, setChecked] = useState(true);
    const [lengthMasking, setLengthMasking] = useState(0);
    const [maskingObj, setMasking] = useState([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [show, setShow] = useState(true);
    const [data, setData] = useState<any>([]);
    const [soundMasking, setSoundMasking] = useState(false);
    const { user } = useAuth();


    const downloadJson = async () => {
        const res = await get_original_transcribe({ task_id: task.task_id, track_id: task.track_id });
        // console.log("original subtitle: ",res);

        let storageObj = res.result;
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(storageObj, null, 2));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", params.task_id + '_' + params.track_id + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }



    const onChangeVerify = async (checked: boolean) => {
        if (checked === true) {
            let verifyTask = await save_task_status({ task_id: task.task_id, track_id: task.track_id, status: 7 });
            if (verifyTask.message === 'success') {
                message.success(`Verify Task สำเร็จ`);
                setChecked(true);
            } else {
                message.error(`Verify Task ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
            }
        } else {
            let unverifyTask = await save_task_status({ task_id: task.task_id, track_id: task.track_id, status: 6 });
            if (unverifyTask.message === 'success') {
                message.success(`Unverify Task สำเร็จ`);
                setChecked(false);
            } else {
                message.error(`Unverify Task ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
            }
        }
    }

    const getStatusTask = async () => {
        try {
            const res = await get_task({ data: { task_id: params.task_id, track_id: params.track_id } });
            // console.log("res get task :", res.data[0]);
            // setTaskData(res.data[0]);
            if (res.data[0].status === 7) {
                setChecked(true);
            } else {
                setChecked(false);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        getStatusTask();
    }, [task])

    useEffect(() => {

        if (lists.length > 0) {
            // const filteredMasking = lists.filter((obj) => obj['intent']["length"] >= 0);
            console.log("found: ", foundArr);

            setLengthMasking(foundArr.length);
            setMasking(foundArr);
            // getAnswer();
            setShow(true);

            const newArray: any = lists.map((obj1: any) => {

                const matchingObj2 = debugging.find((obj2: any) => obj2.id.toString() === obj1.id.toString()); // id
                if (matchingObj2) {
                    // console.log(matchingObj2);
                    let debug_txt = "";
                    for (let i in matchingObj2.message) {

                        debug_txt += matchingObj2.message[i];

                    }

                    return {
                        answer: obj1.answer,
                        endTime: obj1.endTime,
                        id: obj1.id,
                        message: obj1.message,
                        speaker: obj1.speaker,
                        startTime: obj1.startTime,
                        debugging: debug_txt,
                        collapse: false
                    };
                }
                return null;
            });
            // console.log(newArray);

            setData(newArray);
            // setData(lists);
        } else {
            setLengthMasking(0);
            setShow(false);
            setMasking([]);
        }
    }, [lists])



    // const getAnswer = async () => {
    //     if (answer === null) {
    //         setSelectedValues([]);
    //     } else {
    //         setSelectedValues(answer);
    //     }
    // };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div onClick={downloadJson}>
                    Download Json
                </div>
            )
        },
        // {
        //     key: '2',
        //     label: (
        //         <div onClick={downloadSound}>
        //             Download Sound
        //         </div>
        //     )
        // }
    ];

    const onChangeMasking = (value: string) => {
        // Check if the value is already in the array
        if (selectedValues.includes(value)) {
            // If it is, remove it
            setSelectedValues(selectedValues.filter((item) => item !== value));
        } else {
            // If it's not, add it to the array
            setSelectedValues([...selectedValues, value]);
        }
    };

    const sendAnalyze = async () => {

        // console.log('answer', selectedValues);
        const res = await test_analyze(data);

        // console.log('res answer', res);
        if (res.status === "success") {
            message.success(`Save สำเร็จ`);
        } else {
            message.error(`Save ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
        }

    }

    const sendAnswer = () => {
        Modal.confirm({
            title: 'ท่านต้องการบันทึกข้อมูลใช่หรือไม่ !',
            icon: <ExclamationCircleOutlined />,
            // content: "ท่านต้องการบันทึกข้อมูลหรือไม่ !",
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: async () => {
                // console.log('answer', selectedValues);
                const res = await save_task_answer({
                    task_id: task.task_id,
                    track_id: task.track_id,
                    data: selectedValues
                });

                // console.log('res answer', res);
                if (res.status === 200) {
                    message.success(`Save สำเร็จ`);
                } else {
                    message.error(`Save ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
                }
            }
        });
    }

    const changeSoundMasking = (id: number) => {
        let setNewData = data.map((row: any) => {
            if (row.id === id) {
                return { ...row, collapse: !row.collapse }
            }
            return row;
        })
        setData(setNewData);
    }

    let current_utt = "utt0";

    const jumpTo = (time: any) => {

        console.log(time);
        // console.log(foundArr?.filter((item: any) => item.utt_id == time)[0]);
        

        (document.getElementById("utt" + time) as HTMLElement).scrollIntoView({ behavior: "smooth", inline: "start", block: "nearest" });

        // (document.getElementById(current_utt) as HTMLElement).style.borderColor = "#d9d9d9";
        // (document.getElementById("utt" + time) as HTMLElement).style.borderColor = "red";
        // console.log(foundArr[0]);
        // setHightlight(foundArr[0]);
        // console.log(foundArr2);
        // for (let a in foundArr) {
        //     if(foundArr[a].utt_id == time){
        //         console.log(foundArr[a].matched_text);
        //         for (let b in foundArr[a].matched_text) {
        //             highlight(time + "_text", foundArr[a].matched_text[b]);
        //         }
        //     }
        // }

        current_utt = "utt" + time;
    }

    function Highlight(text: any, tags: any) {
        if (!tags?.length) return text;
        const matches = [...text.toUpperCase().matchAll(new RegExp(tags.join("|"), "ig"))];
        const startText = text.slice(0, matches[0]?.index);
        return (
            <span>
                {startText}
                {matches.map((match: any, i: any) => {
                    const startIndex = match.index;
                    const currentText = match[0];
                    const endIndex = startIndex + currentText.length;
                    const nextIndex = matches[i + 1]?.index;
                    const untilNextText = text.slice(endIndex, nextIndex);
                    return (
                        <span key={i} >
                            <mark style={{ backgroundColor: '#FFCC70' }}>{currentText}</mark>
                            {untilNextText}
                        </span>
                    );
                })}
            </span>
        );
    }

    // function getHighlightedText(text: any, highlight: any) {
    //     // Split on highlight term and include term into parts, ignore case
    //     const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    //     return <span> {parts.map((part: any, i: any) =>
    //         <span key={i} style={part.toUpperCase() === highlight.toUpperCase() ? { backgroundColor: '#FFCC70' } : {}}>
    //             {part}
    //         </span>)
    //     } </span>;
    // }


    return (
        <Card title={
            <Row gutter={16}>

                {/* <Col span={24}>
                    <Row justify="space-between">
                        <h1 style={{ fontSize: "18px", marginTop: "10px", color: "black" }}>{title}</h1>
                    </Row>
                </Col> */}
                {/* <Col span={2} style={{ marginRight: '30px', marginTop: "10px" }}><Row><h1 style={{ fontSize: "18px", color: "black" }}>Hilight: {lengthMasking}</h1></Row></Col>
                <Col span={20} style={{ paddingBottom: '10px', overflow: 'hidden', marginTop: "10px" }}>
                    <Row style={{ maxHeight: '70px', overflow: 'auto' }}>
                        {maskingObj.map((key: any, i) => (
                            <Button style={{ margin: '2px', fontSize: '10px', height: '30px', padding: '8px' }} onClick={() => jumpTo(key.id)}>{key.id}</Button>
                        ))}
                    </Row>
                </Col> */}
                <Col span={12} style={{ marginRight: '30px', marginTop: "10px" }}><Row><h1 style={{ fontSize: "18px", color: "black" }}>Conversation</h1></Row></Col>
                <Col span={12} style={{ marginRight: '30px', marginTop: "10px", position: "absolute", right: "10px" }}><Row><h1 style={{ fontSize: "18px", color: "black" }}>Found: {(foundArr?.length + foundArr2?.length)}</h1></Row></Col>
                <Col span={24} style={{ paddingBottom: '10px', overflow: 'hidden', marginTop: "10px" }}>
                    <Row style={{ maxHeight: '70px', overflow: 'auto' }}>
                        {foundArr.map((key: any) => (
                            <Button style={{ margin: '2px', fontSize: '10px', height: '30px', padding: '8px', borderColor: '#378CE7', color: '#378CE7' }} onClick={() => {
                                jumpTo(key.utt_id)

                            }}>{parseInt(key.utt_id) + 1}</Button>
                        ))}
                    </Row>
                    <Row style={{ maxHeight: '70px', overflow: 'auto' }}>
                        {foundArr2.map((key: any) => (
                            <Button style={{ margin: '2px', fontSize: '10px', height: '30px', padding: '8px', borderColor: 'rgb(90 55 231)', color: 'rgb(90 55 231)' }} onClick={() => {
                                jumpTo(key.utt_id)

                            }}>{parseInt(key.utt_id) + 1}</Button>
                        ))}
                    </Row>
                </Col>





            </Row>

        }>

            {show === true ?
                <div>
                    <div style={{ height: '600px', overflowY: "auto", overflowX: "hidden", marginBottom: "30px", paddingRight: "10px" }}>
                        {data.map((card: any) => (
                            <div>
                                {card.message !== '' ?
                                    <Row gutter={16}>
                                        <Col xs={2} xl={2} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', paddingTop: '8px', paddingRight: 'unset !important' }}>
                                            {card.speaker !== 'customer' ?
                                                <div>
                                                    <Image height={40} preview={false} src={agent} style={{ width: '40px' }} /> {card.startTime}</div>
                                                : ''}
                                            {card.speaker === 'customer' ?
                                                <div>
                                                    <Image height={40} preview={false} src={customer} style={{ width: '45px' }} />{card.startTime}</div>
                                                : ''}
                                        </Col>

                                        <Col xs={2} xl={1} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '10px' }}>
                                            <div>{card.id + 1}</div>
                                        </Col>


                                        <Col xs={14} xl={18}
                                            //  onClick={() => { sendSound(card.startTime) }}
                                            className={card.masking} style={{ marginLeft: '5px' }}>

                                            <div>
                                                <Card id={"utt" + card.id} className='text-sm draggableItem' type="inner" style={{ fontSize: "16px", marginTop: 10, borderStyle: "solid", borderColor: foundArr?.filter((item: any) => item.utt_id == card.id).length == 0 && foundArr2?.filter((item: any) => item.utt_id == card.id).length == 0 ? '#d9d9d9' : '#FF8911', color: "black", cursor: "text" }} >
                                                    {foundArr?.filter((item: any) => item.utt_id == card.id).length == 0 && foundArr2?.filter((item: any) => item.utt_id == card.id).length == 0  ?
                                                        
                                                        <div id={card.id + "_text"}>{card.message} </div>

                                                    : foundArr?.filter((item: any) => item.utt_id == card.id).length == 0 ? Highlight(card.message, foundArr2?.filter((item: any) => item.utt_id == card.id)[0].matched_text):
                                                    Highlight(card.message, foundArr?.filter((item: any) => item.utt_id == card.id)[0].matched_text)}

                                                </Card>
                                                {card.collapse === true ?
                                                    <Card key={card.id} id={'masking' + card.id} className='text-sm draggableItem' type="inner" style={{ fontSize: "16px", marginTop: 10, borderStyle: "solid", borderColor: '#d9d9d9', color: "white", backgroundColor: 'black' }} >
                                                        <div>{card.debugging} </div>
                                                    </Card> :
                                                    ''}
                                            </div>

                                        </Col>

                                        {/* <Col xs={14} xl={18} onClick={() => { 
                                            // sendSound(card.startTime)
                                             }} id={card.startTime} className={card.masking} style={{ marginLeft: '5px' }}>
                                            {card.masking === 'true' ?
                                                <Tooltip title={card.message} trigger="hover" overlayStyle={{ maxWidth: '70%' }}>
                                                    <Card className='text-sm draggableItem' type="inner" style={{ fontSize: "16px", marginTop: 10, borderStyle: "solid", borderColor: '#d9d9d9', color: 'black', backgroundColor: '#fbf35524' }} >
                                                        <div>{card.maskingMessage} </div>
                                                    </Card>
                                                    {card.collapse === true ?
                                                        <Card key={card.id} id={'masking' + card.id} className='text-sm draggableItem' type="inner" style={{ fontSize: "16px", marginTop: 10, borderStyle: "solid", borderColor: '#d9d9d9', color: "white", backgroundColor: 'black' }} >
                                                            <div>{card.debugging} </div>
                                                        </Card> :
                                                        ''}
                                                </Tooltip>
                                                :
                                                <div>
                                                    <Card className='text-sm draggableItem' type="inner" style={{ fontSize: "16px", marginTop: 10, borderStyle: "solid", borderColor: '#d9d9d9', color: "black" }} >
                                                        <div>{card.message} </div>
                                                    </Card>
                                                    {card.collapse === true ?
                                                        <Card key={card.id} id={'masking' + card.id} className='text-sm draggableItem' type="inner" style={{ fontSize: "16px", marginTop: 10, borderStyle: "solid", borderColor: '#d9d9d9', color: "white", backgroundColor: 'black' }} >
                                                            <div>{card.debugging} </div>
                                                        </Card> :
                                                        ''}
                                                </div>
                                            }
                                        </Col> */}

                                        <Col xs={3} xl={2} style={{ alignItems: 'flex-start', display: 'flex', paddingTop: '8px', paddingRight: 'unset !important' }} >
                                            {/* <Checkbox key={card.id} onChange={(() => [onChangeMasking(card.id), getChangeAnswer([...selectedValues, card.id], card.id)])} style={{ fontSize: "14px", color: "black", width: "100%", textAlign: "center" }} defaultChecked={card.answer}>Answer</Checkbox> */}
                                            {user.role == "Administrator" && (<Button onClick={(e) => changeSoundMasking(card.id)} style={{ height: '20px', padding: 'unset', display: 'flex', justifyContent: 'center', alignItems: 'center' }} icon={soundMasking === true ? <UpOutlined style={{ fontSize: '10px' }} /> : <DownOutlined style={{ fontSize: '10px' }} />}></Button>)}
                                        </Col>
                                    </Row>
                                    : ''}
                            </div>
                        ))}
                    </div>
                    <div style={{ justifyContent: 'end', display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <Dropdown menu={{ items }}>
                            <Button type="primary" style={{ width: 'fit-content' }}>Export</Button>
                        </Dropdown>
                        {/* <Button type="primary" onClick={sendAnswer}>Save Masking</Button>
                        <Button type="primary" onClick={sendAnalyze}>Analyze</Button> */}
                        <Switch checked={checked} onChange={onChangeVerify} />Verify
                    </div>
                </div>
                : <div style={{ height: '600px', display: 'grid', alignItems: 'center' }}><Empty /></div>}
        </Card>
    );
};

export default Board;
