import { DownOutlined, EditOutlined, UpOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Drawer, Form, Input, message, Space, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import {  useEffect, useState } from 'react'
import config from "../config/config";
import "./style/Profile.less"
import { get_profile, edit_profile, upload_profilepic } from '../services/user';
// import { translate } from '../utils/libs/translate';

const ProfileAvatar = ({ src, icon }) => {
    return (
        <Avatar
            size={{
                xs: 40,
                sm: 64,
                md: 80,
                lg: 100,
                xl: 150,
                xxl: 200,
            }}
            src={src}
            {...icon ? icon = { icon } : null}
        />
    )
}

const Uploader = ({form,previewImage,setPreviewImage}) => {
    //turn image into base64 then set preview
    const handleChange = (image) => {
        const reader = new FileReader()
        form.setFieldsValue({
            profile: image.file.originFileObj
        })
        reader.readAsDataURL(image.file.originFileObj)
        reader.onload = () => {
            setPreviewImage(reader.result)
        }
    };
    return (
        <ImgCrop>
            <Upload
                name="profile"
                listType='picture-circle'
                className="profile-uploader"
                showUploadList={false}
                onChange={handleChange}
                maxCount={1}
            >
                {previewImage ? (
                    <ProfileAvatar src={previewImage} />
                ) : (
                    <UploadOutlined />
                )}
            </Upload>
        </ImgCrop>
    )
}

const Profile = ({ userData, form, isProfileOpen, onClose, isEdit, setIsEdit, passwordFormVisible, setPasswordFormVisible, getProfile }) => {
    const reqPic = config.api_backend+'/pic/';
    //State
    const [messageApi, contextHolder] = message.useMessage()
    const [imageUrl, setImageUrl] = useState(userData.profile_img);
    const [previewImage, setPreviewImage] = useState();
    
    useEffect(()=>{
        setImageUrl(userData.profile_img)
    }, [userData])

    //reset all the state
    const resetProfileEdit = () => {
        setIsEdit(false)
        setPasswordFormVisible(false)
        setPreviewImage(null)
        setPasswordFormVisible(true)
        form.resetFields()
        setPasswordFormVisible(false)
    }

    //sucess message
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Update profile success',
        });
    }

    const handleEdit = () => {
        (isEdit) ? setIsEdit(false) : setIsEdit(true)
        setPreviewImage(null)
    }

    const togglePassword = () => {
        passwordFormVisible ? setPasswordFormVisible(false) : setPasswordFormVisible(true)
    }

    const onFinish = async(values) => {
        console.log("values",values);
        const formData = new FormData();
        if (values.profile) {
            formData.append('file', values.profile);
        }
        delete values.profile;
        

        //get user data from local storage
        let user = JSON.parse(localStorage.getItem("stt"))
        try {
            const editRes = await edit_profile(values);
            const uploadRes = formData.get('file') ? await upload_profilepic(formData) : null;

            if (editRes.status === 1
                // && (!uploadRes || uploadRes.status === 200)
                ) {
                // const result = editRes.dataInfonew;
                // user.user_name = result.name;
                // user.profile_pic = formData.get('file') ? formData.get('file').name : user.profile_pic;
                // localStorage.setItem("stt", JSON.stringify(user));
                getProfile()
                setIsEdit(false);
                success();
            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Error cannot edit profile',
                });
            }
        } catch (error) {
            messageApi.open({
                type: 'error',
                content: 'Error cannot edit profile',
            });
        }
    };

    const handleSubmit = () => {
        form.submit()
    }


    return (
        <Drawer className='profile-drawer' title={("Profile")} placement="right" onClose={onClose} open={isProfileOpen} style={{textAlign:"center"}} >
            <h2>{contextHolder}</h2>
            <Space direction='vertical' className='w-full'>
                {/* Form */}

                <Form className="profile-form" onFinish={onFinish} form={form} >
                     <Form.Item
                        label={("Id")}
                        name="id"
                        style={{display:"none"}}
                        initialValue={userData.user_id}
                    >
                        </Form.Item>
                    <Form.Item
                        className='profile-pic'
                        label=""
                        name="profile"
                    >
                        {/* {isEdit ?
                            <Uploader form={form} previewImage={previewImage} setPreviewImage={setPreviewImage} />
                            : */}
                            <ProfileAvatar src={imageUrl} icon={<UserOutlined />} />
                        {/* // } */}
                    </Form.Item>
                    <Form.Item
                        label={isEdit ? ("Name") : ""}
                        name="name"
                        rules={
                            [{
                                required: true,
                                message: ("Please enter your name")
                            }]
                        }
                        initialValue={userData.user_name}
                    >
                        <Input className="profile-input" bordered={isEdit} disabled={!isEdit} />
                    </Form.Item>
                    
                    {/* <label className='profile-label'>{("Email")}</label> */}
                    <Form.Item
                        label={("Email")}
                        name="email"
                        rules={
                            [{
                                required: isEdit ? true : false,
                                message: ("Please enter your email")
                            }]
                        }
                        initialValue={userData.user_email}
                    >
                        
                        <Input className="profile-input" bordered={isEdit} disabled={!isEdit} />
                    </Form.Item>

                    <Form.Item
                        label={("Role")}
                        name="role"
                        initialValue={userData.role_name}
                    >
                        
                        <Input className="profile-input" bordered={false} disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label={("Organization")}
                        name="org"
                        initialValue={userData.org_name}
                    >
                        
                        <Input className="profile-input" bordered={false} disabled={true} />
                    </Form.Item>
                    
                   

                      
                    {isEdit ?
                        <div className='flex flex-col'>
                            <Button
                                type="link"
                                className='profile-text text-purple'
                                onClick={togglePassword}

                            ><span>{("change password")}{passwordFormVisible ? <UpOutlined /> : <DownOutlined />}</span></Button>
                            {/* password form item */}
                            {
                                passwordFormVisible ?
                                    <div className="slide-bottom">
                                        <Form.Item
                                            label={("Password")}
                                            name="newpassword"
                                            labelCol={{ span: 24 }}
                                            rules={
                                                [{
                                                    required: true,
                                                    message: ('Please enter your password')
                                                },
                                                {
                                                    pattern: /.{8,}/,
                                                    message: ('Password must contain at least 8 characters')
                                                }
                                                ]
                                            }
                                        >
                                            <Input.Password type={"password"} className="profile-text" />
                                        </Form.Item>
                                        <Form.Item
                                            label={("Confirm Password")}
                                            name="confirm"
                                            labelCol={{ span: 24 }}
                                            rules={
                                                [{
                                                    required: true,
                                                    message: ('Please confirm your password')
                                                },
                                                {
                                                    pattern: /.{8,}/,
                                                    message: ('Password must contain at least 8 characters')
                                                }
                                                    , ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('newpassword') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error(('Password must be match')));
                                                        },
                                                    })

                                                ]
                                            }

                                        >
                                            <Input.Password type={"password"} className="profile-text" />
                                        </Form.Item>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        : null
                    }
                </Form>
                {
                    //edit stat
                    isEdit ?
                        <>
                            <div className='profile-button-container'>
                                <Button className='profile-button cancel' onClick={resetProfileEdit}>{("Cancel")}</Button>
                                <Button type='primary' className='profile-button purple' onClick={handleSubmit}>{("Save")}</Button>
                            </div>
                        </>
                        : null
                }
            </Space>
            {/* edit icon button */}
            {
                isEdit ? null
                    :
                    <div className='edit-btn-wrapper'>
                        <Button shape="circle" icon={<EditOutlined className="profile-edit-icon" />} onClick={handleEdit} />
                    </div>
            }
        </Drawer>
    )
}

export default Profile