import React, { useEffect, useState, useRef } from "react";
import "./styles/index.less";
import config from "../../config/config";
import { useNavigate } from "react-router-dom";
import {
  Empty,
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spin
} from "antd";
import { useParams } from "react-router-dom";
import { CaretRightOutlined, ExclamationCircleOutlined, PauseOutlined } from "@ant-design/icons";
import { evaluate_task, get_subtitle, get_task, get_task_answer, get_transcribe, test_masking, update_task_acc } from "../../services/task";
import { get_project } from "../../services/project";
import Board from "./components/Board";

import Waveform from "./components/Waveform";

const { confirm } = Modal;

type Props = {};

const ConfigTask = (props: Props) => {
  const params = useParams();
  const [sublist, setSubList]: any = useState([]);

  const [rulelist, setRuleList]: any = useState([]);
  const [taskdata, setTaskData]: any = useState([]);
  const [projectData, setProjectData]: any = useState({});
  const [answer, setAnswer] = useState([]);
  const [evaluate, setEvaluate] = useState("");
  const [onPlay, setOnPlay] = useState(false);
  const [valueRadio, setValueRadio] = useState("subtitle");
  const [valueChoice, setValueChioce] = useState("1");
  const [dataVideo, setDataVideo] = useState<any>([]);
  const [timeVideo, setTimeVideo] = useState("");
  const [dataScenario, setDataScenario] = useState([{}, {}, {}, {}, {}, {}]);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [subpath, setSubpath] = useState("");
  const [allSub, setAllSub] = useState([]);
  const [indexSelect, setIndexSelect] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const video = document.getElementById("video") as HTMLVideoElement | null;
  const navigate = useNavigate();
  const scrollableDiv = document.getElementById(
    "scrollableDiv"
  ) as HTMLVideoElement | null;
  // test
  const wavesurfer = useRef<any>(null);

  // console.log(params);
  const selectSub = (time: any) => {
    try {
      let h = parseInt(time.split(":")[0]) * 3600;
      let m = parseInt(time.split(":")[1]) * 60;
      let s = parseFloat(time.split(":")[2]);

      let totalTime = h + m + s;
      if (totalTime < 1.0) {
        totalTime = 0.0;
      } else {
        totalTime = totalTime;
      }
      seekTimeVideo(totalTime);
    } catch (e) {
      console.error(e);
    }
  };

  const seekTimeVideo = (time: any) => {
    if (video !== null) {
      video.currentTime = time;
    }
  };

  const getSubtitle = async () => {
    try {
      const res0 = await get_subtitle({ task_id: params.task_id, track_id: params.track_id });
      // console.log("res get subtitle :", res0.result);

      const res = await test_masking(res0.result);
      // console.log("res get test result :", res.result);


      const res2 = await get_task_answer({ task_id: params.task_id, track_id: params.track_id });
      // console.log("res get answer :", res2.data);

      const res3 = await evaluate_task({ data: res.result, answer: res2.data });
      // console.log("res get answer :", res3.result);

      const res4 = await update_task_acc({ task_id: params.task_id, track_id: params.track_id, acc: res3.result });
      // console.log("res get answer :", res4);

      let sub;
      let i = 0;
      if (res.result.length > 0) {
        res.result.map((data: any) => {
          // console.log(data);
          let add = false;
          for (let data2 in res2.data) {
            if (i == 0) {
              if (res2.data[data2] == data.id) {
                add = true
                data.answer = true
                sub = [data]
              }
            } else {
              if (res2.data[data2] == data.id) {
                add = true
                data.answer = true
                sub.push(data);
              }

            }
          }
          if (!add) {
            if (i == 0) {
              data.answer = false
              sub = [data]
            } else {
              data.answer = false
              sub.push(data);
            }
          }

          i++
        })
        // console.log(sub);
        setSubList(sub);
        setAnswer(res2.data);
        setEvaluate(res3.result);
        // setLoading(false);
      } else {
        setSubList([]);
        setAnswer([]);
      }
    } catch (e) {
      console.error(e);
      setError(true);
    }
  };


  const getData = async () => {
    try {
      const res = await get_task({ data: { task_id: params.task_id, track_id: params.track_id } });
      // console.log("res get task :", res.data[0]);
      setTaskData(res.data[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getProjectData = async () => {
    try {
      const res = await get_project({ data: { project_id: params.project_id } });
      // console.log("res get project :", res.data[0]);
      setProjectData(res.data[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const sendSound = (time: any) => {
    let duration = wavesurfer.current.decodedData.duration;
    let calculatePercent = 0;
    calculatePercent = (time / duration);
    if (wavesurfer.current !== null) {
      wavesurfer.current.seekTo(calculatePercent);
      wavesurfer.current.play();
    }
  }

  const checkLoading = () => {
    var refreshId = setInterval(async function () {
      if (wavesurfer.current.getDuration() !== 0) { //if(wavesurfer.current.decodedData !== null){
        clearInterval(refreshId);
        setLoading(false);
      }
    }, 500);
  }

  const sendEvaluate = async () => {
    
  }

  useEffect(() => {
    checkLoading();
    getData();
    getProjectData();
    getSubtitle();
  }, [])

  return (
    <div className="content-container">
      <Spin spinning={loading} tip="กำลังประมวลผล..." size="large">
        <Row gutter={16} style={{ paddingBottom: '10px' }}>
          <h2 onClick={() => {
            Modal.confirm({
              title: 'คุณต้องการออกจากหน้านี้ใช่หรือไม่ ?',
              icon: <ExclamationCircleOutlined />,
              content: "",
              okText: 'ตกลง',
              cancelText: 'ยกเลิก',
              onOk: async () => {
                navigate("/project");
              }
            });

          }} style={{ fontSize: "20px", marginLeft: "15px", cursor: "pointer" }}>Project: {projectData.name}</h2>
          <h2 style={{ fontSize: "20px", marginLeft: "10px" }}>&gt;</h2>

          <h2 onClick={() => {
            Modal.confirm({
              title: 'คุณต้องการออกจากหน้านี้ใช่หรือไม่ ?',
              icon: <ExclamationCircleOutlined />,
              content: "",
              okText: 'ตกลง',
              cancelText: 'ยกเลิก',
              onOk: async () => {
                navigate("/task/" + params.project_id);
              }
            });

          }} style={{ fontSize: "20px", marginLeft: "15px", cursor: "pointer" }}>Task</h2><h2 style={{ fontSize: "20px", marginLeft: "10px" }}>&gt;</h2><h2 style={{ fontSize: "20px", marginLeft: "10px", color: "black" }}>{taskdata.task_id}</h2> <h2 style={{ fontSize: "20px", marginLeft: "10px" }}>&gt;</h2><h2 style={{ fontSize: "20px", marginLeft: "10px", color: "black" }}>{taskdata.name}</h2>
        </Row>
        <Board key={"subtitle"} title="Conversation" height="500px" task={taskdata} lists={sublist} evaluate={evaluate} setLists={setSubList} color="#E0FAE6" text="#53A078" info="#5578B9" search={true} sendSound={sendSound} jsonData={sublist} soundData={config.img_url + taskdata.file + ""} answer={answer} sendEvaluate={sendEvaluate} />

        {sublist.length > 0 ?
          <Card style={{ marginTop: "10px", height: "fit-content" }}>
            {taskdata.file !== undefined ?
              <Waveform url={config.img_url + taskdata.file + ""} wavesurfer={wavesurfer} lists={sublist} />
              : null}
          </Card>
          : <Card style={{ marginTop: "10px", height: "fit-content", display: 'none' }}>
            {taskdata.file !== undefined ?
              <Waveform url={config.img_url + taskdata.file + ""} wavesurfer={wavesurfer} lists={sublist} />
              : null}
          </Card>
        }
      </Spin>
    </div>
  );
};

export default ConfigTask;
