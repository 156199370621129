import config from "../config/config";
// import { getToken } from "../utils/prefix";


export async function org_create(data) {
  const res = await fetch(config.api_backend + `/organization/create`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function org_update(data) {
  const res = await fetch(config.api_backend + `/organization/update`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function org_delete(data) {
  const res = await fetch(config.api_backend + `/organization/delete`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_org(data) {
  const res = await fetch(config.api_backend + `/organization/getUserById`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_all_org(data) {
  const res = await fetch(config.api_backend + `/organization/all`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}


export async function save_org_status(data) {
  const res = await fetch(config.api_backend + `/organization/saveUserStatus`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}


