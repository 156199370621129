import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  Tag,
  Form,
  Radio,
  RadioChangeEvent,
  Switch,
  Tooltip,
  Select,
  Upload,
  Space,
  DatePicker,
  Spin,
  message
} from "antd";
import type { DatePickerProps } from 'antd';
import moment from "moment";
import 'moment/locale/th';
import {
  CarryOutFilled,
  CarryOutOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  EyeFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
  EyeTwoTone,
  HddFilled,
  InboxOutlined,
  PlusOutlined,
  ProfileOutlined,
  ReadFilled,
  SafetyCertificateFilled,
  SafetyCertificateOutlined,
  SettingFilled,
  UploadOutlined,
  LoadingOutlined,
  CommentOutlined
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import Search from "antd/es/input/Search";
import { get_all_org } from "../../services/org";
import { get_all_project, get_project, project_create, project_delete, project_update } from "../../services/project";
import TextArea from "antd/es/input/TextArea";
import { get_all_rule } from "../../services/rule";
import { get_all_task, save_task_files, save_task_status, task_create, task_delete, task_update, findTask, findStatusTask, filterTaskByDate, save_transcribe, text_tran, save_original_transcribe } from "../../services/task";
import { get_role } from "../../services/user";
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

const { confirm } = Modal;

type Props = {};

const Task = (props: Props) => {
  dayjs.extend(customParseFormat)
  dayjs.extend(advancedFormat)
  dayjs.extend(weekday)
  dayjs.extend(localeData)
  dayjs.extend(weekOfYear)
  dayjs.extend(weekYear)
  moment().locale('th');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [taskData, setTaskData]: any = useState([]);
  const [orgData, setOrgData]: any = useState([]);
  const [projectData, setProjectData]: any = useState({});
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isSpinnerOpen, setSpinnerOpen] = useState<any>(false);
  const [isCreate, setIsCreate] = useState<any>(true);
  const [useMeta, setUseMeta] = useState<any>(false);
  const [role, setRole] = useState(1);
  const params = useParams();
  const [userPermission, setUserPermission]: any = useState([]);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(1);
  const [dateFilters, setDateFilter] = useState('');
  const [getStatus, setStatus] = useState('');
  const [transcription, setTranscription] = useState([]);

  const propsModalUpload = {
    name: 'file',
    multiple: true,
    onChange: async (data: any) => {
      console.log('file data', data);

      console.log('file data: ', data.file.name);
      let type = data.file.name.split(".");
      console.log('file type: ', type[type.length - 1]);

      if (type[type.length - 1] == "json") {
        const fileReader = new FileReader();
        fileReader.readAsText(data.file, "UTF-8");
        fileReader.onload = (e: any) => {
          console.log("file result", e.target.result);
          setTranscription(e.target.result);
        };

        setFileUpload(data);
      } else {
        alert("please upload json file");
        setFileUpload(null);
      }

      form.setFieldsValue({
        file: data.file.name,
      });




    },
    // onDrop(e: any) {
    //   console.log('Dropped files', e.dataTransfer.files);
    // },
  };
  const [fileUpdaloads, setFileUpload] = useState<any>(null);


  const onRadioChange = (e: RadioChangeEvent) => {
    // console.log('radio checked', e.target.value);
    setRole(e.target.value);
  };

  const sortTask = localStorage.getItem('sortTask');
  const orderTask = localStorage.getItem('orderTask');

  const sortName = localStorage.getItem('sortName');
  const orderName = localStorage.getItem('orderName');


  const sortDurartion = localStorage.getItem('sortDurartion');
  const orderDuration = localStorage.getItem('orderDuration');

  const sortStatus = localStorage.getItem('sortStatus');
  const orderStatus = localStorage.getItem('orderStatus');

  const sortEvaluate = localStorage.getItem('sortEvaluate');
  const orderEvaluate = localStorage.getItem('orderEvaluate');

  const sortDate = localStorage.getItem('sortDate');
  const orderDate = localStorage.getItem('orderDate');

  const sortUpdatedate = localStorage.getItem('sortUpdatedate');
  const orderUpdatedate = localStorage.getItem('orderUpdatedate');

  let pagingNumber: any = localStorage.getItem('setCurrentPage');
  let pagingSize: any = localStorage.getItem('setPageSize');
  const selectStatus: any = localStorage.getItem('selectStatus');
  const dateFilter: any = localStorage.getItem('dateFilter');

  const columns: any = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, item: any, index: any) => {
        if (pagingNumber == null) {
          pagingNumber = 1;
          pagingSize = 10;
        }

        return ((parseInt(pagingNumber) - 1) * parseInt(pagingSize) + index + 1)
      },
    },
    // {
    //   title: <div style={{ fontSize: "19px", textAlign: "center" }}>Task</div>,
    //   dataIndex: "task",
    //   key: "task",
    //   defaultSortOrder: sortTask === 'true' ? orderTask : '',
    //   onHeaderCell: (column: any) => {
    //     return {
    //       onClick: () => {
    //         localStorage.setItem('sortTask', 'true');
    //         localStorage.setItem('sortName', 'false');
    //         localStorage.setItem('sortDurartion', 'false');
    //         localStorage.setItem('sortEvaluate', 'false');
    //         localStorage.setItem('sortDate', 'false');
    //         localStorage.setItem('sortStatus', 'false');
    //         localStorage.setItem('sortUpdatedate', 'false');
    //       }
    //     }
    //   },
    //   sorter: (a: any, b: any, sortOrder: any) => {
    //     localStorage.setItem('orderTask', sortOrder);
    //     return a.task_id.localeCompare(b.task_id)
    //   },
    //   render: (_: any, record: any) => {
    //     return <div>
    //       <Row align="middle" gutter={16}>
    //         <Col
    //           style={{
    //             fontWeight: 'bolder',
    //             color: 'black',
    //           }}
    //         >

    //           {record.task_id}

    //         </Col>
    //       </Row>
    //       {/* <div style={{ color: "#c0c0c0" }}>{record.track_id}</div> */}
    //     </div>;
    //   },
    // },
    {
      title: <div style={{ fontSize: "19px", textAlign: "center" }}>Name</div>,
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any, sortOrder: any) => {
        localStorage.setItem('orderName', sortOrder);
        return a.name.localeCompare(b.name)
      },
      defaultSortOrder: sortName === 'true' ? orderName : '',
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            localStorage.setItem('sortName', 'true');
            localStorage.setItem('sortTask', 'false');
            localStorage.setItem('sortDurartion', 'false');
            localStorage.setItem('sortDate', 'false');
            localStorage.setItem('sortEvaluate', 'false');
            localStorage.setItem('sortStatus', 'false');
            localStorage.setItem('sortUpdatedate', 'false');
          }
        }
      },
      render: (_: any, record: any) => {
        return <div>
          <Row align="middle" gutter={16}>
            <Col
              style={{
                fontWeight: 'bolder',
                color: 'black',
              }}
            >

              {record.name}

            </Col>
          </Row>
          {/* <div style={{ color: "#c0c0c0" }}>{record.description}</div> */}
        </div>;
      },
    },
    // {
    //   title: <div style={{ fontSize: "18px", textAlign: "center" }}>Group</div>,
    //   key: "group",
    //   align: "center",
    //   render: (_: any, record: any) => record.task_group,
    // },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Create Date</div>,
      key: "create_date",
      sorter: (a: any, b: any, sortOrder: any) => {
        localStorage.setItem('orderDate', sortOrder);
        let a_date_create: any = new Date(a.created_timestamp);
        let b_date_create: any = new Date(b.created_timestamp);
        return b_date_create - a_date_create
      },
      defaultSortOrder: sortDate === 'true' ? orderDate : '',
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            localStorage.setItem('sortName', 'false');
            localStorage.setItem('sortTask', 'false');
            localStorage.setItem('sortDurartion', 'false');
            localStorage.setItem('sortEvaluate', 'false');
            localStorage.setItem('sortDate', 'true');
            localStorage.setItem('sortStatus', 'false');
            localStorage.setItem('sortUpdatedate', 'false');
          }
        }
      },
      render: (_: any, record: any) => {
        return <div>{moment(record.created_timestamp).format('DD-MM-YYYY HH:mm')}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Last Update</div>,
      key: "last_login",
      sorter: (a: any, b: any, sortOrder: any) => {
        localStorage.setItem('orderUpdatedate', sortOrder);
        let a_date: any = new Date(a.lasted_update);
        let b_date: any = new Date(b.lasted_update);
        return b_date - a_date
      },
      defaultSortOrder: sortUpdatedate === 'true' ? orderUpdatedate : '',
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            localStorage.setItem('sortName', 'false');
            localStorage.setItem('sortTask', 'false');
            localStorage.setItem('sortDurartion', 'false');
            localStorage.setItem('sortEvaluate', 'false');
            localStorage.setItem('sortDate', 'false');
            localStorage.setItem('sortStatus', 'false');
            localStorage.setItem('sortUpdatedate', 'true');
          }
        }
      },
      render: (_: any, record: any) => {
        return <div>{moment(record.lasted_update, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm')}</div>;
      },
    },
    // {
    //   title: <div style={{ fontSize: "18px", textAlign: "center" }}>Type</div>,
    //   key: "type",
    //   align: "center",
    //   render: (_: any, record: any) => {
    //     let type = "";
    //     if (record.type % 2 == 0) {
    //       type = "Text";
    //     } else {
    //       type = "Voice";
    //     }
    //     return <div>{type}</div>;
    //   },
    // },
    // {
    //   title: <div style={{ fontSize: "18px", textAlign: "center" }}>Duration</div>,
    //   key: "group",
    //   align: "center",
    //   sorter: (a: any, b: any, sortOrder: any) => {
    //     localStorage.setItem('orderDuration', sortOrder);
    //     return b.duration - a.duration
    //   },
    //   defaultSortOrder: sortDurartion === 'true' ? orderDuration : '',
    //   onHeaderCell: (column: any) => {
    //     return {
    //       onClick: () => {
    //         localStorage.setItem('sortName', 'false');
    //         localStorage.setItem('sortTask', 'false');
    //         localStorage.setItem('sortStatus', 'false');
    //         localStorage.setItem('sortEvaluate', 'false');
    //         localStorage.setItem('sortDate', 'false');
    //         localStorage.setItem('sortDurartion', 'true');
    //         localStorage.setItem('sortUpdatedate', 'false');
    //       }
    //     }
    //   },
    //   render: (_: any, record: any) => {
    //     const seconds = record.duration;
    //     // ✅ get hh:mm:ss string
    //     const result = new Date(seconds * 1000).toISOString().slice(11, 19);
    //     return result;
    //   },
    // },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Status</div>,
      key: "status",
      sorter: (a: any, b: any, sortOrder: any) => {
        localStorage.setItem('orderStatus', sortOrder);
        localStorage.setItem('sortStatus', 'true')
        return b.status - a.status;

      },
      align: "center",
      defaultSortOrder: sortStatus === 'true' ? orderStatus : sortStatus == null && sortDurartion == null && sortEvaluate == null && sortName == null && sortTask == null && sortDate == null && sortUpdatedate == null ? 'descend' : '',
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            localStorage.setItem('sortName', 'false');
            localStorage.setItem('sortTask', 'false');
            localStorage.setItem('sortDurartion', 'false');
            localStorage.setItem('sortEvaluate', 'false');
            localStorage.setItem('sortDate', 'false');
            localStorage.setItem('sortStatus', 'true');
            localStorage.setItem('sortUpdatedate', 'false');
          }
        }
      },
      render: (_: any, record: any) => {
        let status = "";
        let color = "#c0c0c0";
        if (record.status == 1) {
          status = "New";
          color = "purple";
        }
        // else if (record.status == 5) {
        //   status = "Finish Analyze";
        //   color = "blue";
        // } 
        else if (record.status == 5 || record.status == 6) {
          status = "On verify..";
          color = "orange";
        } else if (record.status == 7) {
          status = "verified";
          color = "green";
        } else {
          status = "On transcribing...";
          color = "purple";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Precision/Recall/Accuracy</div>,
      key: "acc",
      align: "center",
      sorter: (a: any, b: any, sortOrder: any) => {
        localStorage.setItem('orderEvaluate', sortOrder);
        if (isNaN(parseInt(a.acc)) && isNaN(parseInt(b.acc))) {
          return 0; // NaN values are considered equal
        } else if (isNaN(parseInt(a.acc))) {
          return 1; // NaN values are placed at the end
        } else if (isNaN(parseInt(b.acc))) {
          return -1; // NaN values are placed at the end
        } else {
          return (parseInt(b.acc) || 0) - (parseInt(a.acc) || 0) // Sort numbers normally
        }
      },
      defaultSortOrder: sortEvaluate === 'true' ? orderEvaluate : '',
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            localStorage.setItem('sortName', 'false');
            localStorage.setItem('sortTask', 'false');
            localStorage.setItem('sortDurartion', 'false');
            localStorage.setItem('sortDate', 'false');
            localStorage.setItem('sortStatus', 'false');
            localStorage.setItem('sortEvaluate', 'true');
            localStorage.setItem('sortUpdatedate', 'false');
          }
        }
      },
      render: (_: any, record: any) => {
        let color = "green";
        let color1 = "green";
        let color2 = "green";
        // console.log(parseInt(record.acc));
        // if (parseInt(record.precition) == 0) {
        //   color = "#c0c0c0";
        // } else if (parseInt(record.precition) < 50) {
        //   color = "red";
        // } else if (parseInt(record.precition) < 80) {
        //   color = "orange";
        // }
        // if (parseInt(record.recall) == 0) {
        //   color1 = "#c0c0c0";
        // } else if (parseInt(record.recall) < 50) {
        //   color1 = "red";
        // } else if (parseInt(record.recall) < 80) {
        //   color1 = "orange";
        // }
        // if (parseInt(record.acc) == 0) {
        //   color2 = "#c0c0c0";
        // } else if (parseInt(record.acc) < 50) {
        //   color2 = "red";
        // } else if (parseInt(record.acc) < 80) {
        //   color2 = "orange";
        // }

        return <div style={{textAlign:"center"}}><b style={{ color: color }}>{record.precition}</b>/<b style={{ color: color1 }}>{record.recall}</b>/<b style={{ color: color2 }}>{record.acc}</b></div>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Action</div>,
      dataIndex: "",
      align: "center",
      key: "tags",
      render: (_: any, record: any) => (
        <Row gutter={16} justify={"center"}>
          {/* <Tooltip placement="bottom" title="Test">
            <Col style={{ color: "#3f8dc4", cursor: "pointer" }}
              onClick={async () => {
                navigate("/test/" + params.project_id + "/" + record.task_id + "/" + record.track_id);
              }
              }>
              <CommentOutlined style={{ color: "gray", fontSize: "18px" }} />
            </Col>
          </Tooltip> */}
          {record.status === 2 || record.status === 3 || record.status === 4 ?
            <Tooltip placement="bottom" title="Processing...">
              <Col style={{ color: "#3f8dc4", cursor: "pointer" }}>
                <LoadingOutlined style={{ color: "#3f8dc4", fontSize: "18px" }} />
              </Col>
            </Tooltip>
            :
            <Tooltip placement="bottom" title="Result">
              <Col
                style={{ color: "#3f8dc4", cursor: "pointer" }}
                onClick={async () => {
                  // if (record.status < 5) {
                  //   alert("Please wait for transcribing");
                  // } else {
                  // await save_task_status({ task_id: record.task_id, track_id: record.track_id, status: 6 });
                  navigate("/file/" + params.project_id + "/" + record.task_id + "/" + record.track_id);
                  // }
                }
                }
              >
                <CommentOutlined style={{ color: "#3f8dc4", fontSize: "18px" }} />
              </Col>
            </Tooltip>
          }

          <Tooltip placement="bottom" title="Edit">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {

                getFocusTask(record);
                setIsCreate(false);
                setIsModalOpen(true);


              }
              }
            >
              <EditFilled style={{ color: "#f3c62b", fontSize: "18px" }} />
            </Col>
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {
                if (record.role_id == "99999999-0000-0000-0000-999999999999") {
                  Modal.error({
                    title: 'Permission Denined!',
                    content: `Not allow to edit Super Admin's data.`,
                  });
                } else {
                  showDeleteConfirm(record.task_id, record.track_id);
                }
              }}
            >
              <DeleteFilled style={{ color: "#d01919", fontSize: "18px" }} />
            </Col>
          </Tooltip>
        </Row>
      ),
    },
  ];

  const getFocusTask = async (record: any) => {
    console.log("get current task :", record);

    form.setFieldsValue({
      task_id: record.task_id,
      track_id: record.track_id,
      org: record.org_id,
      group: record.task_id,
      name: record.name,
      description: record.description
    });

  }

  const onChangeDate = async (date: any, dateString: any) => {
    localStorage.setItem('dateFilter', dateString);
    setDateFilter(dateString);
  };

  const filterDate = async (status: any, date: any) => {
    if (localStorage.getItem('getalldata') !== null) {
      const onFilter = await filterTaskByDate({ start_date: date !== '' ? date + ' 00:00:00' : '', end_date: date !== '' ? date + ' 23:59:59' : '', status: status ? status : '' });
      setTaskData(onFilter.data)
    }
  }

  useEffect(() => {
    getOrgData();
    getProjectData();
    getData();
    localStorage.setItem('selectStatus', '');
    if (dateFilter === null) {
      localStorage.setItem('dateFilter', '');
    }
  }, []);

  useEffect(() => {
    onSelectTask(selectStatus);
  }, [selectStatus])

  useEffect(() => {
    if (dateFilter === null) {
      localStorage.setItem('dateFilter', '');
    }
    filterDate(getStatus, dateFilter);
  }, [getStatus, dateFilter])

  const getOrgData = async () => {
    try {
      const res = await get_all_org();
      // console.log("res get org :", res.result);
      setOrgData(res.result);
    } catch (e) {
      console.error(e);
    }
  };

  const getProjectData = async () => {
    try {
      const res = await get_project({ data: { project_id: params.project_id } });
      // console.log("res get project :", res.data[0]);
      setProjectData(res.data[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    // if (localStorage.getItem('getalldata') === null) {
    try {
      const role = await get_role();
      // console.log("res get role :", role.result);

      // const res = await get_all_rule({ org_id: role.result[0].org_id });
      const res = await get_all_task({ data: { project_id: params.project_id } });
      console.log("res get task :", res.result);

      let sortTask = res.result.sort((a: any, b: any) => a.id - b.id);
      setUserPermission(role.result[0]);
      console.log("res get task :", sortTask);
      // if (selectStatus === '') {
      setTaskData(sortTask);
      // }
      localStorage.setItem('getalldata', 'true');
    } catch (e) {
      console.error(e);
    }
    // }
  };

  const showDeleteConfirm = (id: string, track: string) => {
    confirm({
      title: "Are you sure to delete this task?",
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // console.log("delete row:", id);

        try {
          const res = await task_delete({ data: { task_id: id, track_id: track } });
          getData();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const handleCheckMetaFile = async (value: any) => {
    // console.log("use meta");
    setUseMeta(!useMeta);
  };

  const handleCreateTask = async (value: any) => {
    setIsModalOpen(false);
    setSpinnerOpen(true);
    try {
      if (userPermission.role_name !== "Super Admin") {
        form.setFieldsValue({
          org: userPermission.org_id,
        });
      }

      // console.log('filesValue', fileUpdaloads.file.name);
      // console.log(fileUpdaloads, ' dataFiles ', form.getFieldValue('file'));

      if (fileUpdaloads == null) {
        alert("you should upload file first");
        return;
      }



      const res = await task_create({
        data: {
          name: form.getFieldValue('name'),
          description: form.getFieldValue('description'),
          group: form.getFieldValue('group'),
          file: fileUpdaloads.file.name,
          project_id: params.project_id,
          org_id: form.getFieldValue('org'),
          type: form.getFieldValue('file')
        },
      });

      console.log(res.status);



      if (res.status == "success") {
        let tt = JSON.parse(transcription.toString());
        let text_tran_m = [];
        let index = 0;
        console.log("before text: ", tt);

        for (let a = 0; a < tt.length; a++) {
          // console.log("utt: ", tt[a]);
          if (a == 0) {
            text_tran_m.push(tt[a]);
            index++;
            text_tran_m[index - 1].id = index - 1;
          } else {
            if (tt[a].speaker == tt[a - 1].speaker) {
              text_tran_m[index - 1].message += tt[a].message;
              text_tran_m[index - 1].endTime = tt[a].endTime;

            } else {
              text_tran_m.push(tt[a]);
              index++;
              text_tran_m[index - 1].id = index - 1;
            }
          }
        }

        console.log("merge text: ", text_tran_m);
        let transcription2 = text_tran_m;
        for (let b in text_tran_m) {
          // const res2 = await text_tran({
          //   "input": text_tran_m[b].message
          // });

          // console.log('Texttrans Response', res2);

          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("x-access-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmciOiJhaTkiLCJzZXJ2aWNlIjoic3lzdGVtIiwiaWF0IjoxNjAwMDkxMjUxLCJleHAiOjQxMzE2MjcyNTF9.ZrODaGffnS1u10wCjIYbXV-2sCfT6BkN4hgZxOMgiJo");

          const raw = JSON.stringify({
            "input": text_tran_m[b].message.toString()
          });



          await fetch("https://manna.ai9.co.th/texttran/api/v2/text/transform/string", {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
          })
            .then((response) => response.text())
            .then((result) => {
              // console.log(result);
              let re = JSON.parse(result);
              transcription2[b].message = re.result;
            })
            .catch((error) => console.error(error));


        }

        const res2 = await save_original_transcribe({
          "task_id": res.task_id,
          "track_id": res.track_id,
          "data": text_tran_m
        });
        console.log('Response', res2);

        if (res2.status == "success") {

          console.log("texttran: ", transcription2);

          const res1 = await save_transcribe({
            "task_id": res.task_id,
            "track_id": res.track_id,
            "data": transcription2
          });
          console.log('Response', res1);

          if (res2.status == "success") {
            getData();
            form.resetFields();
            setFileUpload(null);
            setSpinnerOpen(false);
            message.success(`Upload สำเร็จ`);
            setTranscription([]);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateTask = async (value: any) => {
    try {

      const res = await task_update({
        data: {
          task_id: form.getFieldValue('task_id'),
          track_id: form.getFieldValue('track_id'),
          name: form.getFieldValue('name'),
          description: form.getFieldValue('description'),
        },
      });

      if (res.message == "success") {


        // if (fileUpdaloads == null) {
        //   alert("you should upload file first");
        //   return;
        // }
        if (transcription.length <= 0) {
          getData();
          setIsModalOpen(false);
          form.resetFields();
        } else {

          setIsModalOpen(false);
          setSpinnerOpen(true);

          let tt = JSON.parse(transcription.toString());
          let text_tran_m = [];
          let index = 0;
          console.log("before text: ", tt);

          for (let a = 0; a < tt.length; a++) {
            // console.log("utt: ", tt[a]);
            if (a == 0) {
              text_tran_m.push(tt[a]);
              index++;
              text_tran_m[index - 1].id = index - 1;
            } else {
              if (tt[a].speaker == tt[a - 1].speaker) {
                text_tran_m[index - 1].message += tt[a].message;
                text_tran_m[index - 1].endTime = tt[a].endTime;

              } else {
                text_tran_m.push(tt[a]);
                index++;
                text_tran_m[index - 1].id = index - 1;
              }
            }
          }

          console.log("merge text: ", text_tran_m);
          let transcription2 = text_tran_m;
          for (let b in text_tran_m) {
            // const res2 = await text_tran({
            //   "input": text_tran_m[b].message
            // });

            // console.log('Texttrans Response', res2);

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("x-access-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmciOiJhaTkiLCJzZXJ2aWNlIjoic3lzdGVtIiwiaWF0IjoxNjAwMDkxMjUxLCJleHAiOjQxMzE2MjcyNTF9.ZrODaGffnS1u10wCjIYbXV-2sCfT6BkN4hgZxOMgiJo");

            const raw = JSON.stringify({
              "input": text_tran_m[b].message.toString()
            });



            await fetch("https://manna.ai9.co.th/texttran/api/v2/text/transform/string", {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow"
            })
              .then((response) => response.text())
              .then((result) => {
                // console.log(result);
                let re = JSON.parse(result);
                transcription2[b].message = re.result;
              })
              .catch((error) => console.error(error));


          }

          const res2 = await save_original_transcribe({
            "task_id": form.getFieldValue('task_id'),
            "track_id": form.getFieldValue('track_id'),
            "data": text_tran_m
          });
          console.log('Response', res2);

          if (res2.status == "success") {

            console.log("texttran: ", transcription2);

            const res1 = await save_transcribe({
              "task_id": form.getFieldValue('task_id'),
              "track_id": form.getFieldValue('track_id'),
              "data": transcription2
            });
            console.log('Response', res1);

            if (res1.status == "success") {
              getData();
              form.resetFields();
              setFileUpload(null);
              setSpinnerOpen(false);
              message.success(`Upload สำเร็จ`);
              setTranscription([]);
            }

          }
        }




      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSelectTask = async (value: string) => {
    if (value === null) {
      value = '';
    }
    localStorage.setItem('selectStatus', value);
    setStatus(value);
  };

  const onSearch = async (value: string) => {
    const find = await findTask({
      search: value,
    });
    setTaskData(find.data);
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onTabChange = (page: any, pageSize: any) => {
    localStorage.setItem('setCurrentPage', page);
    localStorage.setItem('setPageSize', pageSize);
    setPage(page);
    setPageSize(pageSize);
  }


  return (
    <div className="content-container">
      <Row>
        <h2 onClick={() => { navigate('/project') }} style={{ fontSize: "20px", cursor: "pointer" }}>Project: {projectData.name} </h2>
        <h2 style={{ fontSize: "20px", marginLeft: "10px" }}>&gt;</h2>
        <h2 style={{ fontSize: "20px", marginLeft: "10px", color: "black" }}>Task</h2>
      </Row>
      <div style={{ marginBottom: "2%", float: "right", display: 'flex', gap: '10px' }}>
        {dateFilter !== '' ?
          <DatePicker format="YYYY-MM-DD" onChange={onChangeDate} value={dayjs(dateFilter, 'YYYY-MM-DD')} allowClear={true} />
          :
          <DatePicker format="YYYY-MM-DD" onChange={onChangeDate} allowClear={true} />}
        <Select
          defaultValue={selectStatus === null ? '' : selectStatus}
          onChange={onSelectTask}
          style={{ width: '150px' }}
          options={[
            { value: '', label: 'All' },
            { value: '1', label: 'New' },
            // { value: '5', label: 'Finish transcribe' },
            { value: '6', label: 'On verify' },
            { value: '7', label: 'Verified' },
          ]}
        />
        <Search placeholder="Search Task" onSearch={onSearch} style={{ width: 300 }} />
        <Button
          type="primary"
          style={{ borderRadius: "5px", width: "180px" }}
          onClick={() => {
            form.resetFields();
            setIsCreate(true);
            setIsModalOpen(true);
          }}
        >
          <PlusOutlined /> New Task
        </Button>
      </div>

      <div className="table-project">
        <Table
          rowClassName="TaskTable"
          rowKey="id"
          sortDirections={['ascend', 'descend', 'ascend']}
          columns={columns}
          dataSource={taskData}
          pagination={{ defaultCurrent: pagingNumber ? parseInt(pagingNumber) : 1, defaultPageSize: pagingSize ? parseInt(pagingSize) : 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100', '250', '500'], onChange: (page, pageSize) => onTabChange(page, pageSize) }}
        />

        <Modal
          title={isCreate ? "New Task" : "Edit Task Data"}
          open={isModalOpen}
          // onOk={handleOk}
          onOk={() => form.submit()}
          onCancel={() => { setIsModalOpen(false); setUseMeta(false); }}
          width={600}
        >
          <div>
            <Form
              labelCol={{ flex: '110px' }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              form={form} onFinish={isCreate ? handleCreateTask : handleUpdateTask}>
              <div style={{ marginBottom: "3%", marginTop: "3%" }}>
                <Form.Item
                  name="task_id"
                  style={{ display: "none" }}
                >
                  <Input
                    placeholder="id"
                  />
                </Form.Item>

                <Form.Item
                  name="track_id"
                  style={{ display: "none" }}
                >
                  <Input
                    placeholder="id"
                  />
                </Form.Item>

                <Form.Item
                  name="org"
                  label="Organization"
                  rules={[
                    { required: userPermission.role_name !== "Super Admin" ? false : true, message: "Please input your organization name!" },
                  ]}
                  style={{ display: userPermission.role_name !== "Super Admin" ? "none" : "" }}
                >
                  <Select
                    showSearch
                    placeholder="Organization Name"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    options={(orgData || []).map((v: any) => ({
                      value: v.org_id,
                      label: v.name,
                    }))}

                    defaultValue={userPermission.role_name !== "Super Admin" ? userPermission.org_id : null}
                  />


                </Form.Item>

                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >

                  <Input
                    placeholder="Task name"
                    autoComplete="off"
                  />

                </Form.Item>

                {/* <Form.Item
                  name="group"
                  label="Group"
                  rules={[
                    { required: true, message: "Please select group!" },
                  ]}
                // style={{ display: "none" }}
                > */}
                {/* <Select
                    showSearch
                    placeholder="Group Name"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    // filterOption={(input, option) =>
                    //   (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    // }
                    // options={(projectData || []).map((v: any) => ({
                    //   value: v.project_id,
                    //   label: v.name,
                    // }))}
                  /> */}
                {/* <Input
                    placeholder="Group Name"
                    autoComplete="off"
                  />


                </Form.Item> */}

                <Form.Item
                  name="description"
                  label="Description"
                >
                  <TextArea rows={2}
                    placeholder="Task Description"
                    autoComplete="new-des"
                  />
                </Form.Item>



                <Form.Item
                  name="file"
                  label="File"
                  rules={[
                    { required: isCreate, message: "Please upload file!" },
                  ]}
                  style={{
                    // display: isCreate ? "" : "none",
                    marginBottom: "5px"
                  }}
                >
                  {/* <Space> */}
                  {/* <Row>
                    <Radio.Group>
                      <Radio value="1">Voice</Radio>
                      <Radio value="2">Text</Radio>
                    </Radio.Group>
                  </Row> */}

                  {/* </Space> */}

                  <div style={{
                    //  display: isCreate ? "" : "none",
                    marginBottom: "20px"
                  }}>

                    <Upload.Dragger {...propsModalUpload} maxCount={1}
                      fileList={fileUpdaloads == null ? [] : fileUpdaloads.fileList}
                      beforeUpload={() => false} name="files"  >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                    </Upload.Dragger>
                  </div>
                </Form.Item>
                {/* <Form.Item
                  name="file"
                  // label=" "
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    { required: true, message: "Please upload file!" },
                  ]}
                >
                 
                </Form.Item> */}

                {/* <Form.Item
                  name="metadata"
                  label="Metadata"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  style={{ display: isCreate ? "" : "none", marginBottom: "5px" }}
                // extra="longgggggggggggggggggggggggggggggggggg"
                > */}
                {/* <Upload name="logo" action="/upload.do" listType="picture">
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload> */}
                {/* <Checkbox onChange={handleCheckMetaFile}>
                    Check for upload metadata file (optional)
                  </Checkbox>

                </Form.Item>
                <Upload.Dragger name="files" action="/upload.do" style={{ display: useMeta ? "" : "none" }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag Metadata file to this area to upload</p>
                  <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                </Upload.Dragger> */}
              </div>
            </Form>
          </div>

        </Modal>
      </div>
      <Modal
        // title="Upload in progress.."
        closable={false}
        centered
        open={isSpinnerOpen}
        onCancel={() => { setSpinnerOpen(false) }}
        footer={false}
        style={{ textAlign: "center" }}
      >
        <div style={{ textAlign: "center" }}>Upload in progress.. <Spin size="large" /></div>
      </Modal>



    </div>
  );
};

export default Task;
