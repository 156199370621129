import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Card,
  Col,
  Image,
  Pagination,
  Row,
  Space,
  Tag,
  DatePicker,
  Empty,
  Spin,
} from "antd";
import NoImage from "../../../assets/images/logoMemo.jpg";
import { useNavigate } from "react-router-dom";
import { api_getAllVideo } from "../../../services/video";
import moment from "moment";
moment.updateLocale("th", {
  day: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    ,
    "Thursday",
    "Friday",
    "Saturday",
  ],
  weekdays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Today at] LT",
    lastWeek: "[Last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "dddd DD [at] LT",
  },
});

type Props = {};

const AllVideoPage = (props: Props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const getData = async () => {
    try {
      setIsLoading(true);
      const data = {
        type: "all",
      };
      const res = await api_getAllVideo(data);
      if (res.status == 200) {
        console.log("res video:", res);
        setData(res.data);
        setTotal(res.meta.total);
        delayLoading();
        // setIsLoading(false);
      }
    } catch (e) {
      delayLoading();
      console.error(e);
      // setIsLoading(false);
    }
  };

  const delayLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  useEffect(() => {
    getData();
  }, []);
  console.log("mock data :::", data);
  return (
    <div className="content-container" style={{ height: "100%" }}>
      <Row>
        <h2>All Video</h2>
      </Row>
      <Row>
        <h1 style={{ opacity: 0.8 }}> video</h1>
      </Row>
      <Row style={{ width: "100%" }}>
        {/* {isLoading ? (
          <Spin delay={5000000} spinning={isLoading} />
        ) : ( */}
        {/* <Spin spinning={isLoading} style={{ width: "100%" }}> */}
        <Row gutter={[32, 32]} style={{ width: "100%", padding: "10px 50px" }}>
          {
            // data.length == 0 ? (
            //   <Col xs={24}>
            //     <Row justify="center" align="middle" style={{ height: "40vh" }}>
            //       <Empty />
            //     </Row>
            //   </Col>
            // ) : (
            data.map((d: any) => {
              return (
                <Col
                  // span={8}
                  key={d.task_id}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Card
                    cover={
                      <div
                        style={{
                          height: "170px",
                          minHeight: "170px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <Image
                          preview={false}
                          style={{
                            // width: "100%",
                            // minHeight: "170px",
                            // height: "170px",
                            objectFit: "cover",
                            // objectPosition: "0px 1px",
                          }}
                          alt="example"
                          height={170}
                          width={"100%"}
                          // src={d.video_screenshot}
                          src={
                            d.image_path != ""
                              ? `${process.env.REACT_APP_API + d.image_path}`
                              : NoImage
                          }
                          // src={
                          //   "http://localhost:14001/api/task/image?task_id=" +
                          //     d.task_id || NoImage
                          // }
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: 5,
                            right: "0",
                            backgroundColor: "rgb(0 0 0/0.6)",
                            color: "white",
                            padding: "4px 6px",
                          }}
                        >
                          {new Date(d.duration * 1000)
                            .toISOString()
                            .slice(11, 19)}
                        </div>
                      </div>
                    }
                    bordered={false}
                    onClick={() => navigate(`/video/${d.task_id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <Row gutter={[8, 8]}>
                      <Col xs={24}>{d.title}</Col>
                      <Col
                        xs={24}
                        style={{ position: "relative", overflow: "hidden" }}
                      >
                        <Space size={[0, 2]} wrap={false}>
                          <Tag color="magenta">magenta</Tag>
                          <Tag color="red">red</Tag>
                          <Tag color="volcano">volcano</Tag>
                          <Tag color="volcano">volcano</Tag>
                          <Tag color="volcano">volcano</Tag>
                          <Tag color="volcano">+5</Tag>
                        </Space>
                      </Col>
                      <Col xs={24}>By mock creater</Col>
                      <Col xs={24}>
                        {" "}
                        {moment(d.created_timestamp).fromNow()}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })
            // )
          }
        </Row>
        {/* </Spin> */}
        {/* )} */}
      </Row>
      {total > 12 && (
        <Row align="bottom" justify="end" style={{ marginTop: "10px" }}>
          <Pagination
            total={total}
            showSizeChanger={false}
            // showQuickJumper
            showTotal={(total) => `Total ${total} items`}
          />
        </Row>
      )}
    </div>
  );
};

export default AllVideoPage;
