import { Card } from "antd";
import React from "react";

interface ITileProps{
	children: React.ReactNode;
    meta:string;
    color:any;
	text:string;
	info:string;
}
const { Meta } = Card;
const Tile:React.FC<ITileProps> = ({children, meta, color, text, info}) => {
	return (
		// <Card className='bg-white py-2 px-2 mb-2 transition-shadow shadow-lg rounded' type="inner" style={{ marginTop: 16 }}>
			<Card className='text-sm draggableItem' type="inner" style={{ fontSize:"18px",marginTop: 10,background: color,color:text }} >
               <b>{children} </b> 
            <Meta description={<small style={{color:info}}>{meta}</small>} />
            </Card>
		// </Card>
	);
};

export default Tile;
