import {
    AppstoreOutlined,
    MailOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    UserOutlined,
  } from "@ant-design/icons";
import { route } from "../../routes/routes";
import { t } from "i18next";

const user = JSON.parse(localStorage.getItem("stt"));


 export const items = route.map((d)=>{
    if(user){
      const res = d.access.filter((acc)=>{
        if(acc== user.role){
          return d
        }
      })


      if(res.length != 0){
        
        // console.log("name: ",d.name);
        
      if(d.routes){
       return {
          label: d.name,
          key: d.path,
          icon: <SettingOutlined />,
          access:d.access,
          children:d.routes.map((child)=>{
            return{
                      label: child.name,
                      key: d.path+child.path,
                      icon: <SettingOutlined />,
                      path:d.path+child.path
                    }
          })
        }
      }else if(d.name == "Project"){
        return  {
          label: d.name,
          key: d.path,
          icon: <UnorderedListOutlined />,
          path: d.path,
          access: d.access,
        }
      }else{
        // console.log("name: ",d.name);
        return  {
          label: d.name,
          key: d.path,
          icon: <UserOutlined />,
          path: d.path,
          access: d.access,
        }
      }
    }else{
      return null
    }
    }else{
      return null
    }
})

export const getMenuSider = () => {
  const user = JSON.parse(localStorage.getItem("stt"));

  const res = route.map((d)=>{
    if(user){
      const res = d.access.filter((acc)=>{
        if(acc== user.role){
          return d
        }
      })
      if(res.length != 0){
      if(d.routes){
       return {
          // label: d.name,
          label: t(d.name),
          key: d.path,
          icon: <SettingOutlined />,
          access:d.access,
          children:d.routes.map((child)=>{
            if(child.showMenu){
              return{
                label: child.name,
                key: d.path+child.path,
                icon: <SettingOutlined />,
                path:d.path+child.path
              }
            }else{
              return null
            }
           
          })
        }
      }else{
        if(d.showMenu){
          return  {
            // label: d.name,
            label: t(d.name),
            key: d.path,
            icon: d.icon,
            path: d.path,
            access: d.access,
          }
        }else{
          return  null
        }
        
      }
    }else{
      return null
    }
    }else{
      return null
    }
  })

  const res2 = res.filter((d)=> d != null)
  return res2
}