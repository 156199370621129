import { Col, Radio, Row } from "antd";
import React from "react";
import ScenarioResult from "./component/Scenario";
import Subtitle from "./component/Subtitle";
import Text from "./component/Text";
import Object from "./component/Object";
import Scene from "./component/Scene";

type Props = {
  valueRadio: any;
  setValueRadio: any;
  valueChoice: any;
  setValueChioce: any;
  dataScenario: any;
  setDataScenario: any;
  setisModalOpen: any;
  indexSelect: any;
  selectSub: any;
  allSub: any;
};

const DetailDisplay = ({
  valueRadio,
  setValueRadio,
  valueChoice,
  setValueChioce,
  dataScenario,
  setDataScenario,
  setisModalOpen,
  indexSelect,
  selectSub,
  allSub,
}: Props) => {
  return (
    <Col md={24} lg={12}>
      <Row>
        <Radio.Group
          onChange={(e) => setValueRadio(e.target.value)}
          value={valueRadio}
          buttonStyle="solid"
          style={{ marginBottom: 8, width: "100%" }}
        >
          <Radio.Button style={{ width: "50%" }} value="scenario">
            <Row justify="center">Scenario</Row>
          </Radio.Button>
          <Radio.Button style={{ width: "50%" }} value="analytics">
            <Row justify="center">Subtitle</Row>
          </Radio.Button>
        </Radio.Group>
      </Row>
      <Row>
        {valueRadio == "scenario" ? (
          <Radio.Group
            onChange={(e) => {
              setValueChioce(e.target.value);
              if (e.target.value == 1) {
                setDataScenario([{}, {}, {}, {}, {}, {}]);
              } else if (e.target.value == 2) {
                setDataScenario([{}, {}, {}]);
              } else {
                setDataScenario([
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                ]);
              }
            }}
            value={valueChoice}
            buttonStyle="solid"
            style={{ marginBottom: 8, width: "100%" }}
          >
            <Radio.Button style={{ width: "33.33%" }} value="1">
              <Row justify="center">โฆษณาเกินจริง</Row>
            </Radio.Button>
            <Radio.Button style={{ width: "33.33%" }} value="2">
              <Row justify="center">โฆษณาเกินเกณฑ์</Row>
            </Radio.Button>
            <Radio.Button style={{ width: "33.33%" }} value="3">
              <Row justify="center">เนื้อหาที่ไม่เหมาะสม</Row>
            </Radio.Button>
          </Radio.Group>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        <Col
          xs={24}
          style={{
            background: "#E2E2E8",
            height: "550px",
            borderRadius: "4px",
            padding: "30px 30px 20px 30px",
          }}
        >
          <Row>
            <h1>
              {valueRadio == "scenario" ? (
                <>
                  {valueChoice == "1"
                    ? "โฆษณาเกินจริง"
                    : valueChoice == "2"
                    ? "โฆษณาเกินเกณฑ์"
                    : "เนื้อหาที่ไม่เหมาะสม"}
                </>
              ) : (
                <>
                  {valueChoice == "1"
                    ? "Subtitle"
                    : valueChoice == "2"
                    ? "Topic"
                    : valueChoice == "3"
                    ? "Object"
                    : "Scene"}
                </>
              )}
            </h1>
          </Row>

          {valueRadio == "scenario" ? (
            <ScenarioResult
              dataScenario={dataScenario}
              setisModalOpen={setisModalOpen}
            />
          ) : (
            <div className="list-subtitle" id="scrollableDiv">
              <Subtitle
                indexSelect={indexSelect}
                selectSub={selectSub}
                allSub={allSub}
              />
            </div>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default DetailDisplay;
