import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  Tag,
  Form,
  Radio,
  RadioChangeEvent,
  Switch,
  Tooltip,
  Select,
} from "antd";
import moment from "moment";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  PlusOutlined,
  SettingFilled,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get_all_user, get_role, get_user, save_user_status, user_create, user_delete, user_update } from "../../services/user";
import Search from "antd/es/input/Search";
import { get_all_org } from "../../services/org";
import TextArea from "antd/es/input/TextArea";
import { get_all_rule, rule_create, rule_delete, rule_update } from "../../services/rule";

const { confirm } = Modal;

type Props = {};

const Rule = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [userData, setUserData]: any = useState([]);
  const [orgData, setOrgData]: any = useState([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isCreate, setIsCreate] = useState<any>(true);
  const [role, setRole] = useState(1);
  const editorUrl = "https://manna.ai9.co.th";
  const [userPermission, setUserPermission]: any = useState([]);

  const onRadioChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setRole(e.target.value);
  };


  const columns: any = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: <div style={{ fontSize: "19px", textAlign: "center" }}>Name</div>,
      dataIndex: "name",
      key: "name",
      sorter: (a: any) => a.name,
      width: "50%",
      render: (_: any, record: any) => {
        return <div>
        <Row align="middle" gutter={16}>
        <Col
          style={{
            fontWeight: 'bolder',
            color: 'black',
          }}
        >

          {record.name}

        </Col>
        </Row>
        <div style={{color:"#c0c0c0"}}>{record.description}</div>
      </div>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Last Update</div>,
      key: "last_update",
      render: (_: any, record: any) => {
        return <div>{moment(record.lasted_update).calendar()}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Action</div>,
      dataIndex: "",
      align: "center",
      key: "tags",
      render: (_: any, record: any) => (
        <Row gutter={16} justify={"center"}>
          <Tooltip placement="bottom" title="Config">
            <Col
              style={{ color: "#3fc468", cursor: "pointer" }}
              onClick={() => {

                navigate("/rule/"+record.rule_id)

              }
              }
            >
              <ShareAltOutlined style={{ color: "#3fc468", fontSize: "18px" }} />
            </Col>
          </Tooltip>
          <Tooltip placement="bottom" title="Config">
            <Col
              style={{ color: "#3f8dc4", cursor: "pointer" }}
              onClick={() => {

                navigate("/rule/json/"+record.rule_id)


              }
              }
            >
              <SettingFilled style={{ color: "#3f8dc4", fontSize: "18px" }} />
            </Col>
          </Tooltip>
          <Tooltip placement="bottom" title="Edit">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {

                getFocusRule(record);
                setIsCreate(false);
                setIsModalOpen(true);


              }
              }
            >
              <EditFilled style={{ color: "#f3c62b", fontSize: "18px" }} />
            </Col>
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {
                if (record.role_id == "99999999-0000-0000-0000-999999999999") {
                  Modal.error({
                    title: 'Permission Denined!',
                    content: `Not allow to edit Super Admin's data.`,
                  });
                } else {
                  showDeleteConfirm(record.rule_id);
                }
              }}
            >
              <DeleteFilled style={{ color: "#d01919", fontSize: "18px" }} />
            </Col>
          </Tooltip>
        </Row>
      ),
    },
  ];

  const getFocusRule = async (record: any) => {
    console.log("get current rule :", record);

    form.setFieldsValue({
      id: record.rule_id,
      org: record.org_id,
      name: record.name,
      description: record.description
    });

  }

  useEffect(() => {
    getOrgData();
    getData();
  }, []);

  const getOrgData = async () => {
    try {
      const res = await get_all_org();
      console.log("res get org :", res.result);
      setOrgData(res.result);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    try {
      const role = await get_role();
      console.log("res get role :", role.result);

      const res = await get_all_rule({org_id: role.result[0].org_id});
      console.log("res get rule :", res.result);
      setUserData(res.result);
      setUserPermission(role.result[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Are you sure to delete this rule?",
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        console.log("delete row:", id);

        try {
          const res = await rule_delete({ data: { rule_id: id } });
          getData();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCreateRule = async (value: any) => {
    try {
      if (userPermission.role_name !== "Super Admin") {
        form.setFieldsValue({
          org: userPermission.org_id,
        });
      }
      const res = await rule_create({
        data: {
          name: form.getFieldValue('name'),
          description: form.getFieldValue('description'),
          org_id: form.getFieldValue('org'),
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateRule = async (value: any) => {
    try {

      const res = await rule_update({
        data: {
          rule_id: form.getFieldValue('id'),
          name: form.getFieldValue('name'),
          description: form.getFieldValue('description'),
          org_id: form.getFieldValue('org'),
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
        window.location.href = window.location.toString();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSearch = (value: string) => console.log(value);

  return (
    <div className="content-container">
      <Row>
        <h2 style={{ fontSize: "32px", color: "black" }}>Rule</h2>
      </Row>
      <div style={{ marginBottom: "2%", float: "right" }}>
        <Search placeholder="Search Rule" onSearch={onSearch} style={{ width: 300, marginRight: "50px" }} />
        <Button
          type="primary"
          style={{ borderRadius: "5px", width: "180px" }}
          onClick={() => {
            form.resetFields();
            setIsCreate(true);
            setIsModalOpen(true);
          }}
        >
          <PlusOutlined /> New Rule
        </Button>
      </div>

      <div className="table-user">  
        <Table
          rowClassName="UserTable"
          rowKey="user_id"
          columns={columns}
          dataSource={userData}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100']}}
        />


        <Modal
          title={isCreate ? "New Rule" : "Edit Rule Data"}
          open={isModalOpen}
          // onOk={handleOk}
          onOk={() => form.submit()}
          onCancel={() => setIsModalOpen(false)}
          width={600}
        >
          <div>
            <Form 
             labelCol={{ flex: '110px' }}
             labelAlign="left"
             labelWrap
             wrapperCol={{ flex: 1 }}
             form={form} onFinish={isCreate ? handleCreateRule : handleUpdateRule}>
              <div style={{ marginBottom: "3%", marginTop: "3%" }}>
                <Form.Item
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input
                    placeholder="id"
                  />
                </Form.Item>

                <Form.Item
                  name="org"
                  label="Organization"
                  rules={[
                    { required: userPermission.role_name !== "Super Admin" ? false : true, message: "Please input your organization name!" },
                  ]}
                style={{ display: userPermission.role_name !== "Super Admin"?"none":""}}
                >
                  <Select
                    showSearch
                    placeholder="Organization Name"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    options={(orgData || []).map((v: any) => ({
                      value: v.org_id,
                      label: v.name,
                    }))}

                    defaultValue={userPermission.role_name !== "Super Admin" ? userPermission.org_id : null}
                  />


                </Form.Item>

                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please input rule name!" },
                  ]}
                >

                  <Input
                    placeholder="Rule Name"
                    autoComplete="new-name"
                  />

                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                >
                  <TextArea rows={4}
                    placeholder="Rule Description"
                    autoComplete="new-des"
                  />
                </Form.Item>

              </div>
            </Form>
          </div>
        </Modal>
      </div>

    </div>
  );
};

export default Rule;
