import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  Tag,
  Form,
  Radio,
  RadioChangeEvent,
  Switch,
  Tooltip,
} from "antd";
import moment from "moment";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { createScenario, deleteScenario, getData_Scenario } from "../../services/scenario";
import { selectedModel } from "../../services/model";
import { get_all_user, save_user_status, user_create, user_delete, user_update } from "../../services/user";
import Search from "antd/es/input/Search";
import { get_all_org, org_create, org_delete, org_update } from "../../services/org";

const { confirm } = Modal;

type Props = {};

const Org = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [orgData, setOrgData]: any = useState([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isCreate, setIsCreate] = useState<any>(true);
  const [role, setRole] = useState(1);


  const onRadioChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setRole(e.target.value);
  };


  const columns: any = [
    {
      title: <div style={{ fontSize: "19px", textAlign: "center" }}>Name</div>,
      dataIndex: "name",
      key: "name",
      //  align: "center",
      defaultSortOrder: "ascend",
      render: (_: any, record: any) => {
        return <div>
          <Col
            style={{
              fontWeight: 'bolder',
              color: 'black',
            }}
          >

            {record.name}

          </Col>
        </div>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Codename</div>,
      key: "codename",
      // align: "center",
      render: (_: any, record: any) => record.code,
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Last Update</div>,
      key: "last_update",
      // align: "center",
      render: (_: any, record: any) => {
        return <div>{moment(record.lasted_update).calendar()}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Active</div>,
      sorter: false,
      dataIndex: 'updatedAt',
      valueType: 'dateTime',
      align: 'center',
      render: (_: any, record: any) => {
        console.log(record.status);

        if (record.status == 1) {
          return (
            <Switch
              onChange={async (checked: any) => {
                console.log(`switch to ${checked}`);

                if (checked) {
                  const res = await save_user_status({
                    data: {
                      status: 1,
                      user_id: record.user_id,
                    },
                  });
                  console.log(`switch to 1`);
                } else {
                  const res = await save_user_status({
                    data: {
                      status: 2,
                      user_id: record.user_id,
                    },
                  });
                  console.log(`switch to 2`);
                }
              }}
              defaultChecked
            />
          );
        }
        return (
          <Switch
            onChange={async (checked: any) => {
              console.log(`switch to ${checked}`);
              if (checked) {
                const res = await save_user_status({
                  data: {
                    status: 1,
                    user_id: record.user_id,
                  },
                });
                console.log(`switch to 1`);
              } else {
                const res = await save_user_status({
                  data: {
                    status: 2,
                    user_id: record.user_id,
                  },
                });
                console.log(`switch to 2`);
              }
            }}
          />
        );
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Action</div>,
      dataIndex: "",
      align: "center",
      key: "tags",
      render: (_: any, record: any) => (
        <Row gutter={16} justify={"center"}>
          <Tooltip placement="bottom" title="Edit">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {
                if (record.role_id == "99999999-0000-0000-0000-999999999999") {
                  Modal.error({
                    title: 'Permission Denined!',
                    content: `Not allow to edit Super Admin's data.`,
                  });
                } else {
                  getFocusOrg(record);
                  setIsCreate(false);
                  setIsModalOpen(true);
                }

              }
              }
            >
              <EditFilled style={{ color: "#f3c62b", fontSize: "18px" }} />
            </Col>
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {
                // if (record.role_id == "99999999-0000-0000-0000-999999999999") {
                //   Modal.error({
                //     title: 'Permission Denined!',
                //     content: `Not allow to edit Super Admin's data.`,
                //   });
                // } else {
                  showDeleteConfirm(record.org_id);
                // }
              }}
            >
              <DeleteFilled style={{ color: "#d01919", fontSize: "18px" }} />
            </Col>
          </Tooltip>
        </Row>
      ),
    },
  ];

  const getFocusOrg = async (record: any) => {
    console.log("get current user :", record);

    form.setFieldsValue({
      id: record.org_id,
      name: record.name,
      codename: record.code,

    });

  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await get_all_org();
      console.log("res get org :", res.result);
      setOrgData(res.result);
    } catch (e) {
      console.error(e);
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Are you sure to delete this user?",
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        console.log("delete row:", id);

        try {
          const res = await org_delete({ data: { org_id: id } });
          getData();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCreateOrg = async (value: any) => {
    try {
      // let role_id = "";
      // console.log("user role: ", form.getFieldValue('role'));

      // if (form.getFieldValue('role') == 1) {
      //   role_id = "a8717515-be10-4e72-8d6d-da622284c2f4";
      // } else {
      //   role_id = "ecbf4355-952b-4cc2-8136-8353ca55b0d2";
      // }

      // console.log("user role id: ", role_id);

      const res = await org_create({
        data: {
          name: form.getFieldValue('name'),
          codename: form.getFieldValue('codename'),
          url: "https://manna.ai9.co.th/engine/"+form.getFieldValue('codename'),
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateOrg = async (value: any) => {
    try {
      // let role_id = "";
      // console.log("user role: ", form.getFieldValue('role'));

      // if (form.getFieldValue('role') == 1) {
      //   role_id = "a8717515-be10-4e72-8d6d-da622284c2f4";
      // } else {
      //   role_id = "ecbf4355-952b-4cc2-8136-8353ca55b0d2";
      // }

      // console.log("user role id: ", role_id);

      const res = await org_update({
        data: {
          org_id: form.getFieldValue('id'),
          name: form.getFieldValue('name'),
          codename: form.getFieldValue('codename'),
          url: "https://manna.ai9.co.th/engine/"+form.getFieldValue('codename'),
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSearch = (value: string) => console.log(value);

  return (
    <div className="content-container">
      <Row>
        <h2 style={{ fontSize: "32px" }}>Organization</h2>
      </Row>
      <div style={{ marginBottom: "2%", float: "right" }}>
        <Search placeholder="Search Organization" onSearch={onSearch} style={{ width: 300, marginRight: "50px" }} />
        <Button
          type="primary"
          style={{ borderRadius: "5px", width: "180px" }}
          onClick={() => {
            form.resetFields();
            setIsCreate(true);
            setIsModalOpen(true);
          }}
        >
          <PlusOutlined /> New Organization
        </Button>
      </div>

      <div className="table-user">
        <Table
          rowClassName="UserTable"
          rowKey="user_id"
          columns={columns}
          dataSource={orgData}
          pagination={{
            pageSize: 5,
          }}
        />


        <Modal
          title={isCreate ? "Create New Organization" : "Edit Organization Data"}
          open={isModalOpen}
          // onOk={handleOk}
          onOk={() => form.submit()}
          onCancel={() => setIsModalOpen(false)}
          width={600}
        >
          <div>
            <Form 
             labelCol={{ flex: '110px' }}
             labelAlign="left"
             labelWrap
             wrapperCol={{ flex: 1 }}
             form={form} onFinish={isCreate ? handleCreateOrg : handleUpdateOrg}>
              <div style={{ marginBottom: "3%", marginTop: "3%" }}>
                <Form.Item
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input
                    placeholder="id"
                  />
                </Form.Item>

                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please input organization name!" },
                  ]}
                >

                  <Input
                    placeholder="Organization name"
                  />

                </Form.Item>

                <Form.Item
                  name="codename"
                  label="Code Name"
                  rules={[
                    { required: true, message: "Please input organization codename!" },
                  ]}
                >
                  <Input
                    placeholder="Organization codename"
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </Modal>
      </div>

    </div>
  );
};

export default Org;
