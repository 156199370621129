import { CaretRightOutlined, PauseOutlined, SoundOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
// import RegionsPlugin from 'https://unpkg.com/wavesurfer.js@7/dist/plugins/regions.esm.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions';


const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: "#545454",
  progressColor: "OrangeRed",
  cursorColor: "OrangeRed",
  barWidth: 4,
  barRadius: 3,
  responsive: true,
  height: 25,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
});

export default function Waveform({ url, wavesurfer, lists }) {
  const waveformRef = useRef(null);
  // const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    // console.log("voice file: ", url);
    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", function () {
      // https://wavesurfer-js.org/docs/methods.html
      // wavesurfer.current.play();
      // setPlay(true);

      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);

        wavesurfer.current.on('play', function (e) {
          setPlay(true);
        })
    
        wavesurfer.current.on('pause', function (e) {
          setPlay(false);
        })
      }
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [url]);

  useEffect(() => {
    const wsRegions = wavesurfer.current.registerPlugin(RegionsPlugin.create())
    const random = (min, max) => Math.random() * (max - min) + min
    const randomColor = () => `rgba(255, 191, 0, 1)` // rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)

    wavesurfer.current.on('decode', () => {
      let setLists = lists.filter((obj) => obj['masking'] === 'true');
      setLists.forEach(data => {
        wsRegions.addRegion({
          start: data.startTime,
          end: data.endTime,
          // content: 'Resize me',
          color: randomColor(),
          drag: false,
          resize: false,
        })
      });
    });

    wsRegions.on('region-clicked', (region, e) => {
      // e.stopPropagation() // prevent triggering a click on the waveform
      region.play();
      setPlay(true);
      document.getElementById(region.start).scrollIntoView({ behavior: "smooth", inline: "start", block: "nearest" });
    });
  }, [lists]);

  const handlePlayPause = () => {
    // setPlay(!playing);
    wavesurfer.current.playPause();
    wavesurfer.current.on('play', function (e) {
      setPlay(true);
    })

    wavesurfer.current.on('pause', function (e) {
      setPlay(false);
    })
  };

  const onVolumeChange = e => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  return (
    <Row>
      <Col span={18} style={{ marginTop: "-5px" }}>
        <div className="controls">

          <div id="waveform" ref={waveformRef} style={{ alignSelf: "center" }} />

        </div>
      </Col>
      <Col span={5} style={{ textAlign: "center" }}>
        <Space>
          <label htmlFor="volume"><SoundOutlined /></label>
          <input
            type="range"
            id="volume"
            name="volume"
            // waveSurfer recognize value of `0` same as `1`
            //  so we need to set some zero-ish value for silence
            min="0.01"
            max="1"
            step=".025"
            onChange={onVolumeChange}
            defaultValue={volume}
          />
        </Space>
      </Col>
      <Col span={1}>
        <Button type="primary" className="button4" style={{ background: "#ED2B2A", fontSize: "14px", marginTop: "-20px" }} onClick={handlePlayPause}>{playing ? <PauseOutlined /> : <CaretRightOutlined />}</Button>
      </Col>
    </Row>

  );
}
