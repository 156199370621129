import config from "../config/config";
import { getToken } from "../utils/prefix"

export async function selectedModel(data) {
  const res = await fetch(config.api_backend + `/model/selectmodel`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function createModel(data) {
  const res = await fetch(config.api_backend + `/model/create`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function deleteModel(data) {
  const res = await fetch(config.api_backend + `/model/delete`, {
    body: JSON.stringify(data),
    method: "DELETE",
    headers: {
    "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function editModel(data) {
  const res = await fetch(config.api_backend + `/model/update`, {
    body: JSON.stringify(data),
    method: "PUT",
    headers: {
    "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}
// export async function test_get() {
//     const res = await fetch(config.api_backend + `/topic/gettopic`, {
//       method: "GET",
//       headers: {
//         Authorization: getToken(),
//         "Content-type": "application/json",
//       },
//     });
  
//     const dataRespone = await res.json();
//     return dataRespone;
//   }

