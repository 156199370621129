
import {
  ConfigProvider,
  Layout,
} from "antd";
import React, { useState } from "react";
import { Outlet, Route } from "react-router-dom";
import { localeState } from '../utils/atoms/index'
import { useAuth } from "../utils/Auth/AuthWrapper";
import ContentLayout from "./content";
import SiderLayout from "./sidebar";
import HeaderLayout from "./header";
import FooterLayout from "./footer";
import enUS from "antd/locale/en_US";
import thTH from "antd/locale/th_TH";
import zhCN from 'antd/locale/zh_CN';
import { useRecoilState } from "recoil";

const DefaultLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  // const [api, contextHolder] = notification.useNotification();
  const auth = useAuth();
  const [locale,setLocale] = useRecoilState(localeState)
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
// console.log("locale defualt::",locale)
  return (
    <ConfigProvider locale={locale}>
    <Layout style={{ minHeight: "100vh" }}>
      {/* {contextHolder} */}
      <HeaderLayout collapsed={collapsed}/>
      <Layout hasSider>
        <SiderLayout collapsed={collapsed} setCollapsed={setCollapsed}/>
        <Layout>
        <ContentLayout collapsed={collapsed}/>
        <FooterLayout collapsed={collapsed}/>
        </Layout>
        
      </Layout>
    </Layout>
    </ConfigProvider>
  );
};

export default DefaultLayout;
