import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { route } from "./routes/routes";
import { AuthWrapper, useAuth } from "./utils/Auth/AuthWrapper";
import ProtectedRoute from "./utils/Auth/ProtectedRoute";
import DefaultLayout from "./layout/defualtLayout";
import LoginPage from "./views/login";
import LandingPage from "./layout/LandingPage";

function App() {
  const auth = useAuth();
// console.log("auth user ::",auth)
  return (

      <Router>
        <AuthWrapper>
          <Routes>
            <Route
              path="/"
              // element={<Navigate to="/dashboard" replace={true} />}
              element={<LandingPage/>}
              // element={<DefaultLayout />}
            />
            <Route path="/404" element={<>404</>} />
            <Route path="/500" element={<>500</>} />
            <Route path="/user/login" element={<LoginPage />} />
            <Route path="/user/registration" element={<LoginPage />} />
            <Route element={<DefaultLayout />}>
              {route.map((routes, ind) => {
                // return (
                //   <Route
                //     key={ind}
                //     path={routes.path}
                //     Component={routes.component}
                //   />
                // );
          
                if (routes.routes) {
                  return(
                    <Route key={routes.name}>
              
                    {routes.routes.map((child, inds) => {
                      // console.log("route test child", child);
                      // console.log("path test child2", child.path);
                      return (
                        <Route
                          key={child.name}
                          path={routes.path+child.path}
                          element={
                            <ProtectedRoute access={routes.access}>
                             {child.component}
                            </ProtectedRoute>
                          }
                        
                        />
                      );
                    })}
                 </Route>
                  )
                }else{
                  return (
                    <Route
                      key={routes.name}
                      path={routes.path}
                      element={ 
                      <ProtectedRoute access={routes.access}>
                        {routes.component}
                      </ProtectedRoute>
                    }
                    />
                  );
                }
              })}
            </Route>
            {/* <Route element={<DefaultLayout />}>
                <Route
                  key={"dashboard"}
                  path="/dashboard"
                  element={<>dashboard</>}
                />  
                <Route
                  key={"setting"}
                  path="/setting"
                  // element={<Navigate to="/setting/profile" replace={true} />}
                >
                  <Route
                  index
                  key={"settingprofile"}
                  path="profile"
                  element={<>setting:profile</>}
                  />  
                </Route>  
            </Route> */}
            <Route path="*" element={<Navigate to="/404" replace={true} />} />
          </Routes>
        </AuthWrapper>
      </Router>

  );
}

export default App;
