import React, { useState, useRef, useEffect, useCallback } from 'react';
import Editor, { useMonaco, OnValidate } from "@monaco-editor/react";
import { Button, message, Tabs, Row, Col, Dropdown } from "antd";
import type { MenuProps } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import JSZip from 'jszip';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { deploy_rule, deploy_test_rule, get_all_rule_version, get_rule_data, get_rule_data_by_version, save_rule_data } from '../../services/rule';

const JsonEditor = () => {
    const params = useParams();
    const { TabPane } = Tabs;
    const editorRef: any = useRef(null);
    const editorRef2: any = useRef(null);
    const editorRef3: any = useRef(null);
    const [defaultJson, setDefaultJson] = useState('');
    const [defaultJson2, setDefaultJson2] = useState('');
    const [defaultJson3, setDefaultJson3] = useState('');
    const [version, setVersion] = useState<any>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [errors2, setErrors2] = useState<string[]>([]);
    const [errors3, setErrors3] = useState<string[]>([]);
    const [indexRule, setIndex] = useState('');
    const onChange = (key: string) => {
        // console.log(key);
    };

    // window 1
    const handleEditorDidMountW1 = (editor: any) => {
        editorRef.current = editor;
    }

    const handleEditorPrettifyW1 = useCallback(() => {
        editorRef.current?.getAction("editor.action.formatDocument").run();
    }, []);

    const handleClearClickW1 = () => editorRef.current?.setValue("");

    // window 2
    const handleEditorDidMountW2 = (editor: any) => {
        editorRef2.current = editor;
    }

    const handleEditorPrettifyW2 = useCallback(() => {
        editorRef2.current?.getAction("editor.action.formatDocument").run();
    }, []);

    const handleClearClickW2 = () => editorRef2.current?.setValue("");

    // window 3
    const handleEditorDidMountW3 = (editor: any) => {
        editorRef3.current = editor;
    }

    const handleEditorPrettifyW3 = useCallback(() => {
        editorRef3.current?.getAction("editor.action.formatDocument").run();
    }, []);

    const handleClearClickW3 = () => editorRef3.current?.setValue("");

    const downloadJson = () => {
        let zip = new JSZip();
        zip.file("intent.json", editorRef.current?.getValue());
        zip.file("rule.json", editorRef2.current?.getValue());
        zip.file("tag.json", editorRef3.current?.getValue());

        zip.generateAsync({ type: "base64" }).then(function (content) {
            let downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", "data:application/zip;base64," + content);
            downloadAnchorNode.setAttribute("download", "rule_json.zip");
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        });
    }

    const launchProcess = async () => {
        // console.log(version[version.length-1].key);

        const res = await save_rule_data({
            rule_id: params.project_id,
            data: {
                "intent": JSON.parse(editorRef.current?.getValue()),
                "rule": editorRef2.current?.getValue() === undefined ? defaultJson2 : JSON.parse(editorRef2.current?.getValue()),
                "tag": editorRef3.current?.getValue() === undefined ? defaultJson3 : JSON.parse(editorRef3.current?.getValue())
            },
            version: version[version.length - 1].key
        })
        // console.log(res);
        const res2 = await deploy_rule({
            "intent": JSON.parse(editorRef.current?.getValue()),
            "rule": editorRef2.current?.getValue() === undefined ? defaultJson2 : JSON.parse(editorRef2.current?.getValue()),
            "tag": editorRef3.current?.getValue() === undefined ? defaultJson3 : JSON.parse(editorRef3.current?.getValue()),
            "version": version[version.length - 1].key + ""

        })
        // console.log(res2);
        if (res2.status === "success") {
            message.success(`Deploy Rule สำเร็จ`);
            await getRuleVersion();
        } else {
            message.error(`Deploy Rule ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
        }
    }

    const saveProcess = async () => {
        const res = await save_rule_data({
            rule_id: params.project_id,
            data: {
                "intent": JSON.parse(editorRef.current?.getValue()),
                "rule": editorRef2.current?.getValue() === undefined ? defaultJson2 : JSON.parse(editorRef2.current?.getValue()),
                "tag": editorRef3.current?.getValue() === undefined ? defaultJson3 : JSON.parse(editorRef3.current?.getValue())
            }
        })
        const res2 = await deploy_test_rule({
            "intent": JSON.parse(editorRef.current?.getValue()),
            "rule": editorRef2.current?.getValue() === undefined ? defaultJson2 : JSON.parse(editorRef2.current?.getValue()),
            "tag": editorRef3.current?.getValue() === undefined ? defaultJson3 : JSON.parse(editorRef3.current?.getValue()),
            "version": version[version.length - 1].key + ""
        })
        console.log(res2);

        if (res.status === 200) {
            message.success(`บันทึก Rule สำเร็จ`);
            setIndex(version[version.length - 1].key + 1);
            await getRuleVersion();  
        } else {
            message.error(`บันทึก Rule ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
        }
    }


    const getRule = async () => {
        const res = await get_rule_data({
            rule_id: params.project_id
        })
        // console.log("rule: ", res.data);

        if (res.data != null) {
            setDefaultJson(res.data.intent);
            setDefaultJson2(res.data.rule);
            setDefaultJson3(res.data.tag);
            setIndex(res.version);
        }
    }

    const getRuleVersion = async () => {
        const res = await get_all_rule_version({
            rule_id: params.project_id
        })
        // console.log("version: ", res.data)

        let v = null
        let i = 0

        res.data.map((ver: any) => {
            // console.log(ver.version);
            i++
            if (i == 1) {
                v = [{
                    key: 1,
                    label: "Version 1",
                    value: 1
                }]
            } else {
                v.push(
                    {
                        key: i,
                        label: "Version " + ver.version,
                        value: ver.version
                    },
                )
            }
        }
        );
        if (v != null) {
            // console.log("version list: ", v)
            setVersion(v)
        }
    }

    useEffect(() => {
        getRule();
        getRuleVersion();
    }, [])

    const handleEditorValidation: OnValidate = useCallback((markers: any) => {
        const errorMessage = markers.map(
            ({ startLineNumber, message }: any) => `line ${startLineNumber}: ${message}`
        );
        setErrors(errorMessage);
    }, []);

    const handleEditorValidation2: OnValidate = useCallback((markers: any) => {
        const errorMessage = markers.map(
            ({ startLineNumber, message }: any) => `line ${startLineNumber}: ${message}`
        );
        setErrors2(errorMessage);
    }, []);

    const handleEditorValidation3: OnValidate = useCallback((markers: any) => {
        const errorMessage = markers.map(
            ({ startLineNumber, message }: any) => `line ${startLineNumber}: ${message}`
        );
        setErrors3(errorMessage);
    }, []);

    const selectVersion = async (index: any) => {
        setIndex(index.key);
        const res = await get_rule_data_by_version({
            rule_id: params.project_id,
            version: index.key
        })

        // console.log('version data', res.data);
        if (res.data != null) {
            setDefaultJson(res.data.intent);
            setDefaultJson2(res.data.rule);
            setDefaultJson3(res.data.tag);
        }
    }


    return (
        <>
            <Row style={{ padding: '30px' }}>
                <Col xs={24} xl={24}>
                    <Tabs defaultActiveKey="1" onChange={onChange}>
                        <TabPane tab="Intent" key="1">
                            <Col xs={24} xl={24} style={{ paddingBottom: '10px', justifyContent: 'end', display: 'flex', gap: '8px' }}>
                                <Button danger icon={<ClearOutlined />} onClick={handleClearClickW1}>Clear</Button>
                                <Button type="primary" onClick={handleEditorPrettifyW1}>Prettify</Button>
                            </Col>
                            <Col xs={24} xl={24}>
                                <Editor
                                    value={JSON.stringify(defaultJson, null, 2)}
                                    height="50vh"
                                    defaultLanguage="json"
                                    defaultValue="// start here"
                                    onMount={handleEditorDidMountW1}
                                    options={{
                                        automaticLayout: true,
                                    }}
                                    onValidate={handleEditorValidation}
                                />
                                {errors.length > 0 ?
                                    <div style={{ marginTop: '10px', backgroundColor: 'white', width: '100%', height: '150px', overflow: 'scroll' }}>
                                        {errors.map((errors) => (
                                            <div style={{ padding: '5px' }}>{errors}</div>
                                        ))}
                                    </div>
                                    : null}
                            </Col>
                        </TabPane>
                        <TabPane tab="Rule" key="2">
                            <Col xs={24} xl={24} style={{ paddingBottom: '10px', justifyContent: 'end', display: 'flex', gap: '8px' }}>
                                <Button danger icon={<ClearOutlined />} onClick={handleClearClickW2}>Clear</Button>
                                <Button type="primary" onClick={handleEditorPrettifyW2}>Prettify</Button>
                            </Col>
                            <Col xs={24} xl={24}>
                                <Editor
                                    value={JSON.stringify(defaultJson2, null, 2)}
                                    height="50vh"
                                    defaultLanguage="json"
                                    defaultValue="// start here"
                                    onMount={handleEditorDidMountW2}
                                    options={{
                                        automaticLayout: true,
                                    }}
                                    onValidate={handleEditorValidation2}
                                />
                                {errors2.length > 0 ?
                                    <div style={{ marginTop: '10px', backgroundColor: 'white', width: '100%', height: '150px', overflow: 'scroll' }}>
                                        {errors2.map((errors) => (
                                            <div style={{ padding: '5px' }}>{errors}</div>
                                        ))}
                                    </div>
                                    : null}
                            </Col>
                        </TabPane>
                        <TabPane tab="Tag" key="3">
                            <Col xs={24} xl={24} style={{ paddingBottom: '10px', justifyContent: 'end', display: 'flex', gap: '8px' }}>
                                <Button danger icon={<ClearOutlined />} onClick={handleClearClickW3}>Clear</Button>
                                <Button type="primary" onClick={handleEditorPrettifyW3}>Prettify</Button>
                            </Col>
                            <Col xs={24} xl={24}>
                                <Editor
                                    value={JSON.stringify(defaultJson3, null, 2)}
                                    height="50vh"
                                    defaultLanguage="json"
                                    defaultValue="// start here"
                                    onMount={handleEditorDidMountW3}
                                    options={{
                                        automaticLayout: true,
                                    }}
                                    onValidate={handleEditorValidation3}
                                />
                                {errors3.length > 0 ?
                                    <div style={{ marginTop: '10px', backgroundColor: 'white', width: '100%', height: '150px', overflow: 'scroll' }}>
                                        {errors3.map((errors) => (
                                            <div style={{ padding: '5px' }}>{errors}</div>
                                        ))}
                                    </div>
                                    : null}
                            </Col>
                        </TabPane>
                    </Tabs>
                    <Col xs={24} xl={24} style={{ paddingTop: '10px', justifyContent: 'end', display: 'flex', gap: '8px' }}>
                        <Button type="primary" onClick={downloadJson}>Download Json</Button>
                        <Dropdown.Button type="primary" menu={{
                            onClick: selectVersion,
                            items: version,
                        }}
                            style={{ width: 'fit-content' }}
                        >
                            Rule version {indexRule}
                        </Dropdown.Button>
                        <Button type="primary" onClick={launchProcess} disabled={errors.length === 0 && errors2.length === 0 && errors3.length === 0 ? false : true} > Launch production engine</Button>
                        <Button type="primary" onClick={saveProcess} disabled={errors.length === 0 && errors2.length === 0 && errors3.length === 0 ? false : true} >Save</Button>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default JsonEditor;
