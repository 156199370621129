import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Card,
  Col,
  Image,
  Pagination,
  Row,
  Space,
  Tag,
  DatePicker,
  Empty,
} from "antd";
import { api_getAllVideo } from "../../../services/video";
import NoImage from "../../../assets/images/logoMemo.jpg";

type Props = {};

const MyVideoPage = (props: Props) => {
  const [mockData, setMockData] = useState([]);
  const [total, setTotal] = useState(0);
  const getData = async () => {
    try {
      const data = {
        type: "s",
      };
      const res = await api_getAllVideo(data);
      if (res.status == 200) {
        console.log("res video:", res);
        setMockData(res.data);
        setTotal(res.meta.total);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="content-container">
      <Row>
        <h2>My Video</h2>
      </Row>
      <Row>
        <h1 style={{ opacity: 0.8 }}> video</h1>
      </Row>
      <Row>
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          {mockData.length == 0 ? (
            <Col xs={24}>
              <Row justify="center" align="middle" style={{ height: "40vh" }}>
                <Empty />
              </Row>
            </Col>
          ) : (
            mockData.map((d: any) => {
              return (
                <Col
                  span={8}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                >
                  <Card
                    cover={
                      // <div>
                      <Image
                        preview={false}
                        style={{
                          //   width: "100%",
                          minHeight: "170px",
                          height: "170px",
                          objectFit: "cover",
                          objectPosition: "0px 1px",
                        }}
                        alt="example"
                        // height={350}
                        // src={d.video_screenshot}
                        src={
                          d.video_screenshot != ""
                            ? `${process.env.REACT_APP_API + d.image_path}`
                            : NoImage
                        }
                      />
                      // </div>
                    }
                    bordered={false}
                  >
                    <Row gutter={[8, 8]}>
                      <Col xs={24}>Title</Col>
                      <Col xs={24}>
                        <Space size={[0, 8]} wrap>
                          <Tag color="magenta">magenta</Tag>
                          <Tag color="red">red</Tag>
                          <Tag color="volcano">volcano</Tag>
                        </Space>
                      </Col>
                      <Col xs={24}>By mock creater</Col>
                      <Col xs={24}>
                        {new Date(d.duration * 1000)
                          .toISOString()
                          .slice(11, 19)}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })
          )}
        </Row>
      </Row>
      {total > 12 && (
        <Row align="bottom" justify="end" style={{ marginTop: "10px" }}>
          <Pagination
            total={total}
            showSizeChanger={false}
            // showQuickJumper
            showTotal={(total) => `Total ${total} items`}
          />
        </Row>
      )}
    </div>
  );
};

export default MyVideoPage;
