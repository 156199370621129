import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  Tag,
  Form,
  Radio,
  RadioChangeEvent,
  Switch,
  Tooltip,
  Select,
} from "antd";
import moment from "moment";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  PlusOutlined,
  SettingFilled,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get_all_user, get_user, save_user_status, user_create, user_delete, user_update } from "../../services/user";
import Search from "antd/es/input/Search";
import { get_all_org } from "../../services/org";
import TextArea from "antd/es/input/TextArea";

const { confirm } = Modal;

type Props = {};

const Profile = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [userData, setUserData]: any = useState([]);
  const [orgData, setOrgData]: any = useState([]);

  const getFocusProfile = async (record: any) => {
    console.log("get current Profile :", record);

    form.setFieldsValue({
      id: record.Profile_id,
      org: record.org_id,
      name: record.name,
      description: record.description
    });

  }

  useEffect(() => {
    getOrgData();
    getData();
  }, []);

  const getOrgData = async () => {
    try {
      const res = await get_all_org();
      console.log("res get org :", res.result);
      setOrgData(res.result);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    try {
      const res = await get_all_user();
      console.log("res get Profile :", res.result);
      setUserData(res.result);
    } catch (e) {
      console.error(e);
    }
  };

 
  const handleUpdateUser = async (value: any) => {
    try {

      const res = await user_update({
        data: {
          Profile_id: form.getFieldValue('id'),
          name: form.getFieldValue('name'),
          description: form.getFieldValue('description'),
          org_id: form.getFieldValue('org'),
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        navigate('"/user"')
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSearch = (value: string) => console.log(value);

  return (
    <div className="content-container">
      <Row>
        <h2 style={{ fontSize: "32px" }}>Profile</h2>
      </Row>
    
      <div className="table-user">  
      
          <div>
            <Form 
             labelCol={{ flex: '110px' }}
             labelAlign="left"
             labelWrap
             wrapperCol={{ flex: 1 }}
             form={form} onFinish={handleUpdateUser}>
              <div style={{ marginBottom: "3%", marginTop: "3%" }}>
                <Form.Item
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input
                    placeholder="id"
                  />
                </Form.Item>

                <Form.Item
                  name="org"
                  label="Organization"
                  // style={{ display: "none" }}
                >
                  <Select
                    showSearch
                    placeholder="Organization Name"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    options={(orgData || []).map((v: any) => ({
                      value: v.org_id,
                      label: v.name,
                    }))}
                  />


                </Form.Item>

                <Form.Item
                  name="name"
                  label="Name"
                >

                  <Input
                    placeholder="Profile Name"
                    autoComplete="new-name"
                  />

                </Form.Item>

                <Form.Item
                  name="description"
                  label="Description"
                >
                  <TextArea rows={4}
                    placeholder="Profile Description"
                    autoComplete="new-des"
                  />
                </Form.Item>

              </div>
            </Form>
          </div>
        
      </div>

    </div>
  );
};

export default Profile;
