import config from "../config/config";
import { getProjectToken, getToken } from "../utils/prefix";

export async function project_create(data) {
  const res = await fetch(config.api_backend + `/project/create`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function project_update(data) {
  const res = await fetch(config.api_backend + `/project/update`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function project_delete(data) {
  const res = await fetch(config.api_backend + `/project/delete`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_project(data) {
  const res = await fetch(config.api_backend + `/project/getProjectById`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_project_rule(data) {
  const res = await fetch(config.api_backend + `/project/getProjectRule`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function save_project_rule(data) {
  const res = await fetch(config.api_backend + `/project/saveProjectRule`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function delete_project_rule(data) {
  const res = await fetch(config.api_backend + `/project/deleteProjectRule`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_all_project(data) {
  const res = await fetch(config.api_backend + `/project/all`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}


export async function save_project_status(data) {
  const res = await fetch(config.api_backend + `/project/saveProjectStatus`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function update_project_rule(data) {
  const res = await fetch(config.api_backend + `/project/updateProjectRule`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}


//-----------------------------------------------//


export async function gen_project_token(data) {
  const res = await fetch(config.api_backend + `/project/genProjectJWT`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getToken(),
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function deploy_project(data) {
  const res = await fetch(`https://dev.ai9.co.th/sita/dialog/${data.org_code}/api/v1/project/deploy`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getProjectToken(),
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

