import config from "../config/config";
// import { getToken } from "../utils/prefix";

export async function tag_create(data) {
  const res = await fetch(config.api_backend + `/tag/create`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function tag_update(data) {
  const res = await fetch(config.api_backend + `/tag/update`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function tag_delete(data) {
  const res = await fetch(config.api_backend + `/tag/delete`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function terms_delete(data) {
  const res = await fetch(config.api_backend + `/tag/deleteTerms`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_tag(data) {
  const res = await fetch(config.api_backend + `/tag/getTagById`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_terms(data) {
  const res = await fetch(config.api_backend + `/tag/getTerms`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_all_tag(data) {
  const res = await fetch(config.api_backend + `/tag/all`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}


export async function save_tag_status(data) {
  const res = await fetch(config.api_backend + `/tag/saveTagStatus`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

