import PropTypes from 'prop-types';
import { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';



function StackedChart({ data, view }) {

    ///// DATA
    let chartData = [];
    let lineSeriesData = [];
    console.log("data g", data);
    if (data.length <= 0) {
        data =
        {
            "No Data": {
                "pass": 0,
                "fail": 0,
                // "na": 0
            }
        }
    } 


    useEffect(() => {
        var chart = am4core.create("stackChartDiv", am4charts.XYChart);
        console.log("data", data);
        chart.data = data

        // some extra padding for range labels
        chart.paddingBottom = 50;

        chart.cursor = new am4charts.XYCursor();
        // chart.scrollbarX = new am4core.Scrollbar();

        // will use this to store colors of the same items
        let colors = {};

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataItems.template.text = "";
        categoryAxis.adapter.add("tooltipText", function (tooltipText, target) {
            return categoryAxis.tooltipDataItem.dataContext.realName;
        })

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.min = 0;

        // single column series for all data
        let columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.columns.template.width = am4core.percent(80);
        columnSeries.tooltipText = "{provider}: {realName}, {valueY}";
        columnSeries.dataFields.categoryX = "category";
        columnSeries.dataFields.valueY = "value";

        // second value axis for quantity
        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.renderer.opposite = true;
        valueAxis2.syncWithAxis = valueAxis;
        valueAxis2.tooltip.disabled = true;

       
      
        // fill adapter, here we save color value to colors object so that each time the item has the same name, the same color is used
        columnSeries.columns.template.adapter.add("fill", function (fill, target) {
            let name = target.dataItem.dataContext.realName;
            if (name == "pass") {
                colors[name] = "#508D69";
            }
            if (name == "fail") {
                colors[name] = "#EF4040";
            }
            if (name == "na") {
                colors[name] = "orange";
            }

            target.stroke = colors[name];
            return colors[name];
        })


        let rangeTemplate = categoryAxis.axisRanges.template;
        rangeTemplate.tick.disabled = false;
        rangeTemplate.tick.location = 0;
        rangeTemplate.tick.strokeOpacity = 0.6;
        rangeTemplate.tick.length = 60;
        rangeTemplate.grid.strokeOpacity = 0.5;
        rangeTemplate.label.tooltip = new am4core.Tooltip();
        rangeTemplate.label.tooltip.dy = -10;
        rangeTemplate.label.cloneTooltip = false;



        // process data ant prepare it for the chart
        for (var providerName in data) {

            let providerData = data[providerName];

            // add data of one provider to temp array
            let tempArray = [];
            let count = 0;
            // add items
           
            for (var itemName in providerData) {
                // console.log("providerData",providerData.rule);
                count++;
                // we generate unique category for each column (providerName + "_" + itemName) and store realName
                if(itemName != "rule"){
                    tempArray.push({ category: providerName + "_" + itemName, realName: itemName, value: providerData[itemName], provider: providerData.rule })
                }
                

            }

            // push to the final data
            am4core.array.each(tempArray, function (item) {
                chartData.push(item);
            })

            // create range (the additional label at the bottom)
            let range = categoryAxis.axisRanges.create();
            range.category = tempArray[0].category;
            range.endCategory = tempArray[tempArray.length - 1].category;
            range.label.text = tempArray[0].provider;
            range.label.dy = 30;
            range.label.truncate = true;
            range.label.fontWeight = "bold";
            range.label.tooltipText = tempArray[0].provider;

            range.label.adapter.add("maxWidth", function (maxWidth, target) {
                let range = target.dataItem;
                let startPosition = categoryAxis.categoryToPosition(range.category, 0);
                let endPosition = categoryAxis.categoryToPosition(range.endCategory, 1);
                let startX = categoryAxis.positionToCoordinate(startPosition);
                let endX = categoryAxis.positionToCoordinate(endPosition);
                return endX - startX;
            })
        }

        chart.data = chartData;

        // last tick
        let range = categoryAxis.axisRanges.create();
        range.category = chart.data[chart.data.length - 1].category;
        range.label.disabled = true;
        range.tick.location = 1;
        range.grid.location = 1;
    }, [data, view])

    return (
        <div id='stackChartDiv' style={{ height: '400px' }}></div>
    )
}
StackedChart.propTypes = {
    data: PropTypes.any.isRequired,
    view: PropTypes.string
};
export default StackedChart