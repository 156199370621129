// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[9].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[9].use[2]!../../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[9].use[3]!../../../../node_modules/less-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[9].use[4]!../../../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".box-shadow {\n  box-shadow: 2px 2px 15px -8px #bdbdbd;\n}\n.content-container {\n  padding: 20px 4.5rem 0px 4.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/assets/theme/less/variable.less","webpack://./src/assets/theme/less/index.less","webpack://./src/assets/theme/less/main.less"],"names":[],"mappings":"AAYA;EACE,qCAAA;ACVF;ACHA;EACI,+BAAA;ADKJ","sourcesContent":["@import \"~antd/dist/reset.css\";\n// @import \"../theme/less/index.less\";\n// @import \"../theme/fonts/stylesheet.css\";\n// @screen-xs-min\n// @screen-sm-min\n// @screen-md-min\n// @screen-lg-min\n\n// html {\n//   font-size: @font-size-base;\n// }\n\n.box-shadow {\n  box-shadow: 2px 2px 15px -8px rgb(189, 189, 189);\n}\n","@import \"~antd/dist/reset.css\";\n.box-shadow {\n  box-shadow: 2px 2px 15px -8px #bdbdbd;\n}\n.content-container {\n  padding: 20px 4.5rem 0px 4.5rem;\n}\n",".content-container{\n    padding: 20px 4.5rem 0px 4.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
