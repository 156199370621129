import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { subtitle_APIDownload } from "../../../services/subtitle";

type Props = {
  timeVideo: any;
  handleWatchComplete: () => any;
  subpath: any;
};

const VideoDisplay = ({ timeVideo, handleWatchComplete, subpath }: Props) => {
  const params = useParams();

  return (
    <Col md={24} lg={12}>
      <Row>
        <video
          id="video"
          preload="metadata"
          width="100%"
          // height="auto"
          controls
          onTimeUpdate={handleWatchComplete}
        >
          <source
            src={`${process.env.REACT_APP_API}task/download?task_id=${params.id}`}
            type="video/mp4"
          />
          <track label="English" kind="subtitles" src={subpath} default />
        </video>
      </Row>
      <Row justify="space-between" style={{ marginTop: "1rem" }}>
        <Col>
          <Button onClick={() => ""} type="primary">
            Delete
          </Button>
        </Col>
        <Col>
          <div
            style={{
              padding: "8px 8px",
              background: "#D9D9D9",
              borderRadius: "4px",
            }}
          >
            {timeVideo}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default VideoDisplay;
