import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { subtitle_APIGetSub } from "../../../../services/subtitle";
import { useParams } from "react-router-dom";
import "../style/index.less";

type Props = {
  indexSelect: any;
  selectSub: any;
  allSub: any;
};

const Subtitle = ({ indexSelect, selectSub, allSub }: Props) => {
  // const [data, setData] = useState([]);
  // const params = useParams();
  // let index = "aeef0bf2-b170-4f01-86d1-85efd11c4f94";

  // const getData = async () => {
  //   try {
  //     const res = await subtitle_APIGetSub({ task_id: params.id });
  //     console.log("res", res);
  //     setData(res.result);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <>
      {allSub.map((d: any, ind: number) => {
        return (
          <Row
            style={{ marginTop: "15px" }}
            key={d.sub_id}
            id={`sub-box${d.sub_id}`}
          >
            <Col>
              {d.start_time} - {d.end_time}
            </Col>
            <Col
              span={24}
              className={
                indexSelect != d.sub_id
                  ? "card-subtitle"
                  : "card-subtitle-select"
              }
              onClick={() => selectSub(d.start_time)}
            >
              <Row
                align="middle"
                style={{ height: "100%", paddingLeft: "1rem" }}
              >
                {d.text}
              </Row>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default Subtitle;
