import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Card, Typography, Space, Row, Col, message } from 'antd'
import { UserOutlined, VideoCameraOutlined, ClockCircleOutlined, SecurityScanOutlined, TeamOutlined } from '@ant-design/icons'
import './StatusCard.less'
import { get_graph } from '../../services/task';
function StatusCard({ sidePrefix, status, date, mode, pieData }) {
    const { Title } = Typography;
    const [total, setTotal] = useState(0);
    // render pie chart
    useEffect(() => {
        getPieData();
    }, [])

    useEffect(() => {
        getPieData();
    }, [status, date, mode,pieData])

    const getPieData = async () => {
        console.log("pie in chart",pieData);
        try {
            // const res = await get_graph({
            //     "start_date":  '2024-01-01 00:00:00',
            //     "end_date": '2024-12-31 23:59:59',
            //     "status": status
            // });
            // if (res.status === 200) {
                var chart = am4core.create(`${sidePrefix}`, am4charts.PieChart);
                chart.data = pieData.result;
                setTotal(pieData.total);
                var series = chart.series.push(new am4charts.PieSeries());
                series.dataFields.value = "value";
                series.dataFields.category = "category";
                series.labels.template.text = "{category}: {value.percent.formatNumber('#.##')}%";
                // series.labels._template.disabled = true;
                // series.ticks.template.disabled = true;
                series.slices.template.tooltipText = "{category}: {value}"
                series.colors.list = [
                    am4core.color("#508D69"),
                    am4core.color("#E5E4E2")
                ];

                //add legend
                chart.legend = new am4charts.Legend();
                chart.legend.labels.template.tooltipText = "{category}: {value}"
                chart.legend.position = 'right'
                chart.legend.valueLabels.template.text = "";
                // chart.legend.useDefaultMarker = true;
                // var marker = chart.legend.markers.template.children.getIndex(0);
                // marker.height = 20
                // chart.legend.maxHeight = 24

                am4core.options.autoDispose = true;
            // } else {
            //     message.error('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
            // }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Card
            style={{
                width: 'auto',
            }}
            className='status-card'
        >
            <Space direction='vertical' style={{ width: '100%', height: '100%' }}>
                <Space direction='horizontal' style={{ width: '100%' }}>
                    <Title level={3}><span>{sidePrefix + ' ' + total}</span></Title>
                </Space>
                <Row>
                    <Col span={24}>
                        <div id={sidePrefix} style={{ width: '100%', height: '192px',marginLeft:"15px",marginTop:"-10px" }}></div>
                    </Col>
                </Row>
            </Space>
        </Card>
    )
}

StatusCard.propTypes = {
    sidePrefix: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string,
    mode: PropTypes.string,
};

export default StatusCard