import { Button, Card, Col, Form, Input, List, Modal, Row, Tag } from "antd";
import "./styles/index.less";
import React, { useEffect, useState } from "react";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  createModel,
  deleteModel,
  editModel,
  selectedModel,
} from "../../../services/model";
import moment from "moment";

const { confirm } = Modal;

type Props = {};

const Model = (props: Props) => {
  const [form] = Form.useForm();
  const [topic, setTopic] = useState<any>([]);
  const [object, setObject] = useState<any>([]);
  const [scene, setScene] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [modalDetail, setModalDetail] = useState<any>({
    status: "create",
    topic: "text",
    id: null,
  });
  const [modelType, setModelType] = useState<any>({
    text: "b875162b-0492-11ee-a8f8-0242ac1b0005",
    obj: "b875130b-0492-11ee-a8f8-0242ac1b0005",
    scene: "b8750eec-0492-11ee-a8f8-0242ac1b0005",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const resultText: any = await selectedModel({ model: "text" });
    sortData(resultText);

    const resultObject: any = await selectedModel({ model: "object" });
    sortData(resultObject);

    const resultScene: any = await selectedModel({ model: "scene" });
    sortData(resultScene);

    setTopic(resultText);
    setObject(resultObject);
    setScene(resultScene);
  };

  const sortData = async (data: any) => {
    return data.sort(
      (a: any, b: any) =>
        moment(a.created_timestamp).unix() - moment(b.created_timestamp).unix()
    );
  };

  const handleOpenModel = async () => {
    setIsModalOpen(true);
  };

  const handleCreateForm = async (values: any) => {
    try {
      console.log("values create::", values, modalDetail);
      const result = {
        data: values.name,
        model_type:
          modalDetail.topic == "text"
            ? modelType.text
            : modalDetail.topic == "object"
            ? modelType.obj
            : modelType.scene,
        model_name: values.model,
      };
      const res = await createModel(result);
      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleEditForm = async (values: any) => {
    try {
      console.log("values edit::", values, modalDetail);
      const res = await editModel({
        id: modalDetail.id,
        text: values.name,
        model:
          modalDetail.topic == "text"
            ? modelType.text
            : modalDetail.topic == "object"
            ? modelType.obj
            : modelType.scene,
        model_name: values.model,
      });
      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteModel = async (values: any) => {
    confirm({
      title: "Are you sure delete this topic?",
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const res = await deleteModel({ id: values });
          if (res.message == "Success") {
            getData();
          }
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className="content-container">
      <Row>
        <h2>Model</h2>
      </Row>
      <Row gutter={[16, 16]} justify="space-around" className="card-model">
        <Col xs={8} sm={8} md={7}>
          <Card
            title="Text"
            bordered={false}
            className="card-container"
            actions={[
              <Button
                type="dashed"
                size="middle"
                style={{
                  borderColor: "#E04D8B",
                  color: "#E04D8B",
                  width: "95%",
                  fontSize: "13px",
                }}
                onClick={() => {
                  setModalDetail({
                    status: "create",
                    topic: "text",
                    id: null,
                  });
                  handleOpenModel();
                }}
              >
                <PlusOutlined /> Add topic
              </Button>,
            ]}
          >
            <div id="scrollableDiv" className="scrollModel">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={topic}
                style={{ height: "230px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    <div className="card-text">
                      <div className="left-model">
                        <Tag className="modelTag" color="magenta">
                          {item.text}
                        </Tag>
                      </div>

                      <div className="right-model">
                        <Row gutter={16}>
                          <Col
                            className="model-action"
                            onClick={() => {
                              form.setFieldsValue({
                                name: item.text,
                                model: item.model_name,
                              });
                              console.log("name", item);
                              setModalDetail({
                                status: "edit",
                                topic: "text",
                                id: item.model_id,
                              });
                              handleOpenModel();
                            }}
                          >
                            <EditFilled />
                          </Col>
                          <Col
                            className="model-action"
                            onClick={() => {
                              handleDeleteModel(item.model_id);
                            }}
                          >
                            <DeleteFilled />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
        <Col xs={8} sm={8} md={7}>
          <Card
            title="Object"
            bordered={false}
            className="card-container"
            actions={[
              <Button
                type="dashed"
                size="middle"
                style={{
                  borderColor: "#218AE7",
                  color: "#218AE7",
                  width: "95%",
                  fontSize: "13px",
                }}
                onClick={() => {
                  setModalDetail({
                    status: "create",
                    topic: "object",
                    id: null,
                  });
                  handleOpenModel();
                }}
              >
                <PlusOutlined /> Add object
              </Button>,
            ]}
          >
            <div id="scrollableDiv" className="scrollModel">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={object}
                style={{ maxHeight: "230px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    <div className="card-text">
                      <div className="left-model">
                        <Tag color="blue" className="modelTag">
                          {item.text}
                        </Tag>
                      </div>
                      <div className="right-model">
                        <Row gutter={16}>
                          <Col
                            className="model-action"
                            onClick={() => {
                              form.setFieldsValue({
                                name: item.text,
                                model: item.model_name,
                              });
                              setModalDetail({
                                status: "edit",
                                topic: "object",
                                id: item.model_id,
                              });
                              handleOpenModel();
                            }}
                          >
                            <EditFilled />
                          </Col>
                          <Col
                            className="model-action"
                            onClick={() => {
                              handleDeleteModel(item.model_id);
                            }}
                          >
                            <DeleteFilled />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
        <Col xs={8} sm={8} md={7}>
          <Card
            title="Scene"
            bordered={false}
            className="card-container"
            actions={[
              <Button
                type="dashed"
                size="middle"
                style={{
                  borderColor: "#2EA66C",
                  color: "#2EA66C",
                  width: "95%",
                  fontSize: "13px",
                }}
                onClick={() => {
                  setModalDetail({
                    status: "create",
                    topic: "scene",
                    id: null,
                  });
                  handleOpenModel();
                }}
              >
                <PlusOutlined /> Add scene
              </Button>,
            ]}
          >
            <div id="scrollableDiv" className="scrollModel">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={scene}
                style={{ height: "230px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    <div className="card-text">
                      <div className="left-model">
                        <Tag color="green" className="modelTag">
                          {item.text}
                        </Tag>
                      </div>
                      <div className="right-model">
                        <Row gutter={16}>
                          <Col
                            className="model-action"
                            onClick={() => {
                              form.setFieldsValue({
                                name: item.text,
                                model: item.model_name,
                              });
                              setModalDetail({
                                status: "edit",
                                topic: "scene",
                                id: item.model_id,
                              });
                              handleOpenModel();
                            }}
                          >
                            <EditFilled />
                          </Col>
                          <Col
                            className="model-action"
                            onClick={() => {
                              handleDeleteModel(item.model_id);
                            }}
                          >
                            <DeleteFilled />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/* modal */}
      <Modal
        title={
          modalDetail.topic == "text"
            ? "Text"
            : modalDetail.topic == "object"
            ? "Object"
            : "Scene"
        }
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          setModalDetail({
            status: "create",
            topic: "text",
            id: null,
          });
          setIsModalOpen(false);
        }}
        closable={false}
        maskClosable={false}
        width={420}
        // centered
      >
        <Form
          form={form}
          onFinish={(values) => {
            modalDetail.status == "create"
              ? handleCreateForm(values)
              : handleEditForm(values);
          }}
        >
          <Row justify="center">
            <Col xs={20}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input
                  placeholder={
                    modalDetail.topic == "text"
                      ? "Topic"
                      : modalDetail.topic == "object"
                      ? "Object"
                      : modalDetail.topic == "scene"
                      ? "Scene"
                      : ""
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={20}>
              <Form.Item
                name="model"
                label="Model"
                rules={[
                  { required: true, message: "Please input your model!" },
                ]}
              >
                <Input placeholder="Model" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Model;
