import { useState } from "react";
import Tile from "./Tile";
import { ReactSortable } from "react-sortablejs";
import { Card, Col, Row } from "antd";
import Search from "antd/es/input/Search";

interface IBoardProps {
    title: string;
    lists: [];
    setLists: any;
    color: any;
    height: string;
    search:boolean;
    text:string;
    info:string;
}

interface ICard {
    id: number;
    text: string;
}



const Board: React.FC<IBoardProps> = ({ title, lists, setLists, color, height, search, text, info }) => {
    const onSearch = (value: string) => console.log(value);
    return (
        <Card title={
            <Row gutter={16}>
                <Col span={12}>
                    <h1 style={{fontSize:"24px",marginTop:"10px",color:"#9B9B9B"}}>{title}</h1>
                </Col>
                <Col span={12}>
                    <Search placeholder="Search Rule" onSearch={onSearch} style={{ width: 300, marginTop:"15px", marginRight: "50px",display:search?"":"none" }} />
                </Col>
            </Row>
        }>


            <article id="list" className="mx-2" style={{ height: height, overflowY: "auto", overflowX: "hidden"}}>

                <ReactSortable
                    group="shared"
                    animation={200}
                    delay={1}
                    swap
                    multiDrag
                    setList={setLists}
                    list={lists}
                    style={{ height: "400px" }}
                >
                    {lists.map((card: any) => (
                        <Tile key={card.rule_id} meta={card.description} color={color} text={text} info={info} >{card.name}</Tile>
                    ))}
                </ReactSortable>
            </article>

        </Card>



    );
};

export default Board;
