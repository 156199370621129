import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import decode from 'jwt-decode'
// import moment from "moment-timezone";
import moment from "moment";

const AuthContext = createContext();

export const AuthWrapper = ({ children }) => {
  const navigate = useNavigate();
  var user = JSON.parse(localStorage.getItem("stt"));
  if(user != null){
  const decodeToken =  decode(user.accessToken)
  const timeToken =  moment.unix(decodeToken.exp).format('MM DD YYYY, h:mm:ss a')
  const date = moment( new Date().valueOf()).format('MM DD YYYY, h:mm:ss a')
  if(Date.parse(date) > Date.parse(timeToken)){
    localStorage.clear();
    navigate("/user/login");
  }else{
    user = JSON.parse(localStorage.getItem("stt"));
  }
  }

  const login = async (data) => {
    //รับค่าจากbackendมาเพื่อเก็บไว้ในlocal
    // const result = {
    //   accessToken: data.token,
    //   ...data.user_profile,
    // };
    // localStorage.setItem("stt", JSON.stringify(result));
    // navigate("/task");
    // if (data.user_profile.role == "User") {
    //   navigate("/task");
    // } else {
    //   navigate("/");
    // }

    //test dev
     const result = {
      accessToken: data.token,
      ...data,
    };
    localStorage.setItem("stt", JSON.stringify(result));
    // console.log("test role::",data)
    
    if(data.role == "Super Admin"){
      navigate("/organization");
    }else if(data.role == "Administrator"){
      navigate("/project");
    }else {
      navigate("/project");
    }
    
  };

  const logout = () => {
    localStorage.clear();
    navigate("/user/login");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
