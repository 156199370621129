import React, { useEffect, useRef, useState } from "react";
import "./style/index.less";
import { Breadcrumb, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api_getVideoID } from "../../../services/video";
import VideoDisplay from "./VideoDisplay";
import DetailDisplay from "./DetailDisplay";
import ModalScene from "./component/ModalScene";
import {
  subtitle_APIDownload,
  subtitle_APIGetSub,
} from "../../../services/subtitle";
import moment from "moment";
import { LeftCircleFilled } from "@ant-design/icons";

type Props = {};

const VideoPage = (props: Props) => {
  const [valueRadio, setValueRadio] = useState("scenario");
  const [valueChoice, setValueChioce] = useState("1");
  const [dataVideo, setDataVideo] = useState<any>([]);
  const [timeVideo, setTimeVideo] = useState("");
  const [dataScenario, setDataScenario] = useState([{}, {}, {}, {}, {}, {}]);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [subpath, setSubpath] = useState("");
  const [allSub, setAllSub] = useState([]);
  const [indexSelect, setIndexSelect] = useState("");
  const video = document.getElementById("video") as HTMLVideoElement | null;
  const scrollableDiv = document.getElementById(
    "scrollableDiv"
  ) as HTMLVideoElement | null;
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const getData = async () => {
    try {
      const data = {
        task_id: params.id,
      };
      const res = await api_getVideoID(data);
      const duration = new Date(res[0].duration * 1000)
        .toISOString()
        .slice(11, 19);
      setDataVideo(res[0]);
      setTimeVideo(duration);
    } catch (e) {
      console.error(e);
    }
  };

  const getSubtitle = async () => {
    try {
      const res = await subtitle_APIDownload({ task_id: params.id });
      const sub_path = window.webkitURL.createObjectURL(res);
      setSubpath(sub_path);
    } catch (e) {
      console.error(e);
    }
  };

  const getAllSub = async () => {
    try {
      const res = await subtitle_APIGetSub({ task_id: params.id });
      setAllSub(res.result);
      setIndexSelect(res.result[0].sub_id);
    } catch (e) {
      console.error(e);
    }
  };

  const handleWatchComplete = () => {
    try {
      if (video !== null) {
        const st = allSub.map((item: any) => ({
          sub_id: item.sub_id,
          start_time:
            Number(parseInt(item.start_time.split(":")[0]) * 3600) +
            Number(parseInt(item.start_time.split(":")[1]) * 60) +
            Number(parseFloat(item.start_time.split(":")[2]).toFixed(3)),
          end_time:
            Number(parseInt(item.end_time.split(":")[0]) * 3600) +
            Number(parseInt(item.end_time.split(":")[1]) * 60) +
            Number(parseFloat(item.end_time.split(":")[2]).toFixed(3)),
        }));

        st.map((d: any, ind: number) => {
          if (
            video.currentTime >= d.start_time &&
            video.currentTime < d.end_time
          ) {
            getAbsoluteOffsetFromBody(
              document.getElementById("sub-box" + d.sub_id)
            );
            setIndexSelect(d.sub_id);
          } else if (ind != 0) {
            if (
              video.currentTime < d.start_time &&
              video.currentTime >= st[ind - 1].end_time
            ) {
              getAbsoluteOffsetFromBody(
                document.getElementById("sub-box" + st[ind - 1].sub_id)
              );
              setIndexSelect(st[ind - 1].sub_id);
            }
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getAbsoluteOffsetFromBody = (el: any) => {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _y += el.offsetTop - el.scrollTop + el.clientTop;
      el = el.offsetParent;
    }

    if (window.innerWidth > 992) {
      scrollableDiv?.scrollTo(_x, _y - 250);
    }
    if (window.innerWidth >= 640 && window.innerWidth <= 992) {
      scrollableDiv?.scrollTo(_x, _y - 640);
    }
    if (window.innerWidth < 640) {
      scrollableDiv?.scrollTo(_x, _y - 580);
    }
  };

  const selectSub = (time: any) => {
    try {
      let h = parseInt(time.split(":")[0]) * 3600;
      let m = parseInt(time.split(":")[1]) * 60;
      let s = parseFloat(time.split(":")[2]);

      let totalTime = h + m + s;
      if (totalTime < 1.0) {
        totalTime = 0.0;
      } else {
        totalTime = totalTime;
      }
      seekTimeVideo(totalTime);
    } catch (e) {
      console.error(e);
    }
  };

  const seekTimeVideo = (time: any) => {
    if (video !== null) {
      video.currentTime = time;
    }
  };

  useEffect(() => {
    getData();
    // getSubtitle();
    // getAllSub();
  }, []);

  return (
    <div className="content-container">
      <ModalScene isModalOpen={isModalOpen} setIsModalOpen={setisModalOpen} />
      <Row>
        <h2 style={{ color: "#21134F" }}>Video</h2>
      </Row>
      <Row align="middle">
        {/* {"< "} {dataVideo.title} */}
        <Breadcrumb
          separator=" "
          items={[
            {
              title: (
                <h3>
                  <LeftCircleFilled
                    style={{ fontSize: "12pt", color: "#21134F" }}
                    onClick={() => navigate(-1)}
                  />
                </h3>
              ),
            },
            {
              title: <h4 style={{ color: "#21134F" }}>{dataVideo.title}</h4>,
            },
          ]}
        />
      </Row>
      <Row style={{ marginTop: "5px" }} gutter={[32, 16]}>
        <VideoDisplay
          timeVideo={timeVideo}
          handleWatchComplete={handleWatchComplete}
          subpath={subpath}
        />
        {/* ----------------- */}
        <DetailDisplay
          valueRadio={valueRadio}
          setValueRadio={setValueRadio}
          valueChoice={valueChoice}
          setValueChioce={setValueChioce}
          dataScenario={dataScenario}
          setDataScenario={setDataScenario}
          setisModalOpen={setisModalOpen}
          indexSelect={indexSelect}
          selectSub={selectSub}
          allSub={allSub}
        />
      </Row>
    </div>
  );
};

export default VideoPage;
