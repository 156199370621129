import { Empty, Layout } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

type Props = {
  collapsed: boolean;
};

const ContentLayout = ({ collapsed }: Props) => {
  const { Content } = Layout;
  const { t } = useTranslation();
  return (
    <Content
      // style={
      //   collapsed === true
      //     ? { marginLeft: "80px" }
      //     : { marginLeft: "200px", background: "#f5f5f5" }
      // }
      style={
        collapsed === true
          ? { marginLeft: "0px" }
          : { marginLeft: "0px", background: "#f5f5f5" }
      }
    >
      <Outlet />
      {/* test */}
      {/* <div style={{ fontSize: "18pt" }}> {t("test")}</div> */}
      {/* <Empty /> */}
    </Content>
  );
};

export default ContentLayout;
