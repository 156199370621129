import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Layout,
  Typography,
  Card,
  Space,
  DatePicker,
  Button,
  Select,
  Empty,
  TimePicker,
  Table
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import StatusCard from "./StatusCard";
import StackedChart from "./StackedChart";
import "./index.less";
//sample data
import moment from "moment";
import dayjs from "dayjs";
import { get_all_task, get_filter_task, get_graph, get_graph_daily, get_graph_monthly, get_graph_record } from '../../services/task';
import { get_project } from "../../services/project";
import { useParams } from "react-router-dom";

const Dashboard = () => {
  const params = useParams();
  const [chartData, setChartData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [pickerType, setPickerType] = useState('month');
  const [status, setStatus] = useState('');
  const [date, setDate] = useState('');
  const [projectData, setProjectData] = useState({});
  const [avgPreData, setPRE] = useState(0);
  const [avgRecallData, setRecall] = useState(0);
  const [avgData, setAvg] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const getStatus = localStorage.getItem('statusChart');
  let getMode = localStorage.getItem('modeChart') || 'Daily';
  let dateFilter = null;
  // const { RangePicker } = DatePicker;

  //!Style
  const hidePicker = { display: 'block', width: 0, height: 0, zIndex: 0, position: 'absolute' }

  //!Function
  const handleMonthPick = () => {
    const datePick = document.getElementById('monthPick')
    datePick.click()
  }
  const handleYearPick = () => {
    const datePick = document.getElementById('yearPick')
    datePick.click()
  }
  const handleDatePickerChange = (date, dateString) => {
    localStorage.setItem('dateChart', date !== null ? moment(date.$d).format('YYYY-MM') : '');
    setDate(date !== null ? moment(date.$d).format('YYYY-MM') : '');
  };

  const selectChange = (value) => {
    localStorage.setItem('modeChart', value);

    if (value === 'Daily') {
      setPickerType('month');
    } else if (value === 'Monthly') {
      setPickerType('year');
    } else {
      setPickerType('month');
    }
  }

  const selectChangeStatus = (value) => {
    localStorage.setItem('statusChart', value);
    setStatus(value);
  }

  useEffect(() => {
    getProjectData();
    getChartData();
    getPieData();
    getData();
  }, [pickerType, dateFilter, status, startDate, endDate])

  useEffect(() => {
    selectChange(getMode);
  }, [getMode])

  const getData = async () => {
    const res = await get_filter_task({
      data: {
        project_id: params.project_id,
        start_date: startDate.toString(),
        end_date: endDate.toString()

      }
    });

    console.log("res get task :", res.result);

    let sum = 0.00;
    let sum2 = 0.00;
    let sum3 = 0.00;
    let size = 0;
    for (let a in res.result) {
      // console.log("acc task :", res.result[a].precition, res.result[a].precition.replace("%", ""));
      if (res.result[a].status != 1) {
        // console.log("task precition :", res.result[a].precition, res.result[a].precition.replace("%", ""));
        size++;
        if(res.result[a].precition === undefined || res.result[a].precition === null || res.result[a].precition === NaN || res.result[a].precition == "NaN"){
          sum += 0.00;
        }else{
          sum += parseFloat(res.result[a].precition.replace("%", ""));
        }
        if(res.result[a].recall === undefined || res.result[a].recall === null || res.result[a].recall === NaN){
          sum2 += 0.00;
        }else{
          sum2 += parseFloat(res.result[a].recall.replace("%", ""));
        }
        if(res.result[a].acc === undefined || res.result[a].acc === null || res.result[a].acc === NaN){
          sum3 += 0.00;
        }else{
          sum3 += parseFloat(res.result[a].acc.replace("%", ""));
        }
       
       
      }

    }
    console.log("sum", sum, sum2, sum3);
    console.log("avg", (sum3 / size).toFixed(2));
    if(sum === undefined || sum === null ){
      sum = 0.00;
    }
    if(sum2 === undefined || sum2 === null){
      sum2 = 0.00;
    }
    if(sum3 === undefined || sum3 === null){
      sum3 = 0.00;
    }
    if (size > 0 ) {
      setPRE((sum / size).toFixed(2));
      setRecall((sum2 / size).toFixed(2));
      setAvg((sum3 / size).toFixed(2));
    } else {
      setPRE((0).toFixed(2));
      setRecall((0).toFixed(2));
      setAvg((0).toFixed(2));
    }

  }

  const getProjectData = async () => {
    try {
      const res = await get_project({ data: { project_id: params.project_id } });
      // console.log("res get project :", res.data[0]);
      setProjectData(res.data[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getChartData = async () => {


    const g = await get_graph_record({
      "start_date": startDate.toString(),
      "end_date": endDate.toString()
    });
    console.log(g.data);


    const result = g.data;


    let d0 = []
    for (let a = 0; a < result.length; a++) {
      console.log(result[a]);
      console.log(d0);
      let r = result[a].rule
      console.log(r);

      if (a == 0) {
        if (result[a].answer == "NA") {
          d0.push({
            rule: r,
            pass: 0,
            fail: 0,
            //na:1
          })
        } else if (result[a].score == "PASS") {
          // console.log(result[a].count);
          d0.push({
            rule: r,
            pass: 1,
            fail: 0,
            //na:0
          })
        } else if (result[a].score == "FAIL") {
          d0.push({
            rule: r,
            pass: 0,
            fail: 1,
            //na:0
          })
        }
        else {
          d0.push({
            rule: r,
            pass: 0,
            fail: 0,
            //na:0
          })
        }
      } else {
        console.log("a>0");
        let dub = false;
        let idx = 0;
        for (let b in d0) {
          console.log("loop", b);
          if (d0[b].rule == r) {
            dub = true;
            idx = b;
          }
        }
        if (dub) {
          if (result[a].answer == "NA") {
            d0[idx] = {
              rule: r,
              pass: d0[idx].pass,
              fail: d0[idx].fail,
              //na:d0[idx].na + 1
            }
          }else if (result[a].score == "PASS") {
            d0[idx] = {
              rule: r,
              pass: d0[idx].pass + 1,
              fail: d0[idx].fail,
              //na:d0[idx].na
            }
          } else if (result[a].score == "FAIL") {
            d0[idx] = {
              rule: r,
              pass: d0[idx].pass,
              fail: d0[idx].fail + 1,
              //na:d0[idx].na
            }
          } else {
            d0[idx] = {
              rule: r,
              pass: d0[idx].pass,
              fail: d0[idx].fail,
              //na:d0[idx].na
            }
          }
        }
        else {
          if (result[a].answer == "NA") {
            d0.push({
              rule: r,
              pass: 0,
              fail: 0,
              //na:1
            })
          }else if (result[a].score == "PASS") {
            d0.push({
              rule: r,
              pass: 1,
              fail: 0,
              //na:0
            })
          } else if (result[a].score == "FAIL") {
            d0.push({
              rule: r,
              pass: 0,
              fail: 1,
              //na:0
            })
          } else {
            d0.push({
              rule: r,
              pass: 0,
              fail: 0,
              //na:0
            })
          }
        }
      }
      console.log(d0);
    }
    console.log(d0);
    setChartData(d0);

  }

  const getPieData = async () => {
    console.log("date: ", startDate, endDate);
    const res = await get_graph({
      "start_date": startDate.toString(),
      "end_date": endDate.toString(),
      "status": status
    });
    if (res.status === 200) {
      console.log("pie data", res.response);
      setPieData(res.response);
    }

  };

  const columns = [
    {
      title: 'Rule',
      dataIndex: 'rule',
      key: 'rule',
    },
    {
      title: 'Pass',
      dataIndex: 'score',
      key: 'score',
      render: (value, item) => {
        let rule_percent = 0.00;
        if(item.pass + item.fail == 0){
          rule_percent = 0.00;
        }else{
          rule_percent = (item.pass / (item.pass + item.fail) * 100).toFixed(2);
        }
        
        return (<p>{rule_percent}%</p>)
      },
    },

  ];


  return (
    <>
      <Layout style={{ padding: "20px" }}>
        <Space style={{ display: "flex", justifyContent: "space-between", marginBottom: '20px' }}>
          <div>
            <h3>Project: {projectData.name}
            </h3>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginRight: "35px" }}>

            <DatePicker.RangePicker showTime onChange={(e) => {
              // localStorage.setItem('dateChart', date !== null ? moment(date.$d).format('YYYY-MM') : '');
              console.log(e);
              console.log(e !== null ? moment(e[0].$d).format('YYYY-MM-DD hh:mm:ss') : '');
              console.log(e !== null ? moment(e[1].$d).format('YYYY-MM-DD hh:mm:ss') : '');
              if (e !== null) {
                setStartDate(moment(e[0].$d).format('YYYY-MM-DD hh:mm:ss'));
                setEndDate(moment(e[1].$d).format('YYYY-MM-DD hh:mm:ss'));
                getChartData();
                getPieData();
                getData();
              }
            }} />
          </div>
        </Space>


        {/* stacked chart card */}
        <Row >
          <Col span={16} >
            <Card style={{ width: "auto", marginBottom: '10px' }} >
              <Space direction="vertical" style={{ width: "100%" }}>
                <StackedChart data={chartData} view={pickerType} />
              </Space>
            </Card>
            <Row>
              <Col span={9}>
                <StatusCard sidePrefix="Task:" mode={pickerType} status={status} date={dateFilter} pieData={pieData} />
              </Col>
              <Col span={5}>

                <Card style={{ width: '99%', height: '284px', marginLeft: "2px", textAlign: "center" }}>
                  <div style={{ marginTop: "30%" }}>
                    <h3>Average Precision:</h3>
                    <b style={{ fontSize: "50px", color: avgPreData < 70 && avgPreData > 50 ? "orange" : avgPreData <= 50 ? "#EF4040" : "#508D69" }}>{avgPreData}%</b>
                  </div>

                </Card>
              </Col>
              <Col span={5}>

                <Card style={{ width: '99%', height: '284px', marginLeft: "2px", textAlign: "center" }}>
                  <div style={{ marginTop: "30%" }}>
                    <h3>Average Recall:</h3>
                    <b style={{ fontSize: "50px", color: avgRecallData < 70 && avgRecallData > 50 ? "orange" : avgRecallData <= 50 ? "#EF4040" : "#508D69" }}>{avgRecallData}%</b>
                  </div>

                </Card>

              </Col>
              <Col span={5}>

                <Card style={{ width: '99%', height: '284px', marginLeft: "2px", textAlign: "center" }}>
                  <div style={{ marginTop: "30%" }}>
                    <h3>Average Accuracy:</h3>
                    <b style={{ fontSize: "50px", color: avgData < 70 && avgData > 50 ? "orange" : avgData <= 50 ? "#EF4040" : "#508D69" }}>{avgData}%</b>
                  </div>

                </Card>
              </Col>
            </Row>
          </Col>

          <Col span={7} style={{ marginLeft: '25px' }}>
            <Table dataSource={chartData} columns={columns} pagination={false} />
          </Col>
        </Row>

        <Row >
          {/* <Col span={10}>
            <StatusCard sidePrefix="Task:" mode={pickerType} status={status} date={dateFilter} />
          </Col> */}
          {/* <Col span={1}></Col> */}
          {/* <Col span={24}>
            <Table dataSource={chartData} columns={columns} pagination={false} />
          </Col> */}
        </Row>
      </Layout >
    </>
  );

};

export default Dashboard;
