import { useAuth } from "../../utils/Auth/AuthWrapper";
import React, { useState } from "react";
import { Form, Input, Button, Checkbox, message, Col } from "antd";
import LogoLogin from "../../assets/images/logo/manna.png";
import { useNavigate } from "react-router-dom";
import "./index.less";
// import "antd/dist/antd.css";
// import { login } from "@/services/apis/user";

import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { user_login } from "../../services/user";
type Props = {};

const LoginPage = (props: Props) => {
  let navigate = useNavigate();
  const auth = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [form] = Form.useForm();
  const handleLogin = async (value: any) => {
    setSubmitting(true);
    try {
      const data = {
        user_email: value.email,
        password: value.password,
      };
      const res = await user_login(data);
      // console.log("res login::", res);

      if (res.message == "success" && res.status == 1) {
        auth.login(res.result);
      } else {
        form.setFields([
          {
            name: "email",
            errors: [res.error_message],
          },
        ]);
      }
    } catch (e) {
      console.error(e);
      message.error("Email or password is incorrect, please try again !");
    }
    setSubmitting(false);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };
  return (
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper-log">
          {/* <img src={require("@/images/Login-cuate.png")} alt="Login" /> */}
          <img src={LogoLogin} alt="Login" />
        </div>
        <Form
          layout="vertical"
          form={form}
          name="login-form"
          initialValues={{
            autoLogin: true,
            email: "",
            password: "",
          }}
          onFinish={async (values) => {
            handleLogin(values);
          }}
        >
          <Col style={{textAlign:"center"}}>
          <img src={`/logo-manna.png`} width={250} style={{marginRight:"20px"}}></img>
          <p className="form-title" style={{color:"rgb(101 100 100)"}}>Welcome</p>
          </Col>
         
          <Form.Item
            label="Email *"
            name="email"
            rules={[
              { type: "email", message: "The input is not a valid e-mail" },
              { required: true, message: "Please input your email!" },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Password *"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox onChange={onChange} checked={checked}>
                Remember me
              </Checkbox>
            </Form.Item>

            {/* <a className="login-form-forgot" href="#">
              Forgot password
            </a> */}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={submitting}
              style={{
                background:
                  "linear-gradient(90deg, rgba(137,33,107,1) 0%, rgba(137,33,107,1) 35%,  rgba(218,68,83,1) 100%)",
                padding: "0px",
              }}
            >
              Login
            </Button>
          </Form.Item>
          {/* <p className="form-not-reg">
            Not Register Yet?{" "}
            <a onClick={() => navigate("/user/registration")}>
              Create Account!
            </a>
          </p> */}
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
