import { useState, useEffect, useMemo } from "react";
import { Button, Card, Col, Menu, Row, Select, message, Switch, Dropdown, Modal, Empty, Tooltip, Image, Checkbox, Table } from "antd";
import Search from "antd/es/input/Search";
import { ExclamationCircleOutlined, DownOutlined, UpOutlined, CommentOutlined, MessageOutlined } from "@ant-design/icons";
import { get_original_transcribe, get_task_answer, save_task_answer, save_task_status, get_task, test_analyze, update_task_acc } from "../../../services/task";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import type { MenuProps } from 'antd';
import customer from '../../../assets/images/icons/user-service.png';
import agent from '../../../assets/images/icons/customer-service.png';
import '../styles/index.less';
import TextArea from "antd/es/input/TextArea";

interface IBoardProps {
    title: string;
    task: any;
    lists: [];
    lists2: [];
    answer: [];
    setLists: any;
    color: any;
    height: string;
    search: boolean;
    text: string;
    info: string;
    setFound: any;
    setFound2: any;
}

interface ICard {
    id: number;
    text: string;
}



const Board: React.FC<IBoardProps> = ({ title, task, lists, lists2, answer, setLists, color, height, search, text, info, setFound, setFound2 }) => {
    const params = useParams();
    const history = useLocation();
    const navigate = useNavigate();
    const onSearch = (value: string) => console.log(value);
    const [checked, setChecked] = useState(true);
    const [lengthMasking, setLengthMasking] = useState(0);
    const [maskingObj, setMasking] = useState([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [show, setShow] = useState(true);
    const [data, setData] = useState<any>([]);
    const [data2, setData2] = useState<any>([]);
    const [soundMasking, setSoundMasking] = useState(false);
    const [focusrow, setFocusrow] = useState(null);
    const [precision, setPrecition] = useState("0%");
    const [recall, setRecall] = useState("0%");
    const [acc, setAcc] = useState("0%");
    const [txtArea, setShowtxtArea] = useState<boolean[]>([]);

    const downloadJson = async () => {
        const res = await get_original_transcribe({ task_id: task.task_id, track_id: task.track_id });
        // console.log("original subtitle: ",res);

        let storageObj = res.result;
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(storageObj, null, 2));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", params.task_id + '_' + params.track_id + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }



    const onChangeVerify = async (checked: boolean) => {
        if (checked === true) {
            let verifyTask = await save_task_status({ task_id: task.task_id, track_id: task.track_id, status: 7 });
            if (verifyTask.message === 'success') {
                message.success(`Verify Task สำเร็จ`);
                setChecked(true);
            } else {
                message.error(`Verify Task ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
            }
        } else {
            let unverifyTask = await save_task_status({ task_id: task.task_id, track_id: task.track_id, status: 6 });
            if (unverifyTask.message === 'success') {
                message.success(`Unverify Task สำเร็จ`);
                setChecked(false);
            } else {
                message.error(`Unverify Task ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
            }
        }
    }

    const getStatusTask = async () => {
        try {
            const res = await get_task({ data: { task_id: params.task_id, track_id: params.track_id } });
            console.log("res get task :", res.data[0]);
            // setTaskData(res.data[0]);
            setPrecition(res.data[0].precition);
            setRecall(res.data[0].recall);
            setAcc(res.data[0].acc);
            if (res.data[0].status === 7) {
                setChecked(true);
            } else {
                setChecked(false);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        getStatusTask();
    }, [task])

    useEffect(() => {
        if (lists.length > 0) {
            const filteredMasking = lists.filter((obj) => obj['masking'] === 'true');
            setLengthMasking(filteredMasking.length);
            setMasking(filteredMasking);
            // getAnswer();
            setShow(true);

            // const newArray: any = lists.map((obj1: any) => {
            //     // convert start time
            //     const hours = Math.floor(obj1.startTime / 3600);
            //     const minutes = Math.floor((obj1.startTime % 3600) / 60);
            //     const remainingSeconds = obj1.startTime % 60;
            //     const formattedHours = String(hours).padStart(2, '0');
            //     const formattedMinutes = String(minutes).padStart(2, '0');
            //     const formattedSeconds = String(remainingSeconds).padStart(2, '0');

            //     const matchingObj2 = debugging.find((obj2: any) => obj2.id.toString() === obj1.id.toString()); // id
            //     if (matchingObj2) {
            //         return {
            //             answer: obj1.answer,
            //             endTime: obj1.endTime,
            //             id: obj1.id,
            //             masking: obj1.masking,
            //             maskingMessage: obj1.maskingMessage,
            //             maskingTime: obj1.maskingTime,
            //             message: obj1.message,
            //             nonMask: obj1.nonMask,
            //             speaker: obj1.speaker,
            //             startTime: obj1.startTime,
            //             formatStartTime: `${formattedHours}:${formattedMinutes}:${parseFloat(formattedSeconds).toFixed(0)}`,
            //             token: obj1.token,
            //             wrongMask: obj1.wrongMask,
            //             debugging: matchingObj2.message,
            //             collapse: false
            //         };
            //     }
            //     return null;
            // });
            setData(lists.map((item: any) => {
                return { ...item, show: false }
            }));
            setData2(lists2);
            if (answer == null) {
                var arr = [];
                var arr2 = [];
                for (var i = 1; i <= lists.length; i++) {
                    arr.push(i.toString());
                    arr2.push(false);
                }
                setSelectedValues(arr);
                setShowtxtArea(arr2);
            } else {
                setSelectedValues(answer);
            }

        } else {
            setLengthMasking(0);
            setShow(false);
            setMasking([]);
        }
    }, [lists])

    let current_utt = "utt0";

    const jumpTo = (time: any) => {

        console.log(time);

        (document.getElementById("utt" + time) as HTMLElement).scrollIntoView({ behavior: "smooth", inline: "start", block: "nearest" });

        console.log(data2);

        for (let a in data2) {
            // console.log(a);
            // console.log(data2[a].id);
            // console.log(data2[a]);

            // let idx = data2[a].id - 1;
            // (document.getElementById(data2[a].id) as HTMLElement).style.borderColor = "#d9d9d9";
            // (document.getElementById(data2[a].id+"_text") as HTMLElement).innerHTML = data2[a].message;

            // if (data2[a].id == time) {
            //     // console.log(data2[a].intent[0]);
            //     for (let z in data2[a].intent) {
            //         console.log(data2[a].intent[z].matched_text);
            //         highlight(time + "_text", data2[a].intent[z].matched_text);
            //     }

            // }
            // else{
            //     (document.getElementById("utt"+idx) as HTMLElement).style.borderColor = "#d9d9d9";
            //     (document.getElementById(idx+"_text") as HTMLElement).innerHTML = data2[a].message;
            // }
        }
        (document.getElementById(current_utt) as HTMLElement).style.borderColor = "#d9d9d9";
        (document.getElementById("utt" + time) as HTMLElement).style.borderColor = "#FF8911";

        current_utt = "utt" + time;
    }

    function highlight(id: any, text: any) {
        let inputText: any = document.getElementById(id);
        var innerHTML = inputText.innerHTML;
        var index = innerHTML.indexOf(text);
        if (index >= 0) {
            innerHTML = innerHTML.substring(0, index) + "<span style=\"background-color: #FFCC70;\">" + innerHTML.substring(index, index + text.length) + "</span>" + innerHTML.substring(index + text.length);
            inputText.innerHTML = innerHTML;
        }
    }

    // const getAnswer = async () => {
    //     if (answer === null) {
    //         setSelectedValues([]);
    //     } else {
    //         setSelectedValues(answer);
    //     }
    // };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div onClick={downloadJson}>
                    Download Json
                </div>
            )
        },
        // {
        //     key: '2',
        //     label: (
        //         <div onClick={downloadSound}>
        //             Download Sound
        //         </div>
        //     )
        // }
    ];

    const onChangeMasking = (id: any, value: string) => {
        console.log(`selected ${id} ${value}`);

        let items = [...selectedValues];

        let item = items[id];

        item = value;

        items[id] = item;

        setSelectedValues(items);

    };

    const sendAnalyze = async () => {

        // console.log('answer', selectedValues);
        const res = await save_task_answer({
            task_id: task.task_id,
            track_id: task.track_id,
            data: selectedValues
        });
        let tp = 0.00;
        let tn = 0.00;
        let fp = 0.00;
        let fn = 0.00;
        let z = 0.00;
        for (let b in selectedValues) {
            console.log(b);
            console.log(data[b].id + " → " + data[b].score + " → " + selectedValues[data[b].id]);
            if (data[b].score.toUpperCase() == selectedValues[data[b].id].toUpperCase()) {
                if(data[b].score.toUpperCase() == "PASS"){
                    tp++;
                }else if(data[b].score.toUpperCase() == "FAIL"){
                    tn++;
                }else{
                    z++;
                }
            }else {
                if(selectedValues[data[b].id] == "na"){
                    z++;
                }else if(data[b].score.toUpperCase() == "PASS"){
                    fp++;
                }else if(data[b].score.toUpperCase() == "FAIL"){
                    fn++;
                }else{
                    z++;
                }
            }
            // if (selectedValues[data[b].id] == "na") {
            //     z++;
            // }
        }
        // console.log('res answer', res);




        let precition0 = 0;
        if((tp+fp) > 0){
            precition0 = tp/(tp+fp);
        }
        let recall0 = 0;
        if((tp+fn) > 0){
            recall0 = tp/(tp+fn);
        }
        let accuracy0 = (tp+tn)/(tp+tn+fp+fn);
        console.log("tp = ",tp);
        console.log("tn = ",tn);
        console.log("fp = ",fp);
        console.log("fn = ",fn);
        console.log(precition0,"/",recall0,"/",accuracy0);
        const res4Test = await update_task_acc({ task_id: params.task_id, track_id: params.track_id, precition: (precition0*100).toFixed(2) + "%",recall: (recall0 * 100).toFixed(2) + "%", acc: (accuracy0 * 100).toFixed(2) + "%" });
        
        setPrecition((precition0*100).toFixed(2) + "%");
        setRecall((recall0 * 100).toFixed(2) + "%");
        setAcc( (accuracy0 * 100).toFixed(2) + "%");
       
        if (res4Test.message === "success") {
            let verifyTask = await save_task_status({ task_id: task.task_id, track_id: task.track_id, status: 6 });
            if (verifyTask.message === "success") {
                message.success(`Evaluate สำเร็จ`);
                window.location.reload();
            } else {
                message.error(`Evaluate Task ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
            }

        } else {
            message.error(`Evaluate ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
        }
    }

    const sendAnswer = () => {
        Modal.confirm({
            title: 'ท่านต้องการบันทึกข้อมูลใช่หรือไม่ !',
            icon: <ExclamationCircleOutlined />,
            // content: "ท่านต้องการบันทึกข้อมูลหรือไม่ !",
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: async () => {
                // console.log('answer', selectedValues);
                const res = await save_task_answer({
                    task_id: task.task_id,
                    track_id: task.track_id,
                    data: selectedValues
                });

                // console.log('res answer', res);
                if (res.status === 200) {
                    message.success(`Save สำเร็จ`);
                } else {
                    message.error(`Save ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
                }
            }
        });
    }

    const changeSoundMasking = (id: number) => {
        // let setNewData = data.map((row: any) => {
        //     if (row.id === id) {
        //         return { ...row, collapse: !row.collapse }
        //     }
        //     return row;
        // })
        // setData(setNewData);
    }

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const test = useMemo(() => {
        const newdata = data
        // .map((item:any)=>{
        //     return {...item,show:null}
        // })
        return newdata
    }, [data])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'label',
            key: 'label',
            // align: 'left',
            render: (_: any, record: any) => {
                let txt_id = record.id + "_note";
                // return <div><Row gutter={16}>
                //     <p>{record.label}</p>
                // </Row>
                //     <TextArea id={txt_id} placeholder="Note" style={{display:txtArea[record.id]?"":"none"}}></TextArea>
                //     </div>;
                return (
                    <>
                        <p>{record.label}</p>
                        {/* {record.show ?<>true</> :<>false</>} */}
                        <TextArea
                            id={txt_id}
                            style={{ display: record.show ? "" : "none" }}
                            // value={value}
                            // onChange={(e) => setValue(e.target.value)}
                            placeholder="Note"
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}
                        />

                    </>
                )
            }
        },
        {
            title: 'Result',
            dataIndex: 'score',
            key: 'score',
            // align: 'center',
            render: (_: any, record: any) => {


                let index = 0;
                if (record.score == "PASS") {
                    // console.log(record.intent1.found);
                    return <Button style={{ background: "green", color: "white" }} onClick={() => {
                        setFocusrow(record.id);
                        if (record.intent1.found !== undefined) {
                            setFound(record.intent1.found);

                        } else {
                            setFound([]);
                        }
                        if (record.intent2.found !== undefined) {
                            setFound2(record.intent2.found);

                        } else {
                            setFound2([]);
                        }
                        // if (record.intent1.found !== undefined) {
                        //     jumpTo(record.intent1.found[index]);
                        //     index++;
                        //     if (index >= record.intent1.found.length) {
                        //         index = 0;
                        //     }
                        // }
                    }

                    }>{record.score}</Button>;
                } else {
                    return <Button style={{ background: "red", color: "white" }} onClick={() => {
                        setFocusrow(record.id);
                        // console.log(record.intent1.found);
                        if (record.intent1.found !== undefined) {
                            setFound(record.intent1.found);
                        } else {
                            setFound([]);
                        }
                        if (record.intent2.found !== undefined) {
                            setFound2(record.intent2.found);

                        } else {
                            setFound2([]);
                        }

                        // if (record.intent1.found !== undefined) {
                        //     jumpTo(record.intent1.found[index]);
                        //     index++;
                        //     if (index >= record.intent1.found.length) {
                        //         index = 0;
                        //     }
                        // }
                    }

                    }>{record.score}</Button>;
                }

            },
        },
        {
            title: 'Answer',
            dataIndex: 'answer',
            key: 'answer',
            // align: 'center',
            render: (_: any, record: any, ind: number) => {
                let txt_list = txtArea;
                // if ((answer[record.id] + "").toUpperCase() == record.score.toUpperCase() || answer[record.id] == "na") {
                return <Select
                    defaultValue={answer ? answer[record.id] : null}
                    style={{ width: 80 }}
                    onChange={(value: any) => { onChangeMasking(record.id, value) }}
                    options={[
                        { value: 'pass', label: 'Pass' },
                        { value: 'fail', label: 'Fail' },
                        { value: 'na', label: 'N/A' },
                    ]}
                />;

                // } else {
                //     return <Row><Select
                //         defaultValue={answer ? answer[record.id] : null}
                //         style={{ width: 80 }}
                //         onChange={(value: any) => { onChangeMasking(record.id, value) }}
                //         options={[
                //             { value: 'pass', label: 'Pass' },
                //             { value: 'fail', label: 'Fail' },
                //             { value: 'na', label: 'N/A' },
                //         ]}
                //     /><MessageOutlined style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => {
                //         if (txtArea[record.id]) {
                //             txt_list[record.id] = false;
                //         } else {
                //             txt_list[record.id] = true;
                //         }
                //         console.log(txtArea);
                //         console.log("data:", data);
                //         console.log(data[record.id]);

                //         setShowtxtArea(txt_list);

                //         const setNewData = data.map((item: any) => {
                //             if (item.id == record.id) {
                //                 return { ...item, show: !record.show }
                //             } else {
                //                 return item
                //             }
                //         })
                //         setData(setNewData)

                //         // let newList = data;
                //         // newList[record.id].isOpen = txt_list[record.id];
                //         // setData(newList); 

                //     }} /></Row>;
                // }

            },
        }
    ];

    return (
        <Card title={
         
            <Row gutter={16}>


                <Col span={12} style={{ marginRight: '30px', marginTop: "10px" }}><Row><h1 style={{ fontSize: "18px", color: "black" }}>Result</h1></Row></Col>
                <Col span={12} style={{ marginRight: '30px', marginTop: "10px", position: "absolute", right: "10px" }}>
                    {/* <Row><h1 style={{ fontSize: "18px", color: "black" }}>
                Found: {(acc?.length + acc?.length)}
                </h1></Row> */}
                    <Row><h1 style={{ fontSize: "14px", color: "black" }}>
                        Precision/Recall/Accuracy:
                    </h1></Row>
                    <Row><h1 style={{ fontSize: "18px", color: "green",marginTop:"-10px" }}>
                        {precision}</h1>
                        <h1 style={{ fontSize: "18px", color: "green",marginTop:"-10px" }}>
                        /{recall}</h1>
                        <h1 style={{ fontSize: "18px", color: "green",marginTop:"-10px" }}>
                        /{acc}</h1></Row>

                </Col>


            </Row>

        }>

            {show === true ?
                <div>
                    <div style={{ height: '610px', overflowY: "auto", overflowX: "hidden", marginBottom: "30px", paddingRight: "10px" }}>
                        {/* {data.map((card: any) => (
                            <div>
                                {card.message !== '' ?
                                    <Row gutter={16}>
                                       
                                    </Row>
                                    : ''}
                            </div>
                        ))} */}
                        <Table rowClassName={(record, index) => index !== focusrow ? 'table-row-light' : 'table-row-dark'}
                            dataSource={test}
                            // dataSource={data} 
                            columns={columns} pagination={false} />
                    </div>
                    <div style={{ justifyContent: 'end', display: 'flex', gap: '8px', alignItems: 'center' }}>
                        {/* <Dropdown menu={{ items }}>
                            <Button type="primary" style={{ width: 'fit-content' }}>Download</Button>
                        </Dropdown> */}
                        {/* <Button type="primary" onClick={sendAnswer}>Save Masking</Button> */}
                        <Button type="primary" onClick={sendAnalyze}>Save</Button>
                        {/* <Switch checked={checked} onChange={onChangeVerify} />Verify */}
                    </div>
                </div>
                : <div style={{ height: '610px', display: 'grid', alignItems: 'center' }}><Empty /></div>}
        </Card>
    );
};

export default Board;
