import config from "../config/config";
import { getToken } from "../utils/prefix";

export async function user_login(data) {
  const res = await fetch(config.api_backend + `/user/login`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function user_create(data) {
  const res = await fetch(config.api_backend + `/user/register`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function user_update(data) {
  const res = await fetch(config.api_backend + `/user/update`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function user_delete(data) {
  const res = await fetch(config.api_backend + `/user/delete`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_user(data) {
  const res = await fetch(config.api_backend + `/user/getUserById`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_all_user(data) {
  const res = await fetch(config.api_backend + `/user/all`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_all_org_user(data) {
  const res = await fetch(config.api_backend + `/user/all_org`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}


export async function save_user_status(data) {
  const res = await fetch(config.api_backend + `/user/saveUserStatus`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_profile(){
  try{
  const res = await fetch(config.api_backend + `/user/profile`, {
    body: JSON.stringify(),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
    
  })
  const dataRespone = await res.json();
  return dataRespone;
  }catch(e){
    console.log(e);
  }
}

export async function edit_profile(data){
  const res = await fetch(config.api_backend + `/user/edit`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },

  })
  const dataRespone = await res.json();
  console.log(dataRespone);
  return dataRespone;
}

export async function upload_profilepic(formData){
  const res = await fetch(config.api_backend + `/user/uploadprofile`, {
    body: formData,
    method: "POST",
    headers: {
      "x-access-token": getToken(),
    },

  })
  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_role(){
  const res = await fetch(config.api_backend + `/user/role`, {
    body: JSON.stringify(),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
    
  })
  const dataRespone = await res.json();
  return dataRespone;
}

