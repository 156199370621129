import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {

  Breadcrumb,
  Button,
  Col,
  Modal,
  Row,

} from "antd";
import { useParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { get_rule } from "../../services/rule";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";

const { confirm } = Modal;

type Props = {};

const ConfigRule = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [ruledata, setRuleData]: any = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await get_rule({ data: { rule_id: params.rule_id } });
      console.log("res get rule :", res.data[0]);
      setRuleData(res.data[0]);
    } catch (e) {
      console.error(e);
    }
  };



  return (
    <div className="content-container">
      {/* <Breadcrumb
    items={[
    
      {
        title: <a href="/rule" style={{ fontSize: "32px" }}>Rule</a>,
      },
      {
        title: <div style={{ fontSize: "32px" }}>Config Rule</div>,
      },
    ]}
  /> */}

      <Row gutter={16}>

        {/* <Button onClick={() => {
           Modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            content: "You won't be able to revert this!",
            okText: 'Yes, close!',
            cancelText: 'Cancel',
            onOk: async () => {
              window.location.href = "/rule";
            }
          });
        
        }} style={{ width: "75px", height: "40px", marginBottom: "20px",background:"#cc4474",color:"white" }}>← Back</Button> */}
        <h2 onClick={() => {
           Modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            content: "You won't be able to revert this!",
            okText: 'Yes, close!',
            cancelText: 'Cancel',
            onOk: async () => {
              // window.location.href = "/rule";
              navigate("/rule");
            }
          });
        
        }} style={{ fontSize: "32px",marginLeft:"15px",cursor:"pointer" }}>Rule</h2> <h2 style={{ fontSize: "32px",marginLeft:"10px" }}>&gt;</h2><h2 style={{ fontSize: "32px",marginLeft:"10px", color: "black" }}>{ruledata.name}</h2>
      </Row>
      <iframe src={config.editor_url +"?rule_id=" + params.rule_id + "&rule_name="+ruledata.name+"&org_id=1&org_code=ai9&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmciOiJhaTkiLCJwcm9qZWN0IjoiZGV2LTNWQjRiYmlJIiwic2VydmljZSI6ImRpYWxvZyIsImlzcyI6ImFpOSBjby4sbHRkLiIsImlhdCI6MTY5MjE3MjM0MiwiZXhwIjoxNjkyMTgzMTQyfQ.1frynavnIW93uCO2QNnAoSrIj_1tQxPaUqOakpuQOCE"} style={{ width: "100%", height: "650px",borderStyle:"groove",borderWidth:"1px",borderColor:"#c0c0c0",borderRadius:"10px" }}></iframe>

    </div>
  );
};

export default ConfigRule;
