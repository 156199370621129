import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Tag,
} from "antd";
import "./styles/index.less";
import { get_ScenarioByID, put_ScenarioAPI } from "../../../services/scenario";
import { useNavigate, useParams } from "react-router-dom";
import { selectedModel } from "../../../services/model";
import moment from "moment";

type Props = {};

const EditTable = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [titleName, setTitleName] = useState<any>("");
  const [topic, setTopic]: any = useState<any>([]);
  const [object, setObject]: any = useState([]);
  const [scene, setScene]: any = useState([]);
  const [editName, setEditName]: any = useState(false);

  const getData = async () => {
    try {
      const res = await get_ScenarioByID({ id: params.id });
      console.log("res data sce:", res);

      if (res.error) {
        navigate("/404");
      }

      const resultText: any = await selectedModel({ model: "text" });
      sortData(resultText);

      const resultObject: any = await selectedModel({ model: "object" });
      sortData(resultObject);

      const resultScene: any = await selectedModel({ model: "scene" });
      sortData(resultScene);

      const checkObj = await checkDataSelect(
        resultObject,
        res.datajson[0].object
      );
      const checkScene = await checkDataSelect(
        resultScene,
        res.datajson[1].scene
      );
      const checkText = await checkDataSelect(
        resultText,
        res.datajson[2].topic
      );
      console.log("checkObj", checkObj);
      console.log("checkScene", checkScene);
      console.log("checkText", checkText);

      setTopic(checkText);
      setObject(checkObj);
      setScene(checkScene);
      setTitleName(res.title);
    } catch (e) {
      console.error(e);
    }
  };

  const sortData = async (data: any) => {
    return data.sort(
      (a: any, b: any) =>
        moment(a.created_timestamp).unix() - moment(b.created_timestamp).unix()
    );
  };

  const checkDataSelect = async (data: any, checkData: any) => {
    const result = data.map((d: any) => {
      const res = checkData.filter((v: any) => {
        if (v.model_id == d.model_id) {
          return true;
        }
        return false;
      });

      if (res.length > 0) {
        return { ...d, check: true };
      } else {
        return { ...d, check: false };
      }
    });
    return result;
  };

  const handleCheckModel = async (
    check: boolean,
    data: any,
    modelName: string
  ) => {
    try {
      if (modelName == "topic") {
        const result = topic.map((d: any) => {
          if (d.model_id == data.model_id) {
            return { ...d, check: check };
          }
          return d;
        });
        setTopic(result);
      } else if (modelName == "object") {
        const result = object.map((d: any) => {
          if (d.model_id == data.model_id) {
            return { ...d, check: check };
          }
          return d;
        });
        setObject(result);
      } else if (modelName == "scene") {
        const result = scene.map((d: any) => {
          if (d.model_id == data.model_id) {
            return { ...d, check: check };
          }
          return d;
        });
        setScene(result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const confirm_checkSelectData = (data: any) => {
    const checktrue = data.filter((d: any) => {
      if (d.check == true) {
        return d;
      }
    });
    const res = checktrue.map((d: any) => {
      return {
        model_id: d.model_id,
        model_type: d.model_type,
        model_name: d.model_name,
        text: d.text,
      };
    });
    return res;
  };

  const handleSubmit = async () => {
    try {
      const data = {
        id: params.id,
        title: titleName,
        data: [
          {
            object: confirm_checkSelectData(object),
          },
          { scene: confirm_checkSelectData(scene) },
          { topic: confirm_checkSelectData(topic) },
        ],
      };

      const res = await put_ScenarioAPI(data);
      console.log("update s:::", res);
      if (res.status == 1) {
        navigate("/setting/settingSce");
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="content-container">
      <Row align="middle" gutter={16}>
        <Col>
          <h2>
            Scenario:
            {/* {scenario} */}
          </h2>
        </Col>
        <Col>{editName ? <Input /> : <h2>{titleName}</h2>}</Col>
        <Col>
          {editName ? (
            <Row gutter={16}>
              <Col
                style={{ cursor: "pointer" }}
                onClick={() => setEditName(!editName)}
              >
                cancle
              </Col>
              <Col
                style={{ cursor: "pointer" }}
                onClick={() => setEditName(!editName)}
              >
                ok
              </Col>
            </Row>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setEditName(!editName)}
            >
              Edit
            </div>
          )}
        </Col>
      </Row>

      <Row gutter={[16, 16]} justify="space-around" className="card-model">
        <Col xs={8} sm={8} md={7}>
          <Card title="Text" bordered={false} className="card-container">
            <div id="scrollableDiv" className="scrollModel ">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={topic}
                style={{ height: "250px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    <div className="card-text">
                      <div className="left">
                        <Checkbox
                          // value={item.text}
                          checked={item.check}
                          onClick={(e: any) => {
                            handleCheckModel(e.target.checked, item, "topic");
                          }}
                          style={{
                            color: "#E04D8B",
                            padding: "0px",
                            marginLeft: "50%",
                          }}
                          // size="medium"
                        />
                      </div>

                      <div className="right">
                        <Tag color="magenta" className="editScenario">
                          {item.text}
                        </Tag>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
        <Col xs={8} sm={8} md={7}>
          <Card title="Object" bordered={false} className="card-container">
            <div id="scrollableDiv" className="scrollModel ">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={object}
                style={{ minHeight: "250px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    <div className="card-text">
                      <div className="left">
                        <Checkbox
                          checked={item.check}
                          onClick={(e: any) => {
                            handleCheckModel(e.target.checked, item, "object");
                          }}
                          style={{
                            color: "#218AE7",
                            padding: "0px",
                            marginLeft: "50%",
                          }}
                        />
                      </div>
                      <div className="right">
                        <Tag color="blue" className="editScenario">
                          {item.text}
                        </Tag>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
        <Col xs={8} sm={8} md={7}>
          <Card title="Scene" bordered={false} className="card-container">
            <div id="scrollableDiv" className="scrollModel ">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={scene}
                style={{ height: "250px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    <div className="card-text">
                      <div className="left">
                        <Checkbox
                          checked={item.check}
                          onClick={(e: any) => {
                            handleCheckModel(e.target.checked, item, "scene");
                          }}
                          style={{
                            color: "#42a219",
                            padding: "0px",
                            marginLeft: "50%",
                          }}
                        />
                      </div>
                      <div className="right">
                        <Tag color="green" className="editScenario">
                          {item.text}
                        </Tag>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Button
        type="primary"
        style={{
          marginTop: "3%",
          borderRadius: "5px",
          marginLeft: "81%",
          width: "180px",
        }}
        onClick={() => {
          handleSubmit();
        }}
      >
        Confirm
      </Button>
    </div>
  );
};

export default EditTable;
