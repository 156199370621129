import { Layout } from "antd";

type Props = {
  collapsed: boolean;
};

const FooterLayout = ({ collapsed }: Props) => {
  const { Footer } = Layout;
  return (
    <Footer
      style={
        {textAlign:"center",
        // display: window.location.toString() == "/file"?"none":""
      }
      }
    >
      <img style={{height:"50px"}} src={`/footer.png`} ></img>
    </Footer>
  );
};

export default FooterLayout;
