import { useState, useEffect } from "react";
import { Button, Card, Col, Menu, Row, Select, message, Switch, Dropdown, Modal, Empty, Tooltip, Image, Checkbox } from "antd";
import Search from "antd/es/input/Search";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { get_original_transcribe, get_task_answer, save_task_answer, save_task_status } from "../../../services/task";
import { useParams } from "react-router-dom";
import type { MenuProps } from 'antd';
import customer from '../../../assets/images/icons/user-service.png';
import agent from '../../../assets/images/icons/customer-service.png';

interface IBoardProps {
    title: string;
    task: any;
    lists: [];
    setLists: any;
    color: any;
    height: string;
    search: boolean;
    text: string;
    info: string;
    sendSound: (time: any) => void
    jsonData: [];
    soundData: string;
    answer: any;
    evaluate: string;
    sendEvaluate: () => void;
}

interface ICard {
    id: number;
    text: string;
}



const Board: React.FC<IBoardProps> = ({ title, task, lists, setLists, color, height, search, text, info, sendSound, jsonData, soundData, answer, evaluate, sendEvaluate }) => {
    const params = useParams();
    const onSearch = (value: string) => console.log(value);
    const [checked, setChecked] = useState(true);
    const [lengthMasking, setLengthMasking] = useState(0);
    const [maskingObj, setMasking] = useState([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [show, setShow] = useState(true);

    const downloadJson = async () => {
        const res = await get_original_transcribe({ task_id: task.task_id, track_id: task.track_id });
        // console.log("original subtitle: ",res);

        let storageObj = res.result;
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(storageObj, null, 2));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", params.task_id + '_' + params.track_id + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    const downloadSound = () => {
        let downloadAnchorNodeSound = document.createElement('a');
        downloadAnchorNodeSound.setAttribute("href", soundData);
        downloadAnchorNodeSound.setAttribute("target", "_blank");
        downloadAnchorNodeSound.setAttribute("download", params.task_id + '_' + params.track_id + ".mp3");
        document.body.appendChild(downloadAnchorNodeSound); // required for firefox
        downloadAnchorNodeSound.click();
        downloadAnchorNodeSound.remove();
    }

    useEffect(() => {
        if (task.status === 7) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [task])

    useEffect(() => {
        if (lists.length > 0) {
            setShow(true);
            const filteredMasking = lists.filter((obj) => obj['masking'] === 'true');
            setLengthMasking(filteredMasking.length);
            setMasking(filteredMasking);
            getAnswer();
        } else {
            setLengthMasking(0);
            setShow(false);
            setMasking([]);
        }
    }, [lists])

    const jumpTo = (time: any) => {
        (document.getElementById(time) as HTMLElement).scrollIntoView({ behavior: "smooth", inline: "start", block: "nearest" });
    }

    const getAnswer = async () => {
        if (answer === null) {
            setSelectedValues([]);
        } else {
            setSelectedValues(answer);
        }
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div onClick={downloadJson}>
                    Download Json
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div onClick={downloadSound}>
                    Download Sound
                </div>
            )
        }
    ];

    const onChangeMasking = (value: string) => {
        // Check if the value is already in the array
        if (selectedValues.includes(value)) {
            // If it is, remove it
            setSelectedValues(selectedValues.filter((item) => item !== value));
        } else {
            // If it's not, add it to the array
            setSelectedValues([...selectedValues, value]);
        }
    };

    return (
        <Card title={
            <Row gutter={16}>
                <Col span={2} style={{ marginRight: '30px', marginTop: "10px" }}><Row><h1 style={{ fontSize: "18px", color: "black" }}>Hilight: {lengthMasking}</h1></Row></Col>
                <Col span={17} style={{ paddingBottom: '10px', overflow: 'hidden', marginTop: "10px" }}>
                    <Row style={{ maxHeight: '70px', overflow: 'auto' }}>
                        {maskingObj.map((key: any, i) => (
                            <Button style={{ margin: '2px', fontSize: '10px', height: '30px', padding: '8px' }} onClick={() => jumpTo(key.startTime)}>{key.id}</Button>
                        ))}
                    </Row>
                </Col>
                <Col span={3} style={{ textAlign: "right", marginTop: "10px", justifyContent: "end", fontSize: "18px" }}>
                    P/R/F1 : {evaluate}
                </Col>
            </Row>
        }>
            {show === true ?
                <div>
                    <div style={{ height: '400px', overflowY: "auto", overflowX: "hidden", marginBottom: "30px", paddingRight: "10px" }}>
                        {lists.map((card: any) => (
                            <Row gutter={16} >
                                <Col xs={2} xl={2} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', paddingTop: '8px', paddingRight: 'unset !important' }}>
                                    {card.speaker === 'agent' ?
                                        <Image height={40} preview={false} src={agent} style={{ width: '40px' }} />
                                        : ''}
                                    {card.speaker === 'customer' ?
                                        <Image height={40} preview={false} src={customer} style={{ width: '45px' }} />
                                        : ''}
                                </Col>

                                <Col xs={2} xl={1} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '10px' }}>
                                    <div>{card.id}</div>
                                </Col>

                                <Col xs={14} xl={18} onClick={() => { sendSound(card.startTime) }} id={card.startTime} className={card.masking} style={{ marginLeft: '5px' }}>
                                    {card.masking === 'true' ?
                                        <Tooltip title={card.message} trigger="hover" overlayStyle={{ maxWidth: '70%' }}>
                                            <Card className='text-sm draggableItem' type="inner" style={{ fontSize: "16px", marginTop: 10, borderStyle: "solid", borderColor: '#d9d9d9', color: 'black', backgroundColor: '#fbf35524' }} >
                                                <div>{card.maskingMessage} </div>
                                            </Card>
                                        </Tooltip>
                                        :
                                        <Card className='text-sm draggableItem' type="inner" style={{ fontSize: "16px", marginTop: 10, borderStyle: "solid", borderColor: '#d9d9d9', color: "black" }} >
                                            <div>{card.message} </div>
                                        </Card>
                                    }
                                </Col>

                                <Col xs={3} xl={2} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', paddingTop: '8px', paddingRight: 'unset !important', pointerEvents: 'none' }} >
                                    <Checkbox key={card.id} onChange={(() => onChangeMasking(card.id))} style={{ fontSize: "14px", marginTop: "10px", color: "black", width: "100%", textAlign: "center" }} defaultChecked={card.answer}>Answer</Checkbox>
                                </Col>
                            </Row>
                        ))}
                    </div>
                    <div style={{ justifyContent: 'end', display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <Dropdown menu={{ items }}>
                            <Button type="primary" style={{ width: 'fit-content' }}>Download</Button>
                        </Dropdown>
                    </div>
                </div>

                : <div style={{ height: '400px', display: 'grid', alignItems: 'center' }}><Empty /></div>
            }
        </Card>
    );
};

export default Board;
