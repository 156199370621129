import { DownOutlined, SwapOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Image,
  MenuProps,
  Modal,
  Radio,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useState } from "react";

type Props = {
  isModalOpen: any;
  setIsModalOpen: any;
};

const ModalScene = ({ isModalOpen, setIsModalOpen }: Props) => {
  const [valueChoice, setValueChioce] = useState("1");
  const [data, setData] = useState([
    {
      time: "00.00.02",
      relevance: "98.07",
      text: "มึงคิดว่ามึงเป็นใคร กูไม่ค่อยกลัวมึงเท่าไหร่หรอกนะจะบอกให้",
    },
    {
      time: "00.00.02",
      relevance: "98.07",
      text: "มึงคิดว่ามึงเป็นใคร กูไม่ค่อยกลัวมึงเท่าไหร่หรอกนะจะบอกให้",
    },
    {
      time: "00.00.02",
      relevance: "98.07",
      text: "มึงคิดว่ามึงเป็นใคร กูไม่ค่อยกลัวมึงเท่าไหร่หรอกนะจะบอกให้",
    },
    {
      time: "00.00.02",
      relevance: "98.07",
      text: "มึงคิดว่ามึงเป็นใคร กูไม่ค่อยกลัวมึงเท่าไหร่หรอกนะจะบอกให้",
    },
    {
      time: "00.00.02",
      relevance: "98.07",
      text: "มึงคิดว่ามึงเป็นใคร กูไม่ค่อยกลัวมึงเท่าไหร่หรอกนะจะบอกให้",
    },
    {
      time: "00.00.02",
      relevance: "98.07",
      text: "มึงคิดว่ามึงเป็นใคร กูไม่ค่อยกลัวมึงเท่าไหร่หรอกนะจะบอกให้",
    },
  ]);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item
        </a>
      ),
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      width: "25%",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Relevance (%)",
      dataIndex: "relevance",
      key: "relevance",
      align: "center",
      width: "25%",
    },
    {
      title: (
        <Row justify="end">
          <Dropdown menu={{ items }} placement="bottomRight">
            <Button style={{ fontWeight: "600" }}>
              <SwapOutlined rotate={90} />
              Sort by <DownOutlined />
            </Button>
          </Dropdown>
        </Row>
      ),
      dataIndex: "text",
      key: "text",
    },
  ];

  return (
    <Modal
      closable={false}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={850}
    >
      <Row gutter={32}>
        <Col xs={10}>
          <Row>{valueChoice == "1" ? "Text" : "Object"}</Row>
          <Row style={{ marginTop: "30px" }}>
            {/* <Image /> */}
            <div
              style={{ width: "100%", background: "gray", height: "200px" }}
            ></div>
          </Row>
        </Col>
        <Col xs={14}>
          <Row>
            <Radio.Group
              onChange={(e) => setValueChioce(e.target.value)}
              value={valueChoice}
              buttonStyle="solid"
              style={{ marginBottom: 8, width: "100%" }}
            >
              <Radio.Button style={{ width: "50%" }} value="1">
                <Row justify="center">Text</Row>
              </Radio.Button>
              <Radio.Button style={{ width: "50%" }} value="2">
                <Row justify="center">Object</Row>
              </Radio.Button>
            </Radio.Group>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col
              xs={24}
              style={{
                background: "#E2E2E8",
                height: "450px",
                padding: "24px 24px",
              }}
            >
              <h2 style={{ marginBottom: "10px" }}>Topic</h2>
              {valueChoice == "1" ? (
                <Row>
                  <Col>
                    <Space size={[0, 8]} wrap>
                      <Tag color="magenta">magenta</Tag>
                      <Tag color="red">red</Tag>
                      <Tag color="volcano">volcano</Tag>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Row>
                      {Array(5).map((d: any) => {
                        return <Col xs={6}>test</Col>;
                      })}
                    </Row>
                  </Col>
                  <Col xs={24} style={{ marginTop: "10px" }}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      scroll={{ y: 250 }}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={24}>
                    <Space size={[0, 8]} wrap>
                      <Tag color="magenta">magenta</Tag>
                      <Tag color="red">red</Tag>
                      <Tag color="volcano">volcano</Tag>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Row justify="end">
                      <Dropdown menu={{ items }} placement="bottomRight">
                        <Button>
                          <SwapOutlined rotate={90} />
                          Sort by <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Row>
                  </Col>
                  <Col xs={24} style={{ marginTop: "10px" }}>
                    {/* <Row>
          {data.map((d: any) => {
            return (
              <Col flex="0 1 33%" style={{ padding: "4px", width: "200px" }}>
                <div style={{ backgroundColor: "gray", height: "120px" }}>
                  <Row
                    style={{ height: "100%" }}
                    justify="center"
                    align="middle"
                  >
                    Image
                  </Row>
                </div>
              </Col>
            );
          })}
        </Row> */}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalScene;
