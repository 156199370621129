import config from "../config/config";
import { getToken } from "../utils/prefix"

  // export async function tesk_createAPI(data) {
  //   const res = await fetch(config.api_backend + `/task/create`, {
  //     body: JSON.stringify(data),
  //     method: "POST",
  //     headers: {
  //     "x-access-token": getToken(),
  //       "Content-type": "application/json",
  //     },
  //   });
  
  //   const dataRespone = await res.json();
  //   return dataRespone;
  // }
  export async function task_create(data) {
    const res = await fetch(config.api_backend + `/task/create`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function task_update(data) {
    const res = await fetch(config.api_backend + `/task/update`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function task_delete(data) {
    const res = await fetch(config.api_backend + `/task/delete`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function get_task(data) {
    const res = await fetch(config.api_backend + `/task/getTaskById`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function get_original_transcribe(data) {
    const res = await fetch(config.api_backend + `/task/getTranscribe`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  
  export async function get_subtitle(data) {
    const res = await fetch(config.api_backend + `/task/getTranscribe`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function get_transcribe(data) {
    const res = await fetch(config.api_backend + `/task/getMaskResult`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function get_all_task(data) {
    const res = await fetch(config.api_backend + `/task/all`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function get_filter_task(data) {
    const res = await fetch(config.api_backend + `/task/getTask`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function save_task_status(data) {
    const res = await fetch(config.api_backend + `/task/updateTaskStatus`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }


  export async function save_task_files(
    project_id,
    data,
    task_name,
  ) {
    var formdata = new FormData();
    console.log(formdata);
    formdata.append('file', data);
    formdata.append('task_name', task_name);
    formdata.append('project_id', project_id);
    console.log(data, "",task_name,"", project_id,"", formdata);

    const res = await fetch(config.api_backend + `/task/uploadTaskFile`, {
      body: formdata,
      method: "POST",
      headers: {
        // Authorization: getToken(),
        // "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function test_masking(data) {
    const res = await fetch(config.api_dialog + `/masking`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "x-access-token": config.dialog_token,
        "Content-type": "application/json",
        "mode":"test"
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function test_analyze(data) {
    const res = await fetch(config.api_dialog + `/analyze`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "x-access-token": config.dialog_token,
        "Content-type": "application/json",
        "mode":"test"
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function save_task_answer(data) {
    const res = await fetch(config.api_backend + `/task/saveAnswerJson`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  
  export async function save_transcribe(data) {
    console.log("save transcribe: ",data);
    const res = await fetch(config.api_backend + `/task/saveTranscribe`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function save_original_transcribe(data) {
    const res = await fetch(config.api_backend + `/task/saveOriginalTranscribe`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }


  export async function get_task_answer(data) {
    const res = await fetch(config.api_backend + `/task/getAnswerJson`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function findTask(data) {
    const res = await fetch(config.api_backend + `/task/findTask`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function evaluate_task(data) {
    const res = await fetch(config.api_dialog + `/evaluate`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "x-access-token": config.dialog_token,
        "Content-type": "application/json",
        "mode":"test"
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function update_task_acc(data) {
    const res = await fetch(config.api_backend + `/task/updateTaskAcc`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "x-access-token": config.dialog_token,
        "Content-type": "application/json"
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function findStatusTask(data) {
    const res = await fetch(config.api_backend + `/task/findStatusTask`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function updateDateTask(data) {
    const res = await fetch(config.api_backend + `/task/updateDateTask`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "x-access-token": config.dialog_token,
        "Content-type": "application/json"
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function filterTaskByDate(data) {
    const res = await fetch(config.api_backend + `/task/filterTaskByDate`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function get_graph_pie(data) {
    const res = await fetch(config.api_backend + `/task/getGraphData`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function get_graph(data) {
    const res = await fetch(config.api_backend + `/task/getGraphData`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function get_graph_daily(data) {
    const res = await fetch(config.api_backend + `/task/getGraphDataDaily`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function get_graph_monthly(data) {
    const res = await fetch(config.api_backend + `/task/getGraphDataMonth`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function text_tran(data) {
    console.log("Body: ",data);
    const res = await fetch(config.api_texttran + `/text/transform/string`, {
      body: data,
      method: "POST",
      headers: {
        Authorization: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmciOiJhaTkiLCJzZXJ2aWNlIjoic3lzdGVtIiwiaWF0IjoxNjAwMDkxMjUxLCJleHAiOjQxMzE2MjcyNTF9.ZrODaGffnS1u10wCjIYbXV-2sCfT6BkN4hgZxOMgiJo",
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function save_graph_data(data) {
    const res = await fetch(config.api_backend + `/task/saveGraphData`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }

  export async function update_graph_data(data) {
    const res = await fetch(config.api_backend + `/task/updateGraphData`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }
  
  export async function get_graph_record(data) {
    console.log("graph body: ",data);
    const res = await fetch(config.api_backend + `/task/getGraphRecord`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
      //   Authorization: getToken(),
        "Content-type": "application/json",
      },
    });
  
    const dataRespone = await res.json();
    return dataRespone;
  }