import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  Tag,
  Form,
  Radio,
  RadioChangeEvent,
  Switch,
  Tooltip,
  Select,
} from "antd";
import moment from "moment";
import {
  BarChartOutlined,
  CarryOutFilled,
  CarryOutOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  HddFilled,
  PlusOutlined,
  ProfileOutlined,
  ReadFilled,
  SafetyCertificateFilled,
  SafetyCertificateOutlined,
  SettingFilled,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import Search from "antd/es/input/Search";
import { get_all_org } from "../../services/org";
import { get_all_project, project_create, project_delete, project_update } from "../../services/project";
import { get_role } from "../../services/user";
import { useAuth } from "../../utils/Auth/AuthWrapper";

const { confirm } = Modal;

type Props = {};

const Project = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [projectData, setProjectData]: any = useState([]);
  const [orgData, setOrgData]: any = useState([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isCreate, setIsCreate] = useState<any>(true);
  const [role, setRole] = useState(1);
  const params = useParams();
  const { user } = useAuth();
  const [userPermission, setUserPermission]: any = useState([]);

  const onRadioChange = (e: RadioChangeEvent) => {
    // console.log('radio checked', e.target.value);
    setRole(e.target.value);
  };


  const columns: any = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: <div style={{ fontSize: "19px", textAlign: "center" }}>Name</div>,
      dataIndex: "name",
      key: "name",
      sorter: (a: any) => moment(a.name),
      render: (_: any, record: any) => {
        return <Row align="middle" gutter={16}>
          <Col
            style={{
              fontWeight: 'bolder',
              color: 'black',
            }}
          >

            {record.name}

          </Col>
        </Row>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>No. Of Task</div>,
      key: "task",
      align: "center",
      render: (_: any, record: any) => record.task_count,
    },
    // {
    //   title: <div style={{ fontSize: "18px", textAlign: "center" }}>No. Of Rule</div>,
    //   key: "org",
    //   align:"center",
    //   render: (_: any, record: any) => record.rule_no,
    // },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Last Update</div>,
      key: "last_login",
      render: (_: any, record: any) => {
        return <div>{moment(record.lasted_login).calendar()}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Action</div>,
      dataIndex: "",
      align: "center",
      key: "tags",
      render: (_: any, record: any) => {
        console.log(user.role);

        return (
          <Row gutter={16} justify={"center"}>
             {user.role == "Administrator" && (<Tooltip placement="bottom" title="Dashboard">
              <Col
                style={{ color: "#3f8dc4", cursor: "pointer" }}
                onClick={() => {

                  // getFocusProject(record);
                  // setIsCreate(false);
                  // setIsModalOpen(true);
                  // if( record.rule_no == 0){
                  //   Modal.warning({
                  //     title: 'Cannot Upload Task!',
                  //     content: `You should config project rule first!`,
                  //   });
                  // }else{
                  navigate("/dashboard/" + record.project_id);
                  // }



                }
                }
              >
                {/* <img src="/task_icon.png" style={{ color: "#3f8dc4", fontSize: "18px",width:"18px",marginTop:"-7px" }} /> */}
                <BarChartOutlined style={{ color: "rgb(145 94 197)", fontSize: "18px", width: "18px", marginTop: "-7px" }} />
              </Col>
            </Tooltip>)}
        
            <Tooltip placement="bottom" title="Task">
              <Col
                style={{ color: "#3f8dc4", cursor: "pointer" }}
                onClick={() => {

                  // getFocusProject(record);
                  // setIsCreate(false);
                  // setIsModalOpen(true);
                  // if( record.rule_no == 0){
                  //   Modal.warning({
                  //     title: 'Cannot Upload Task!',
                  //     content: `You should config project rule first!`,
                  //   });
                  // }else{
                  navigate("/task/" + record.project_id);
                  // }



                }
                }
              >
                {/* <img src="/task_icon.png" style={{ color: "#3f8dc4", fontSize: "18px",width:"18px",marginTop:"-7px" }} /> */}
                <ReadFilled style={{ color: "#60b372", fontSize: "18px", width: "18px", marginTop: "-7px" }} />
              </Col>
            </Tooltip>
            {user.role == "Administrator" && (<Tooltip placement="bottom" title="Config">
              <Col
                style={{ color: "#3f8dc4", cursor: "pointer" }}
                onClick={() => {

                  navigate("/project/" + record.project_id)


                }
                }
              >
                <SettingFilled style={{ color: "#3f8dc4", fontSize: "18px" }} />
              </Col>
            </Tooltip>)}

            {/* {user.role == "Administrator" && (<Tooltip placement="bottom" title="Config">
              <Col
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {

                  navigate("/rule/" + record.project_id)


                }
                }
              >
                <SettingFilled style={{ color: "red", fontSize: "18px" }} />
              </Col>
            </Tooltip>)} */}

            <Tooltip placement="bottom" title="Edit">
              <Col
                style={{ color: "#989898", cursor: "pointer" }}
                onClick={() => {

                  getFocusProject(record);
                  setIsCreate(false);
                  setIsModalOpen(true);


                }
                }
              >
                <EditFilled style={{ color: "#f3c62b", fontSize: "18px" }} />
              </Col>
            </Tooltip>
            {user.role == "Administrator" && (<Tooltip placement="bottom" title="Delete">
              <Col
                style={{ color: "#989898", cursor: "pointer" }}
                onClick={() => {
                  if (record.role_id == "99999999-0000-0000-0000-999999999999") {
                    Modal.error({
                      title: 'Permission Denined!',
                      content: `Not allow to edit Super Admin's data.`,
                    });
                  } else {
                    showDeleteConfirm(record.project_id);
                  }
                }}
              >
                <DeleteFilled style={{ color: "#d01919", fontSize: "18px" }} />
              </Col>
            </Tooltip>)}
          </Row>
        )
      },
    },
  ];

  const getFocusProject = async (record: any) => {
    // console.log("get current project :", record);

    form.setFieldsValue({
      id: record.project_id,
      org: record.org_id,
      name: record.name
    });

  }

  useEffect(() => {
    // getProjectData();
    getOrgData();
    getData();
  }, []);

  const getOrgData = async () => {
    try {
      const res = await get_all_org();
      // console.log("res get org :", res.result);
      setOrgData(res.result);
    } catch (e) {
      console.error(e);
    }
  };

  const getProjectData = async () => {
    try {
      const res = await get_all_org();
      // console.log("res get org :", res.result);
      setOrgData(res.result);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    try {
      const role = await get_role();
      // console.log("res get role :", role.result);

      const res = await get_all_project({ org_id: role.result[0].org_id });
      // console.log("res get project :", res.result);
      setProjectData(res.result);
      setUserPermission(role.result[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Are you sure to delete this project?",
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // console.log("delete row:", id);

        try {
          const res = await project_delete({ data: { project_id: id } });
          getData();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const handleCreateProject = async (value: any) => {
    try {

      if (userPermission.role_name !== "Super Admin") {
        form.setFieldsValue({
          org: userPermission.org_id,
        });
      }

      const res = await project_create({
        data: {
          name: form.getFieldValue('name'),
          org_id: form.getFieldValue('org'),
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateProject = async (value: any) => {
    try {

      const res = await project_update({
        data: {
          project_id: form.getFieldValue('id'),
          name: form.getFieldValue('name'),
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSearch = (value: string) => console.log(value);

  return (
    <div className="content-container">
      <Row>
        <h2 style={{ fontSize: "20px", color: "black" }}>Project</h2>
      </Row>
      <div style={{ marginBottom: "2%", float: "right" }}>
        <Search placeholder="Search Project" onSearch={onSearch} style={{ width: 300, marginRight: "8px" }} />
        {/* <Button
          type="primary"
          style={{ borderRadius: "5px", width: "180px" }}
          onClick={() => {
            form.resetFields();
            setIsCreate(true);
            setIsModalOpen(true);
          }}
        >
          <PlusOutlined /> New Project
        </Button> */}
      </div>

      <div className="table-project">
        <Table
          rowClassName="ProjectTable"
          rowKey="project_id"
          columns={columns}
          dataSource={projectData}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }}
        />


        <Modal
          title={isCreate ? "New Project" : "Edit Project Data"}
          open={isModalOpen}
          // onOk={handleOk}
          onOk={() => form.submit()}
          onCancel={() => setIsModalOpen(false)}
          width={600}
        >
          <div>
            <Form
              labelCol={{ flex: '110px' }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              form={form} onFinish={isCreate ? handleCreateProject : handleUpdateProject}>
              <div style={{ marginBottom: "3%", marginTop: "3%" }}>
                <Form.Item
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input
                    placeholder="id"
                  />
                </Form.Item>

                <Form.Item
                  name="org"
                  label="Organization"
                  rules={[
                    { required: userPermission.role_name !== "Super Admin" ? false : true, message: "Please input your organization name!" },
                  ]}
                  style={{ display: userPermission.role_name !== "Super Admin" ? "none" : "" }}
                >
                  <Select
                    showSearch
                    placeholder="Organization Name"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    options={(orgData || []).map((v: any) => ({
                      value: v.org_id,
                      label: v.name,
                    }))}

                    defaultValue={userPermission.role_name !== "Super Admin" ? userPermission.org_id : null}
                  />


                </Form.Item>

                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >

                  <Input
                    placeholder="Fullname"
                    autoComplete="new-name"
                  />

                </Form.Item>

              </div>
            </Form>
          </div>
        </Modal>
      </div>

    </div>
  );
};

export default Project;
