//import element
import User from "../views/user";
import Org from "../views/org";
import Project from "../views/project";
import Rule from "../views/rule";
import Task from "../views/task";
import Tag from "../views/tag";
import Group from "../views/group";
import ConfigRule from "../views/configRule";
import ConfigProject from "../views/configProject";
import ConfigProjectJson from "../views/configProjectJson";
import ConfigRuleJson from "../views/configProjectJson";
import Profile from "../views/profile";
import ConfigTask from "../views/configTask";
import TestTask from "../views/testTask";

import AllVideoPage from "../views/videos/AllVideo";
import MyVideoPage from "../views/videos/MyVideo";
import VideoPage from "../views/videos/VideoDetail";

import Dashboard from "../views/dashboard";
import ModelPage from "../views/settings/Model"
import Scenario from "../views/settings/Scenario";
import EditTable from "../views/settings/EditTable";

import { BankOutlined, BookOutlined, ContainerOutlined, BarChartOutlined, FolderOpenOutlined, NumberOutlined, OrderedListOutlined, TagOutlined, UnorderedListOutlined, UserOutlined, SettingFilled } from "@ant-design/icons";
import { Link, Navigate } from "react-router-dom";



export const route = [
  {
    path: '/organization',
    name: 'Organization',
    icon: <BankOutlined />,
    access: ['Super Admin'],
    showMenu: true,
    component: <Org />,
  },
  {
    path: '/user',
    name: 'User',
    icon: <UserOutlined />,
    access: ['Super Admin','Administrator'],
    showMenu: true,
    component: <User />,
  },
  {
    path: '/profile',
    name: 'Profile',
    // icon: <UserOutlined />,
    access: ['Super Admin', 'Administrator', "Staff"],
    showMenu: false,
    component: <Profile />,
  },


  {
    path: '/project',
    name: 'Project',
    icon: <UnorderedListOutlined />,
    access: ['Administrator', "Staff"],
    showMenu: true,
    component: <Project />,
  },
  // {
  //   path: '/task',
  //   name: 'Task',
  //   icon: <SettingFilled />,
  //   access: ['Administrator', "Staff"],
  //   showMenu: true,
  //   component: <Navigate to="/task/a9321045-ef42-4195-98c6-d5427ca324ee" />
  // },
  // {
  //   path: '/rule',
  //   name: 'Rule',
  //   icon: <BookOutlined />,
  //   access: ['Administrator', "Staff"],
  //   showMenu: true,
  //   component: <Navigate to="/project/a9321045-ef42-4195-98c6-d5427ca324ee" />
  // },

  // {
  //   path: '/rule',
  //   name: 'Rule',
  //   icon: <BookOutlined />,
  //   access: ['Administrator',"Staff"],
  //   showMenu: true,
  //   component: <Rule />,
  // },

  // {
  //   path: '/tag',
  //   name: 'Tag',
  //   icon: <TagOutlined />,
  //   access: ['Administrator',"Staff"],
  //   showMenu: true,
  //   component: <Tag />,
  // },
  {
    path: '/project/:project_id',
    name: 'Project',
    icon: <UnorderedListOutlined />,
    access: ['Administrator', "Staff"],
    showMenu: false,
    component: <ConfigRuleJson/>,
  },

  {
    path: '/task/:project_id',
    name: 'Task',
    icon: <OrderedListOutlined />,
    access: ['Administrator', "Staff"],
    showMenu: false,
    component: <Task />,
  },
  {
    path: '/dashboard/:project_id',
    name: 'Dashboard',
    icon: <BarChartOutlined />,
    access: ['Administrator', "Staff"],
    showMenu: false,
    component: <Dashboard />,
  },
  // {
  //   path: '/group',
  //   name: 'Group',
  //   icon: <FolderOpenOutlined />,
  //   access: ['Administrator',"Staff"],
  //   showMenu: true,
  //   component: <Group />,
  // },

  {
    path: '/file/:project_id/:task_id/:track_id',
    name: 'File',
    icon: <FolderOpenOutlined />,
    access: ['Administrator', "Staff"],
    showMenu: false,
    component: <ConfigTask />,
  },
  {
    path: '/test/:project_id/:task_id/:track_id',
    name: 'Test',
    icon: <FolderOpenOutlined />,
    access: ['Administrator', "Staff"],
    showMenu: false,
    component: <TestTask />,
  },

  {
    path: '/rule/:project_id',
    name: 'ConfigRule',
    icon: <BookOutlined />,
    access: ['Administrator',"Staff"],
    showMenu: false,
    component: <ConfigRule />,
  },
  {
    path: '/rule/json/:rule_id',
    name: 'ConfigRuleJson',
    icon: <BookOutlined />,
    access: ['Administrator',"Staff"],
    showMenu: false,
    component: <ConfigRuleJson />,
  },

]