import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  Tag,
  Form,
} from "antd";
import moment from "moment";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  createScenario,
  deleteScenario,
  getData_Scenario,
} from "../../../services/scenario";
import { selectedModel } from "../../../services/model";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

type Props = {};

const Scenario = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [scenarioData, setScenarioData]: any = useState([]);
  const [topic, setTopic]: any = useState([]);
  const [object, setObject]: any = useState([]);
  const [scene, setScene]: any = useState([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [resultScenario, setResultScenario] = useState<any>({
    text: [],
    object: [],
    scene: [],
  });

  const columns: any = [
    {
      title: "Scenario",
      dataIndex: "title",
      key: "title",
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) =>
        moment(a.created_timestamp).unix() - moment(b.created_timestamp).unix(),
    },
    {
      title: "Text",
      key: "text",
      align: "center",
      render: (_: any, record: any) => record.text[2].topic.length,
    },
    {
      title: "Object",
      key: "object",
      align: "center",
      render: (_: any, record: any) => record.text[0].object.length,
    },
    {
      title: "Scene",
      key: "scene",
      align: "center",
      render: (_: any, record: any) => record.text[1].scene.length,
    },
    {
      title: "Action",
      dataIndex: "",
      align: "center",
      key: "tags",
      render: (_: any, record: any) => (
        // <div style={{ textAlign: "center" }}>
        //   <a
        //     style={{ marginRight: "10%", color: "#989898" }}
        //     // onClick={() => {
        //     //   handleEdit(record.title, record.scenario_id);
        //     // }}
        //   >
        //     <EditFilled />
        //   </a>
        //   <a
        //     style={{ color: "#989898" }}
        //     // onClick={() => {
        //     //   showDeleteConfirm(record.scenario_id);
        //     // }}
        //   >
        //     <DeleteFilled />
        //   </a>
        // </div>
        <Row gutter={16} justify={"center"}>
          <Col
            style={{ color: "#989898", cursor: "pointer" }}
            //     // onClick={() => {
            //     //   handleEdit(record.title, record.scenario_id);
            //     // }}
            onClick={() =>
              navigate(`/setting/settingSce/${record.scenario_id}`)
            }
          >
            <EditFilled />
          </Col>
          <Col
            style={{ color: "#989898", cursor: "pointer" }}
            onClick={() => {
              showDeleteConfirm(record.scenario_id);
            }}
          >
            <DeleteFilled />
          </Col>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await getData_Scenario({});
      console.log("res get scenario :", res);
      setScenarioData(res);
    } catch (e) {
      console.error(e);
    }
  };

  const getDateModel = async () => {
    const resultText: any = await selectedModel({ model: "text" });
    sortData(resultText);

    const resultObject: any = await selectedModel({ model: "object" });
    sortData(resultObject);

    const resultScene: any = await selectedModel({ model: "scene" });
    sortData(resultScene);

    setTopic(resultText);
    setObject(resultObject);
    setScene(resultScene);
  };

  const sortData = async (data: any) => {
    return data.sort(
      (a: any, b: any) =>
        moment(a.created_timestamp).unix() - moment(b.created_timestamp).unix()
    );
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Are you sure delete this scenario?",
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        console.log("delete row:", id);

        try {
          const res = await deleteScenario({ scenario_id: id });
          getData();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCheckModel = async (
    values: any,
    topics: string,
    checked: any
  ) => {
    const result = {
      model_id: values.model_id,
      text: values.text,
      model_name: values.model_name,
      model_type: values.model_type,
    };

    if (topics == "text") {
      checked == false
        ? setResultScenario({
            ...resultScenario,
            text: resultScenario.text.filter(
              (d: any) => d.model_id != values.model_id
            ),
          })
        : setResultScenario({
            ...resultScenario,
            text: [...resultScenario.text, result],
          });
    } else if (topics == "object") {
      checked == false
        ? setResultScenario({
            ...resultScenario,
            object: resultScenario.object.filter(
              (d: any) => d.model_id != values.model_id
            ),
          })
        : setResultScenario({
            ...resultScenario,
            object: [...resultScenario.object, result],
          });
    } else {
      checked == false
        ? setResultScenario({
            ...resultScenario,
            scene: resultScenario.scene.filter(
              (d: any) => d.model_id != values.model_id
            ),
          })
        : setResultScenario({
            ...resultScenario,
            scene: [...resultScenario.scene, result],
          });
    }
  };

  const handleCreateScenario = async (value: any) => {
    try {
      const res = await createScenario({
        title: value.name,
        data: resultScenario,
      });

      if (res.message == "success") {
        getData();
        setResultScenario({
          text: [],
          object: [],
          scene: [],
        });
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="content-container">
      <Row>
        <h2>Scenario</h2>
      </Row>
      <div className="table-scenario">
        <Table
          rowClassName="ScenarioTable"
          rowKey="scenario_id"
          columns={columns}
          dataSource={scenarioData}
          pagination={false}
        />
      </div>

      <div style={{ marginTop: "2%", float: "right" }}>
        <Button
          type="primary"
          style={{ borderRadius: "5px", width: "180px" }}
          onClick={() => {
            getDateModel();
            setIsModalOpen(true);
            // showModal();
            // getTextModel();
          }}
        >
          <PlusOutlined /> Add scenario
        </Button>

        <Modal
          title="Scenario"
          open={isModalOpen}
          // onOk={handleOk}
          onOk={() => form.submit()}
          onCancel={() => setIsModalOpen(false)}
          width={830}
        >
          <div>
            <Form form={form} onFinish={handleCreateScenario}>
              <div style={{ marginBottom: "3%" }}>
                {/* <p>Scenario</p> */}
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input
                    placeholder="Scenario"
                    // value={valueNewScenario}
                    // onChange={(e) => {
                    //   setValueNewScenario(e.target.value);
                    // }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "flex" }}>
                <Row gutter={16}>
                  <Col>
                    <Card style={{ width: 250, height: "300px" }}>
                      <p style={{ marginTop: "-3%" }}>Text</p>
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={topic}
                        renderItem={(item: any, i) => (
                          <List.Item>
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                style={{
                                  color: "#E04D8B",
                                  padding: "0px",
                                  marginRight: "10%",
                                }}
                                // size="small"
                                onClick={(e: any) => {
                                  handleCheckModel(
                                    item,
                                    "text",
                                    e.target.checked
                                  );
                                }}
                              />
                              <Tag color="magenta" className="tagScenario">
                                {item.text}
                              </Tag>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ width: 250, height: "300px" }}>
                      <p style={{ marginTop: "-3%" }}>Object</p>
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={object}
                        renderItem={(item: any, i) => (
                          <List.Item>
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                style={{
                                  color: "#218AE7",
                                  padding: "0px",
                                  marginRight: "10%",
                                }}
                                // size="small"
                                onClick={(e: any) => {
                                  handleCheckModel(
                                    item,
                                    "object",
                                    e.target.checked
                                  );
                                }}
                              />
                              <Tag color="blue" className="tagScenario">
                                {item.text}
                              </Tag>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ width: 250, height: "300px" }}>
                      <p style={{ marginTop: "-3%" }}>Scene</p>
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={scene}
                        renderItem={(item: any, i) => (
                          <List.Item>
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                style={{
                                  color: "#42a219",
                                  padding: "0px",
                                  marginRight: "10%",
                                }}
                                // size="small"
                                onClick={(e: any) => {
                                  handleCheckModel(
                                    item,
                                    "scene",
                                    e.target.checked
                                  );
                                }}
                              />
                              <Tag color="green" className="tagScenario">
                                {item.text}
                              </Tag>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Scenario;
