import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  Form,
  Radio,
  RadioChangeEvent,
  Switch,
  Tooltip,
  Select,
  SelectProps,
} from "antd";
import moment from "moment";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  PlusOutlined,
  SettingFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { get_all_org } from "../../services/org";
import { get_all_tag, get_terms, tag_create, tag_delete, tag_update, terms_delete } from "../../services/tag";
import { get_role } from "../../services/user";
import { get_all_group } from "../../services/group";

const { confirm } = Modal;

type Props = {};

const Group = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [groupData, setGroupData]: any = useState([]);
  const [orgData, setOrgData]: any = useState([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isCreate, setIsCreate] = useState<any>(true);
  const [role, setRole] = useState(1);
  const [term, setTerm]: any = useState([]);
  const [userPermission, setUserPermission]: any = useState([]);

  const onRadioChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setRole(e.target.value);
  };


  const columns: any = [
    {
      title: <div style={{ fontSize: "19px", textAlign: "center" }}>Name</div>,
      dataIndex: "name",
      key: "name",
      sorter: (a: any) => moment(a.name),
      render: (_: any, record: any) => {
        return <Row align="middle" gutter={16}>
          <Col
            style={{
              fontWeight: 'bolder',
              color: 'black',
            }}
          >

            {record.name}

          </Col>
        </Row>;
      },
    },
    // {
    //   title: <div style={{ fontSize: "18px", textAlign: "center" }}>Terms</div>,
    //   key: "term",
    //   align: "center",
    //   render: (_: any, record: any) => record.terms_no,
    // },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Last Update</div>,
      key: "last_login",
      render: (_: any, record: any) => {
        return <div>{moment(record.lasted_login).calendar()}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Action</div>,
      dataIndex: "",
      align: "center",
      key: "tags",
      render: (_: any, record: any) => (
        <Row gutter={16} justify={"center"}>
          <Tooltip placement="bottom" title="Edit">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {

                getFocusGroup(record);
                setIsCreate(false);
                setIsModalOpen(true);


              }
              }
            >
              <EditFilled style={{ color: "#f3c62b", fontSize: "18px" }} />
            </Col>
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {
                if (record.role_id == "99999999-0000-0000-0000-999999999999") {
                  Modal.error({
                    title: 'Permission Denined!',
                    content: `Not allow to edit Super Admin's data.`,
                  });
                } else {
                  showDeleteConfirm(record.tag_id);
                }
              }}
            >
              <DeleteFilled style={{ color: "#d01919", fontSize: "18px" }} />
            </Col>
          </Tooltip>
        </Row>
      ),
    },
  ];

  const getFocusGroup = async (record: any) => {
    console.log("get current Group :", record);

    const results = await get_terms({ data: { tag_id: record.tag_id } });

    console.log("terms in tag: ", results.result);

    let terms = [];
    for (let a in results.result) {
      terms.push(results.result[a].name);
    }

    console.log("terms in tag: ", terms);

    form.setFieldsValue({
      id: record.tag_id,
      org: record.org_id,
      name: record.name,
      terms: terms
    });

  }

  useEffect(() => {
    getOrgData();
    getData();
  }, []);

  const getOrgData = async () => {
    try {
      const res = await get_all_org();
      console.log("res get org :", res.result);
      setOrgData(res.result);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    try {
      const role = await get_role();
      console.log("res get role :", role.result);

      const res = await get_all_group({org_id: role.result[0].org_id});
      console.log("res get Group :", res.result);
      setGroupData(res.result);
      setUserPermission(role.result[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Are you sure to delete this Group?",
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        console.log("delete row:", id);

        try {
          const res = await tag_delete({ data: { tag_id: id } });
          getData();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCreateGroup = async (value: any) => {
    try {
      console.log("term", term);

      console.log("term length", term.length);

      if (userPermission.role_name !== "Super Admin") {
        form.setFieldsValue({
          org: userPermission.org_id,
        });
      }
      
      const res = await tag_create({
        data: {
          name: form.getFieldValue('name'),
          org_id: form.getFieldValue('org'),
          terms_no: term.length,
          terms: term
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateGroup = async (value: any) => {
    try {
      console.log("term", term);

      console.log("term length", term.length);

      const res2 = await terms_delete({
        data: {
          tag_id: form.getFieldValue('id'),
        },
      });

      const res = await tag_update({
        data: {
          tag_id: form.getFieldValue('id'),
          name: form.getFieldValue('name'),
          terms_no: term.length,
          terms: term
        },
      });


      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSearch = (value: string) => console.log(value);

  const handleChange = (value: string | string[]) => {
    console.log(`Selected: ${value}`);
    setTerm(value);
  };

  const options: SelectProps['options'] = [];

  return (
    <div className="content-container">
      <Row>
        <h2 style={{ fontSize: "32px" }}>Group</h2>
      </Row>
      <div style={{ marginBottom: "2%", float: "right" }}>
        <Search placeholder="Search Group" onSearch={onSearch} style={{ width: 300, marginRight: "50px" }} />
        <Button
          type="primary"
          style={{ borderRadius: "5px", width: "180px" }}
          onClick={() => {
            form.resetFields();
            setIsCreate(true);
            setIsModalOpen(true);
          }}
        >
          <PlusOutlined /> New Group
        </Button>
      </div>

      <div className="table-Group">
        <Table
          rowClassName="GroupTable"
          rowKey="group_id"
          columns={columns}
          dataSource={groupData}
          pagination={{
            pageSize: 5,
          }}
        />


        <Modal
          title={isCreate ? "New Group" : "Edit Group Data"}
          open={isModalOpen}
          // onOk={handleOk}
          onOk={() => form.submit()}
          onCancel={() => setIsModalOpen(false)}
          width={600}
        >
          <div>
            <Form
              labelCol={{ flex: '110px' }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              form={form} onFinish={isCreate ? handleCreateGroup : handleUpdateGroup}>
              <div style={{ marginBottom: "3%", marginTop: "3%" }}>
                <Form.Item
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input
                    placeholder="id"
                  />
                </Form.Item>

               <Form.Item
                  name="org"
                  label="Organization"
                  rules={[
                    { required: userPermission.role_name !== "Super Admin" ? false : true, message: "Please input your organization name!" },
                  ]}
                style={{ display: userPermission.role_name !== "Super Admin"?"none":""}}
                >
                  <Select
                    showSearch
                    placeholder="Organization Name"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    options={(orgData || []).map((v: any) => ({
                      value: v.org_id,
                      label: v.name,
                    }))}

                    defaultValue={userPermission.role_name !== "Super Admin" ? userPermission.org_id : null}
                  />


                </Form.Item>

                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >

                  <Input
                    placeholder="Group Name"
                    autoComplete="new-name"
                  />

                </Form.Item>

                {/* <Form.Item
                  name="terms"
                  label="Terms"
                // rules={[
                //   { required: true, message: "Please input your name!" },
                // ]}
                >

                  <Select
                    mode="tags"
                    placeholder="Add..."
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    options={options}
                  />

                </Form.Item> */}

              </div>
            </Form>
          </div>
        </Modal>
      </div>

    </div>
  );
};

export default Group;
