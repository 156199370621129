import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {

  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  message,

} from "antd";
import { useParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { delete_project_rule, gen_project_token, get_project, get_project_rule, save_project_rule, update_project_rule } from "../../services/project";
// Default SortableJS
import Sortable, { MultiDrag, Swap } from 'sortablejs';
import { ReactSortable } from "react-sortablejs";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import Board from "./components/Board";
import { get_all_rule } from "../../services/rule";
import { get_role } from "../../services/user";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

type Props = {};

const ConfigProject = (props: Props) => {
  const navigate = useNavigate();
  const [projectData, setProjectData]: any = useState({});
  const [rulelist, setRuleList]: any = useState([]);
  const [projectrulelist, setProjectRuleList]: any = useState([]);
  const [role, setRole]: any = useState([]);

  const params = useParams();

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'This is a success message',
    });
  };

  useEffect(() => {
    getData();
    // getProjectRule();
    getRuleData();
   
  }, []);

  const getData = async () => {
    try {
      const res = await get_project({ data: { project_id: params.project_id } });
      console.log("res get project :", res.data[0]);
      setProjectData(res.data[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const getRuleData = async () => {
    try {
      const role = await get_role();
      console.log("res get role :", role.result);

      const res = await get_all_rule({ org_id: role.result[0].org_id });

      const res2 = await get_project_rule({ data: { project_id: params.project_id } });
      console.log("res get project rule :", res2.data);
    

      let list = res.result;
      let list2 = res2.data;

      console.log("res get rule :", res.result,list,list2);

      let rule = list.filter((n: any) => {
        return !list2.some((n2: any) => n.id == n2.id)
      })

      console.log("all rule: ",rule);
      
      setRuleList(rule);
      if (res2.data[0]) {
        console.log("set project rule :", res2.data);
        setProjectRuleList(res2.data);
      }

      setRole(role.result[0]);
    } catch (e) {
      console.error(e);
    }
  };


  const saveProjectRule = async () => {
    try {
      const res = await delete_project_rule({ data: { project_id: params.project_id } });
      console.log("res get delete project :", res.data[0]);
 
      let idx=0;
      projectrulelist?.map(async (items: any) => {
        idx++;
        console.log("data: ", items,idx);
        const res2 = await save_project_rule({ data: { project_id: params.project_id,rule_id:items.rule_id,idx:idx } });
        console.log("res get save project :", res2.data[0]);

      });

      const res3 = await update_project_rule({ data: { project_id: params.project_id,rule_no:projectrulelist.length } });
      console.log("res update project :", res3.data[0]);

    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className="content-container">
      {/* <Breadcrumb
    items={[
    
      {
        title: <a href="/rule" style={{ fontSize: "32px" }}>Rule</a>,
      },
      {
        title: <div style={{ fontSize: "32px" }}>Config Rule</div>,
      },
    ]}
  /> */}

      <Row gutter={16}>

        {/* <Button onClick={() => {
           Modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            content: "You won't be able to revert this!",
            okText: 'Yes, close!',
            cancelText: 'Cancel',
            onOk: async () => {
              window.location.href = "/rule";
            }
          });
        
        }} style={{ width: "75px", height: "40px", marginBottom: "20px",background:"#cc4474",color:"white" }}>← Back</Button> */}
        <h2 onClick={() => {
          Modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            content: "You won't be able to revert this!",
            okText: 'Yes, close!',
            cancelText: 'Cancel',
            onOk: async () => {
              // window.location.href = "/project";
              navigate("/project")
            }
          });

        }} style={{ fontSize: "32px", marginLeft: "15px", marginBottom: "35px", cursor: "pointer" }}>Project   </h2>
        <h2 style={{ fontSize: "32px", marginLeft: "15px", marginBottom: "35px" }}>&gt;</h2>
        <h2 style={{ fontSize: "32px", marginLeft: "15px", marginBottom: "35px", color: "black" }}> {projectData.name}</h2>
      </Row>
      <Row gutter={16}>

        <Col span={12}>
          <Board title="Rule" height="582px" lists={rulelist} setLists={setRuleList} color="#E2EEFF" text="#4285EB" info="#5578B9" search={true} />
        </Col>

        <Col span={12}>
          <Board title="Project" height="500px" lists={projectrulelist} setLists={setProjectRuleList} color="#E0FAE6" text="#53A078" info="#53A078" search={false} />
          <Card>
            <Row gutter={16}>
              <Col span={12}>
                <Button type="primary" style={{ background: "#31C277" }} onClick={async () => {
                  const res = await gen_project_token({ 
                    data: { 
                      org_code: role.org_code,
                      project_code: params.project_id,
                      fullname: role.user_name,
                      role: role.role_name
                    }
                   });
                  console.log("Project JWT: ",res);
                  localStorage.setItem(params.project_id+"_token",res.projectToken);
                }}>Launch</Button>
              </Col>
              <Col span={12} style={{ textAlign: "end" }}>
                <Button type="primary" style={{ background: "#1890FF" }} onClick={saveProjectRule}>Save</Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ConfigProject;
