import config from "../config/config";
// import { getToken } from "../utils/prefix";

export async function rule_create(data) {
  const res = await fetch(config.api_backend + `/rule/create`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function rule_update(data) {
  const res = await fetch(config.api_backend + `/rule/update`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function rule_delete(data) {
  const res = await fetch(config.api_backend + `/rule/delete`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_rule(data) {
  const res = await fetch(config.api_backend + `/rule/getRuleById`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_all_rule(data) {
  const res = await fetch(config.api_backend + `/rule/all`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}


export async function save_rule_status(data) {
  const res = await fetch(config.api_backend + `/rule/saveRuleStatus`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function save_rule_data(data) {
  const res = await fetch(config.api_backend + `/rule/saveRuleJson`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_rule_data(data) {
  const res = await fetch(config.api_backend + `/rule/getRuleJson`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_rule_data_by_version(data) {
  const res = await fetch(config.api_backend + `/rule/getRuleByVersion`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_all_rule_version(data) {
  const res = await fetch(config.api_backend + `/rule/getAllRuleVersion`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function deploy_rule(data) {
  console.log("path: ",data);
  const res = await fetch(config.api_dialog + `/project/deploy`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "x-access-token": config.dialog_token,
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function deploy_test_rule(data) {
  const res = await fetch(config.api_dialog + `/project/test`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
    //   Authorization: getToken(),
      "x-access-token": config.dialog_token,
      "Content-type": "application/json",
      "mode":"test"
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}