import React from "react";
import { useAuth } from "../utils/Auth/AuthWrapper";
import { Navigate } from "react-router-dom";

type Props = {};

const LandingPage = (props: Props) => {
  const auth = useAuth();
  console.log("auth", auth);
  return (
    <>
      {auth.user == null ? (
        <Navigate to="/user/login" replace={true} />
      ) : auth.user.role == "User" ? (
        <Navigate to="/project" replace={true} />
      ) : (
        <Navigate to="/project" replace={true} />
      )}
    </>
  );
};

export default LandingPage;
