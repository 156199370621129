import { DownOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, MenuProps, Row } from "antd";
import React, { useState } from "react";

type Props = {
  dataScenario: any;
  setisModalOpen: any;
};

const ScenarioResult = ({ dataScenario, setisModalOpen }: Props) => {
  const [data, setData] = useState<any>([{}, {}, {}, {}]);
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item
        </a>
      ),
    },
  ];

  return (
    <Row>
      <Col xs={24}>
        {/* <Row justify="end" style={{ width: "100%" }}>
          <Col>
            <Dropdown menu={{ items }} placement="bottomRight">
              <Button>
                <SwapOutlined rotate={90} />
                Sort by <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Row style={{ marginTop: "1rem" }} gutter={[16, 16]} wrap={true}>
          {dataScenario.map((d: any, ind: number) => {
            return (
              <Col
                key={ind}
                flex="0 1 33%"
                style={{ padding: "4px", width: "200px" }}
              >
                <div
                  onClick={() => setisModalOpen(true)}
                  style={{ backgroundColor: "gray", height: "120px" }}
                >
                  <Row
                    style={{ height: "100%" }}
                    justify="center"
                    align="middle"
                  >
                    Image
                  </Row>
                </div>
              </Col>
            );
          })}
        </Row> */}
      </Col>
    </Row>
  );
};

export default ScenarioResult;
