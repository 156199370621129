import {
  Layout,
  Row,
  Col,
  Dropdown,
  Avatar,
  Switch,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  Checkbox,
  UploadProps,
  message,
  Image,
} from "antd";
import {
  CloudUploadOutlined,
  InboxOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAuth } from "../utils/Auth/AuthWrapper";
import i18n from "i18next";
import { useRecoilState } from "recoil";
import { localeState } from "../utils/atoms";
import enUS from "antd/locale/en_US";
import thTH from "antd/locale/th_TH";
import { useEffect, useState } from "react";
import Manna from "../assets/images/logo/logo-manna.png";
import "./index.less";
// import UploadVideo from "../components/UploadVideo";
// import { tesk_createAPI } from "../services/task";
// import { showScenario } from "../services/scenario";
import moment from "moment";
import Profile from "../components/Profile";
import config from "../config/config";
// import { translate } from "../utils/libs/translate";
import { get_profile } from "../services/user";
import { useNavigate, useParams } from "react-router-dom";

interface UserData {
  user_name: string;
  org_name: string;
  user_email: string;
  profile_img: string;
  role_name: string;
}

type Props = {
  collapsed: boolean;
};

const HeaderLayout = ({ collapsed }: Props) => {
  const navigate = useNavigate();
  const { Header } = Layout;
  const auth = useAuth();
  // console.log("auth", auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [passwordFormVisible, setPasswordFormVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUser] = useState<UserData>({
    user_name: "",
    org_name: "",
    user_email: "",
    profile_img: "",
    role_name: "",
  });
  const [locale, setLocale] = useRecoilState(localeState);
  const [scenarioShow, setScenarioShow]: any = useState([]);

  const reqPic = config.api_backend + "/pic/";

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const res = await get_profile();
      const user = res.result;
      // console.log("res", user[0]);
      setUser(user[0]);
      // console.log("userData", userData);
    } catch (error) {
      console.log(error);
      message.error("Cannot get user data");
      localStorage.clear();
      navigate("/user/login");
    }
  };

  //Profile Form
  const [form] = Form.useForm();

  //profile function
  const handleProfile = () => {
    setIsProfileOpen(true);
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleProfile()}
        >
          {("Profile")}
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          // target="_blank"
          // rel="noopener noreferrer"
          // href="https://www.aliyun.com"
          onClick={() => auth.logout()}
        >
          {("Logout")}
        </a>
      ),
      // icon: <SmileOutlined />,
      // disabled: true,
    },
  ];

  const validateMessages = {
    required: "Please input your ${label}",
  };

  const onChangeLanguage = (checked: any) => {
    // if checked is false, French should be adopted
    if (!checked) {
      i18n.changeLanguage("thTH");
      setLocale(thTH);
    } else {
      i18n.changeLanguage("enUS");
      setLocale(enUS);
    }
  };

  //Profile handling
  const onClose = () => {
    setIsProfileOpen(false);
    setIsEdit(false);
    setPasswordFormVisible(false);
    form.resetFields();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (checkedValues: any) => {
    // console.log("checked = ", checkedValues);
  };


  return (
    <Header className="header-layout" style={{ background: "white" }}>
      <Row
        className="w-100 h-100"
        justify={"space-between"}
        align={"middle"}
        gutter={16}
        style={{ cursor: "pointer", height: "100%" }}
      >
        <Col>
          <Image src={Manna} height={60} preview={false} onClick={() => navigate('/project')} />
        </Col>
        <Col>
          <Row
            className="w-100 h-100"
            justify={"end"}
            align={"middle"}
            gutter={16}
            style={{ cursor: "pointer", height: "100%" }}
          >
            {/* <Col>
              <Switch
                checkedChildren="EN"
                unCheckedChildren="TH"
                defaultChecked
                onChange={onChangeLanguage}
              />
            </Col> */}
            <Col>
              <Row align={"middle"}>
                <Profile
                  form={form}
                  isProfileOpen={isProfileOpen}
                  onClose={onClose}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  passwordFormVisible={passwordFormVisible}
                  setPasswordFormVisible={setPasswordFormVisible}
                  userData={userData}
                  getProfile={getProfile}
                />
              </Row>
            </Col>
            <Col>
              <Row className="h-100 w-100" align={"middle"}>
                <Col>
                  <Dropdown menu={{ items }} placement="bottomRight">
                    <Row className="h-100 w-100" align={"middle"}>
                      <Avatar
                        src={userData ? userData.profile_img : null}
                        icon={<UserOutlined />}
                      />
                      <span style={{ color: "black", marginLeft: "10px" }}>
                        {/* Sittichai Saetiaw */}
                        {userData ? userData.user_name : null}
                      </span>
                    </Row>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderLayout;