import { Layout, Row, Col, Image, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
// import { items } from "../config/menu/configMenu";
import { getMenuSider, items } from "../config/menu/configMenu";
import { useEffect } from "react";




type Props = {
  collapsed: boolean;
  setCollapsed: any;
};

const SiderLayout = ({ collapsed, setCollapsed }: Props) => {
  const { Sider } = Layout;
  const location = useLocation();
  const navigate = useNavigate();
  let menutest:any = []

  const navigatePath = (data: any) => {
    navigate(data.keyPath[0]);
  };

  const getMenu = async() => {
    const res = getMenuSider()
    // console.log("get menu ::",res)
    menutest=res
  }
  useEffect(()=>{
    getMenu()
  },[])

  return (
    <Sider
      className="box-shadow"
      // style={{
      //   overflow: "auto",
      //   height: "100vh",
      //   position: "fixed",
      //   left: 0,
      //   top: 0,
      //   bottom: 0,
      //   borderRadius: "0 20px 20px 0",
      //   // background:"white",
      // }}
      theme="light"
      // style={{background:"#3C2A21"}}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      {/* <Row> */}
      {/* <div style={{width:"500"}}  > */}
      <Menu
        defaultSelectedKeys={[`${location.pathname}`]}
        // defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
        // style={{background:"#3C2A21",color:"white"}}
        // items={items}
        items={getMenuSider()}
        onClick={navigatePath}
      />

    </Sider>
  );
};

export default SiderLayout;

{
  /* <Row justify={"center"} align="middle" style={{ margin: 16 }}>
{collapsed === true ? (
  <Image src={"LogoImg"} preview={false} height={40} />
) : (
  <>
    <Image src={"LogoImg2"} preview={false} height={70} />

  </>
)}
</Row> */
}
