import React, { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  Tag,
  Form,
  Radio,
  RadioChangeEvent,
  Switch,
  Tooltip,
  Select,
} from "antd";
import moment from "moment";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get_all_org_user, get_all_user, get_role, get_user, save_user_status, user_create, user_delete, user_update } from "../../services/user";
import Search from "antd/es/input/Search";
import { get_all_org } from "../../services/org";

const { confirm } = Modal;

type Props = {};

const User = (props: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [userData, setUserData]: any = useState([]);
  const [orgData, setOrgData]: any = useState([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [isCreate, setIsCreate] = useState<any>(true);
  const [role, setRole] = useState(1);
  const [userPermission, setUserPermission]: any = useState([]);

  const onRadioChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setRole(e.target.value);
  };


  const columns: any = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: <div style={{ fontSize: "19px", textAlign: "center" }}>Name</div>,
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend",
      render: (_: any, record: any) => {
        return <Row align="middle" gutter={16}>
          <Col>
            <img
              src={
                record.profile_img === null
                  ? `/avatar.png`
                  : `${record.profile_img}`
              }
              style={{ width: '30px', height: '30px' }}
            />
          </Col>

          <Col
            style={{
              fontWeight: 'bolder',
              color: 'black',
            }}
          >

            {record.name}

          </Col>
        </Row>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Email</div>,
      key: "email",
      render: (_: any, record: any) => record.email,
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Organization</div>,
      key: "org",
      render: (_: any, record: any) => record.org_name,
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Role</div>,
      key: "role",
      render: (_: any, record: any) => record.role_name,
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Last Login</div>,
      key: "last_login",
      render: (_: any, record: any) => {
        return <div>{moment(record.lasted_login).calendar()}</div>;
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Active</div>,
      sorter: false,
      dataIndex: 'updatedAt',
      valueType: 'dateTime',
      align: 'center',
      render: (_: any, record: any) => {
        // console.log(record.status);

        if (record.status == 1) {
          return (
            <Switch
              onChange={async (checked: any) => {
                console.log(`switch to ${checked}`);

                if (checked) {
                  const res = await save_user_status({
                    data: {
                      status: 1,
                      user_id: record.user_id,
                    },
                  });
                  console.log(`switch to 1`);
                } else {
                  const res = await save_user_status({
                    data: {
                      status: 2,
                      user_id: record.user_id,
                    },
                  });
                  console.log(`switch to 2`);
                }
              }}
              defaultChecked
            />
          );
        }
        return (
          <Switch
            onChange={async (checked: any) => {
              console.log(`switch to ${checked}`);
              if (checked) {
                const res = await save_user_status({
                  data: {
                    status: 1,
                    user_id: record.user_id,
                  },
                });
                console.log(`switch to 1`);
              } else {
                const res = await save_user_status({
                  data: {
                    status: 2,
                    user_id: record.user_id,
                  },
                });
                console.log(`switch to 2`);
              }
            }}
          />
        );
      },
    },
    {
      title: <div style={{ fontSize: "18px", textAlign: "center" }}>Action</div>,
      dataIndex: "",
      align: "center",
      key: "tags",
      render: (_: any, record: any) => (
        <Row gutter={16} justify={"center"}>
          <Tooltip placement="bottom" title="Edit">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {
                if (record.role_id == "99999999-0000-0000-0000-999999999999") {
                  Modal.error({
                    title: 'Permission Denined!',
                    content: `Not allow to edit Super Admin's data.`,
                  });
                } else {
                  getFocusUser(record);
                  setIsCreate(false);
                  setIsModalOpen(true);
                }

              }
              }
            >
              <EditFilled style={{ color: "#f3c62b", fontSize: "18px" }} />
            </Col>
          </Tooltip>
          <Tooltip placement="bottom" title="Delete">
            <Col
              style={{ color: "#989898", cursor: "pointer" }}
              onClick={() => {
                if (record.role_id == "99999999-0000-0000-0000-999999999999") {
                  Modal.error({
                    title: 'Permission Denined!',
                    content: `Not allow to edit Super Admin's data.`,
                  });
                } else {
                  showDeleteConfirm(record.user_id);
                }
              }}
            >
              <DeleteFilled style={{ color: "#d01919", fontSize: "18px" }} />
            </Col>
          </Tooltip>
        </Row>
      ),
    },
  ];

  const getFocusUser = async (record: any) => {
    console.log("get current user :", record);

    form.setFieldsValue({
      id: record.user_id,
      role: record.role_id == "a8717515-be10-4e72-8d6d-da622284c2f4" ? 1 : 2,
      org: record.org_id,
      name: record.name,
      email: record.email,
      password: record.password
    });

  }

  useEffect(() => {
    getOrgData();
    getData();
  }, []);

  const getOrgData = async () => {
    try {
      const res = await get_all_org();
      console.log("res get org :", res.result);
      setOrgData(res.result);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    try {
     
      const role = await get_role();
      console.log("res get role :", role.result);
      let res;
      if(role.result[0].role_name !== "Super Admin"){
        res = await get_all_org_user({org_id:role.result[0].org_id});
      }else{
        res = await get_all_user();
      }
      console.log("res get user :", res.result);
      setUserData(res.result);
      setUserPermission(role.result[0]);
    } catch (e) {
      console.error(e);
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Are you sure to delete this user?",
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        console.log("delete row:", id);

        try {
          const res = await user_delete({ data: { user_id: id } });
          getData();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCreateUser = async (value: any) => {

    try {
      let role_id = "";
      console.log("user role: ", form.getFieldValue('role'));

      if (userPermission.role_name !== "Super Admin") {
        form.setFieldsValue({

          org: userPermission.org_id,

        });
      }
      console.log("user org: ", form.getFieldValue('org'));
      if (form.getFieldValue('role') == 1) {
        role_id = "a8717515-be10-4e72-8d6d-da622284c2f4";
      } else {
        role_id = "ecbf4355-952b-4cc2-8136-8353ca55b0d2";
      }

      console.log("user role id: ", role_id);

      const res = await user_create({
        data: {
          name: form.getFieldValue('name'),
          email: form.getFieldValue('email'),
          password: form.getFieldValue('password'),
          org_id: form.getFieldValue('org'),
          role_id: role_id,
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateUser = async (value: any) => {
    try {
      let role_id = "";
      console.log("user role: ", form.getFieldValue('role'));

      if (form.getFieldValue('role') == 1) {
        role_id = "a8717515-be10-4e72-8d6d-da622284c2f4";
      } else {
        role_id = "ecbf4355-952b-4cc2-8136-8353ca55b0d2";
      }

      console.log("user role id: ", role_id);

      const res = await user_update({
        data: {
          user_id: form.getFieldValue('id'),
          name: form.getFieldValue('name'),
          email: form.getFieldValue('email'),
          role_id: role_id,
        },
      });

      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSearch = (value: string) => console.log(value);

  return (
    <div className="content-container">
      <Row>
        <h2 style={{ fontSize: "32px", color: "black" }}>User</h2>
      </Row>
      <div style={{ marginBottom: "2%", float: "right" }}>
        <Search placeholder="Search User" onSearch={onSearch} style={{ width: 300, marginRight: "50px" }} />
        <Button
          type="primary"
          style={{ borderRadius: "5px", width: "180px" }}
          onClick={() => {
            form.resetFields();
            setIsCreate(true);
            setIsModalOpen(true);
          }}
        >
          <PlusOutlined /> New User
        </Button>
      </div>

      <div className="table-user">
        <Table
          rowClassName="UserTable"
          rowKey="user_id"
          columns={columns}
          dataSource={userData}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100']}}
        />


        <Modal
          title={isCreate ? "New User" : "Edit User Data"}
          open={isModalOpen}
          // onOk={handleOk}
          onOk={() => form.submit()}
          onCancel={() => setIsModalOpen(false)}
          width={600}
        >
          <div>
            <Form
              labelCol={{ flex: '110px' }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              form={form} onFinish={isCreate ? handleCreateUser : handleUpdateUser}>
              <div style={{ marginBottom: "3%", marginTop: "3%" }}>
                <Form.Item
                  name="id"
                  style={{ display: "none" }}
                >
                  <Input
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  name="role"
                  label="Role"
                  rules={[
                    { required: true, message: "Please select your role!" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={1}>Admin</Radio>
                    <Radio value={2}>Staff</Radio>
                  </Radio.Group>
                </Form.Item>



                <Form.Item
                  name="org"
                  label="Organization"
                  rules={[
                    { required: userPermission.role_name !== "Super Admin" ? false : true, message: "Please input your organization name!" },
                  ]}
                style={{ display: userPermission.role_name !== "Super Admin"?"none":""}}
                >
                  <Select
                    showSearch
                    placeholder="Organization Name"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    options={(orgData || []).map((v: any) => ({
                      value: v.org_id,
                      label: v.name,
                    }))}

                    defaultValue={userPermission.role_name !== "Super Admin" ? userPermission.org_id : null}
                  />


                </Form.Item>

                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >

                  <Input
                    placeholder="Fullname"
                    autoComplete="new-name"
                  />

                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    autoComplete="new-email"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                  style={{ display: isCreate ? "" : "none" }}
                >
                  <Input.Password
                    placeholder="Password"
                    autoComplete="new-password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </Modal>
      </div>

    </div>
  );
};

export default User;
